import * as THREE from 'three';

const sphere = new THREE.Group();
let spinSpeed = 0.005;
let desiredSpinSpeed = 0.005;

export function destroy(scene)
{
    const count = sphere.children.length;
    for (let i = 0; i < count; i++) {
        sphere.children[i].children[0].geometry.dispose();
        sphere.children[i].children[0].material.dispose();
        scene.remove(sphere.children[i].children[0]);
        sphere.children[i].geometry.dispose();
        sphere.children[i].material.dispose();
        scene.remove(sphere.children[i]);
    }
    scene.remove(sphere);
}

export function create(scene)
{
    const hexasphere = new Hexasphere(0.5, 3, 0.85);
    const material = new THREE.MeshLambertMaterial({
        color: 0x000000,
        side: THREE.DoubleSide,
    });
    const lineMaterial = new THREE.LineBasicMaterial({
        color: 0x485460,
        linewidth: 3,
    });

    for (let i = 0; i< hexasphere.tiles.length; i++){
        const t = hexasphere.tiles[i];
        const geometry = new THREE.Geometry();

        for (let j = 0; j < t.boundary.length; j++){
            const bp = t.boundary[j];
            geometry.vertices.push(new THREE.Vector3(bp.x, bp.y, bp.z));
        }

        geometry.faces.push(new THREE.Face3(0,1,2));
        geometry.faces.push(new THREE.Face3(0,2,3));
        geometry.faces.push(new THREE.Face3(0,3,4));

        if(geometry.vertices.length > 5){
            geometry.faces.push(new THREE.Face3(0,4,5));
        }

        geometry.computeFaceNormals();
        geometry.computeVertexNormals();
        geometry.mergeVertices();

        const edges = new THREE.EdgesGeometry(geometry, 5);
        const line = new THREE.LineSegments(edges, lineMaterial.clone());
        //line.layers.enable(1);

        const mesh = new THREE.Mesh(geometry, material);
        mesh.add(line);
        sphere.add(mesh);
    }

    scene.add(sphere);
}

export function animate(audioData)
{
    if (sphere.children.length === 0) {
        return;
    }

    if (desiredSpinSpeed !== spinSpeed) {
        spinSpeed = THREE.MathUtils.lerp(spinSpeed, desiredSpinSpeed, 0.01);
    }

    sphere.rotation.x -= spinSpeed;
    sphere.rotation.y += spinSpeed;
    sphere.rotation.z -= spinSpeed;

    // const notes = audioData.getNotes();
    // const color = new THREE.Color();
    // for (let i = 0; i < sphere.children.length; i++) {
    //     color.setHSL(notes[i]/250, 1, 0.5);
    //     sphere.children[i].children[0].material.color.lerpHSL(color, 0.25);
    // }

}

export function setSpinSpeed(float)
{
    desiredSpinSpeed = float;
}
