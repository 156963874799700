import {Chapter} from "/assets/js/chapters/chapter";
import * as Lasers from '/assets/js/visualizations/chapter4/lasers';
import * as Swirlers from '/assets/js/visualizations/chapter4/swirlers';
import * as TranceHexasphere from '/assets/js/trancehexasphere';
import * as Waves from '/assets/js/visualizations/chapter4/waves';
import * as Mandala from '/assets/js/visualizations/chapter4/mandala';
import { BokehPass } from 'three/examples/jsm/postprocessing/BokehPass.js';
import * as Fader from "/assets/js/fader";

// Imports for custom render passes
import {Bloom} from "../bloom";
import * as THREE from "three";
import * as TiltBrush from '/assets/js/tiltbrush';

export class Chapter4 extends Chapter {
    constructor(cameraFactory, scene, settings = {}) {
        super(cameraFactory, scene, settings);

        this.chapterTimeline = {
            //Next track bass cut
            '45:28': function() {
                Fader.setBlocked(true);
            },
            '45:29': function() {
                Lasers.destroy(scene);
                Swirlers.create(scene);
                TranceHexasphere.create(scene);
                Fader.setBlocked(false);
            },
            '47:20': function() {
                TranceHexasphere.setSpinSpeed(0.001);
                Swirlers.setFog(1.1);
            },
            '49:14': function() {
                TranceHexasphere.setSpinSpeed(0.003);
            },
            '49:36': function() {
                TranceHexasphere.setSpinSpeed(0.0075);
            },
            '49:50': function() {
                Swirlers.setFog(1.5);
            },
            '50:02': function() {
                Swirlers.lightningMode();
                Swirlers.setFog(0.7);
            },
            '50:03': function() {
                TranceHexasphere.setSpinSpeed(0.015);
            },
            '50:58': function() {
                Fader.setBlocked(true);
            },
            '50:59': function(chapter) {
                TranceHexasphere.destroy(scene);
                Swirlers.destroy(scene);
                chapter.scene.composer.addPass(chapter.bokehPass);
                Waves.create(scene);
                Fader.setBlocked(false);
            },
            '54:41': function() {
                Fader.setBlocked(true);
            },
            '54:42': function(chapter) {
                Waves.destroy(scene);
                for (let i = 0; i < chapter.scene.composer.passes.length; i++) {
                    if (chapter.scene.composer.passes[i] instanceof BokehPass) {
                        chapter.scene.composer.passes[i].enabled = false;
                    }
                }
                Mandala.create(scene);
                Fader.setBlocked(false);
            },
            '56:33': function() {
                Mandala.sparksFall();
            },
            '57:28': function() {
                Mandala.sparksReact();
            },
            '58:52': function() {
                Mandala.sparksGrow();
            }
        }
    }

    start() {
        super.start();
        this.addComposers();
        this.customizeScene();
    };

    customizeScene() {
        super.customizeScene();

        this.lights.ambient = new THREE.AmbientLight(0x555555, 0.1);
        this.scene.add(this.lights.ambient);
        this.lights.ambient2 = new THREE.AmbientLight(0x404040, 0.25);
        this.scene.add(this.lights.ambient2);
        this.lights.directional = new THREE.DirectionalLight(0xffffff, 5);
        this.lights.directional.position.set(0, 0, -1);
        this.scene.add(this.lights.directional);

        Lasers.create(this.scene);
    }

    addComposers() {
        super.addComposers();
        ///////////////////////////////////
        // Begin custom scene composers
        this.bloom = new Bloom(3, 1, 0.25, this.renderer, this.getRenderPass());
        this.scene.composer.addPass(this.bloom.getBloomShaderPass());

        this.bokehPass = new BokehPass(this.scene, this.camera, {
            focus: 50.0,
            aperture: 0.000075,
            maxblur: 0.01,

            width: window.innerWidth,
            height: window.innerHeight
        });

        // End custom scene composers
        ///////////////////////////////////
    }

    animate(audioData) {
        Lasers.animate(audioData);
        Swirlers.animate(audioData);
        TranceHexasphere.animate(audioData);
        Waves.animate(audioData);
        Mandala.animate(audioData, this.scene);
        //It's important this goes last so the scene this renders is in sync with the main composer
        this.bloom.renderBloom(this.scene);
    }

    end() {
        super.end();
    };
}
