import * as THREE from 'three';
import { TypedArrayUtils } from '/assets/js/kdtree';
import PointVert from '/assets/shaders/vert/point.vert';
import PointFrag from '/assets/shaders/frag/point.frag';

let points = null;
let newColor = null;
let expansion = false;
const seedCount = 256;
const scaleFactor = 0.037;
let grey = false;
let kdtree = null;

export function destroy(scene)
{
    points.geometry.dispose();
    points.material.dispose();
    scene.remove(points);
    points = null;
    kdtree = null;
}

export function createWave(scene)
{
    const material = new THREE.ShaderMaterial( {
        uniforms: {
            color: { value: new THREE.Color( 0xffffff ) },
            pointTexture: { value: new THREE.TextureLoader().load("/assets/images/disc.png") }
        },
        vertexShader: PointVert,
        fragmentShader: PointFrag,

        blending: THREE.AdditiveBlending,
        depthTest: false,
        transparent: true
    });

    const positions = new Float32Array(seedCount * 4);
    const sizes = new Float32Array(seedCount);
    const colors = new Float32Array(seedCount * 3);
    const geometry = new THREE.BufferGeometry();

    let pointCounter = 0;
    const color = new THREE.Color(0xffffff);

    for (let i = 0; i < seedCount; i++) {
        let theta = 2.39998131 * i;
        let radius = (scaleFactor * Math.sqrt(theta));

        positions[pointCounter] = Math.cos(theta) * radius;
        pointCounter++;
        positions[pointCounter] = Math.sin(theta) * radius;
        pointCounter++;
        positions[pointCounter] = 0;
        pointCounter++;
        positions[pointCounter] = i;
        pointCounter++;

        sizes[i] = 0.01;
        color.setHSL(0.5 + ( i * 0.0015 ), 0.9, 0.5);
        color.toArray(colors, i * 3);
    }

    kdtree = new TypedArrayUtils.Kdtree(positions, distanceFunction, 4);

    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 4));
    geometry.setAttribute('customColor', new THREE.BufferAttribute( colors, 3));
    geometry.setAttribute('size', new THREE.BufferAttribute(sizes, 1));

    points = new THREE.Points(geometry, material);
    points.position.z = -0.00015;

    scene.add(points);
}

export function changeColorBase(hslDecimal)
{
    newColor = hslDecimal;
}

export function animate(audioData, meshesToAffectDots)
{
    if (points === null) {
        return;
    }

    const colors = points.geometry.attributes.customColor.array;
    let positions = points.geometry.attributes.position.array;

    if (newColor !== null && grey === false) {
        for (let i = 0; i < seedCount; i++) {
            const existingColor = new THREE.Color(0xffffff);
            existingColor.setRGB(colors[i*3], colors[(i*3)+1], colors[(i*3)+2]);
            const color = new THREE.Color(0xffffff);
            color.setHSL(newColor + (i * 0.0015), 0.9, 0.5);
            if (existingColor.getHex() !== color.getHex()) {
                existingColor.lerpHSL(color, 0.01);
                existingColor.toArray(colors, i * 3);
            }
        }
    }

    let amountToAdd = 0;
    if (expansion === true) {
        amountToAdd = 1.5;
    }

    let moved = false;
    for (let i = 0; i < seedCount; i++) {
        let theta = 2.39998131 * i;
        let radius = (scaleFactor * Math.sqrt(theta))+amountToAdd;

        let vector3 = new THREE.Vector3(Math.cos(theta) * radius, Math.sin(theta) * radius, 0);
        let currentVector3 = new THREE.Vector3(positions[i*4], positions[i*4+1], positions[i*4+2]);
        if (Math.abs(vector3.x - currentVector3.x) < 0.001 && Math.abs(vector3.y - currentVector3.y) < 0.001) {
            break;
        } else {
            moved = true;
            positions[i*4] = currentVector3.lerp(vector3, 0.01).x;
            positions[i*4+1] = currentVector3.lerp(vector3, 0.01).y;
        }
    }

    if (moved === true) {
        kdtree = new TypedArrayUtils.Kdtree(positions, distanceFunction, 4);
    }

    let sizes = points.geometry.attributes.size.array;
    if (grey === true) {
        const coloredPositions = [];
        for (let i = 0; i < meshesToAffectDots.length; i++) {
            const objectPositionsInRange = kdtree.nearest([meshesToAffectDots[i].position.x, meshesToAffectDots[i].position.y, meshesToAffectDots[i].position.z], 12, 5);
            for (let x = 0; x < objectPositionsInRange.length; x++) {
                coloredPositions.push(objectPositionsInRange[x][0].obj[3]);
            }
        }

        let noteCounter = 0;
        for (let i = 0; i < seedCount; i++) {
            if (noteCounter === audioData.getNotes().length) {
                noteCounter = 0;
            }

            const existingColor = new THREE.Color();
            existingColor.setRGB(colors[i*3], colors[(i*3)+1], colors[(i*3)+2]);
            if (coloredPositions.indexOf(i) === -1) {
                sizes[i] = audioData.getNotes()[noteCounter]/2000;
                const color = new THREE.Color(0x0fbcf9);
                color.offsetHSL((i * 0.0015), 0, 0);
                if (existingColor.getHex() !== color.getHex()) {
                    existingColor.lerpHSL(color, 0.05);
                    existingColor.toArray(colors, i * 3);
                }
            } else {
                sizes[i] = audioData.getNotes()[noteCounter]/750;
                const color = new THREE.Color(0x4bcffa);
                existingColor.lerpHSL(color, 0.05);
                existingColor.toArray(colors, i * 3);
            }
            noteCounter++;
        }
    } else {
        for (let i = 0; i < audioData.getNotes().length; i++) {
            sizes[i] = audioData.getNotes()[i]/1000;
            sizes[i+128] = audioData.getNotes()[i]/1000;
        }
    }

    points.geometry.attributes.size.needsUpdate = true;
    points.geometry.attributes.customColor.needsUpdate = true;
    if (moved === true) {
        points.geometry.attributes.position.needsUpdate = true;
    }
}

export function expand()
{
    expansion = true;
}

export function contract()
{
    expansion = false;
}

export function setGrey(boolean)
{
    grey = boolean;
}

function distanceFunction(a, b) {
    return Math.pow( a[ 0 ] - b[ 0 ], 2 ) + Math.pow( a[ 1 ] - b[ 1 ], 2 ) + Math.pow( a[ 2 ] - b[ 2 ], 2 );
}
