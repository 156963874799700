import * as THREE from 'three';
import PointVert from '/assets/shaders/vert/point.vert';
import PointFrag from '/assets/shaders/frag/point.frag';

const orbs = [];
const lights = [];
const destinations = [];
const orbLines = [];
const sparks = [];

export function destroy(scene)
{
    for (let i = 0; i < sparks.length; i++) {
        sparks[i].geometry.dispose();
        sparks[i].material.dispose();
        scene.remove(sparks[i]);
    }

    for (let i = 0; i < orbs.length; i++) {
        orbs[i].geometry.dispose();
        orbs[i].material.dispose();
        scene.remove(orbs[i]);
    }

    for (let i = 0; i < lights.length; i++) {
        scene.remove(lights[i]);
    }

    for (let i = 0; i < orbLines.length; i++) {
        for (let x = 0; x < orbLines[i].length; x++) {
            orbLines[i][x].geometry.dispose();
            orbLines[i][x].material.dispose();
            scene.remove(orbLines[i][x]);
        }
    }
}

export function createOrbs(scene)
{
    generateOrb(scene, 0x4bcffa, generatePoints(1.5, 1), 0);
    generateOrb(scene, 0xffa801, generatePoints(1.5, 1), 0);
    generateOrb(scene, 0xd2dae2, generatePoints(1.5, 1), 0);
}

export function peekOrbs(scene)
{
    generateOrb(scene, 0x4bcffa, generatePoints(6, 3), -6);
    generateOrb(scene, 0xffa801, generatePoints(6, 3), -6);
    generateOrb(scene, 0xd2dae2, generatePoints(6, 3), -6);
}

export function generatePoints(x, y)
{
    const path = new THREE.EllipseCurve(
        0,
        0,
        x + Math.random(),
        y - Math.random(),
        0,
        2 * Math.PI,
        orbs.length+1 % 2 === 0,
        orbs.length+1
    );

    const points = path.getPoints(1000);

    destinations.push({
        frame: 0,
        points: points
    });

    return points;
}

export function animateOrbs(audioData)
{
    let orbLineCount = 3;
    for (let i = 0; i < orbs.length; i++) {
        destinations[i].frame += 1;
        const vector3 = new THREE.Vector3(
            destinations[i].points[destinations[i].frame].x,
            destinations[i].points[destinations[i].frame].y,
            orbs[0].position.z
        );
        orbs[i].position.set(vector3.x, vector3.y, vector3.z);
        lights[i].position.set(vector3.x, vector3.y, vector3.z+0.01);

        lights[i].intensity = audioData.getBeatStrength();
        orbs[i].material.opacity = 1;

        const beatColor = new THREE.Color(0x000000);
        beatColor.setHSL(audioData.getBeatStrength(), 1, 0.7);
        lights[0].color.lerpHSL(beatColor, 0.05);
        orbs[0].material.color.lerpHSL(beatColor, 0.05);

        const melodyColor = new THREE.Color(0x000000);
        melodyColor.setHSL(audioData.getMelodyStrength(), 1, 0.7);
        lights[2].color.lerpHSL(melodyColor, 0.05);
        orbs[2].material.color.lerpHSL(melodyColor, 0.05);

        let orbLineStart = (destinations[i].frame-orbLineCount)-1;
        for (let x = 0; x < orbLines[i].length; x++) {
            orbLines[i][x].material.opacity = 1;
            if (orbLineStart < 0) {
                orbLineStart = destinations[i].points.length - Math.abs(orbLineStart);
            }
            orbLines[i][x].geometry.setDrawRange(orbLineStart, orbLineCount);
            orbLineStart -= (orbLineCount-1);

            if (i === 0) {
                orbLines[i][x].material.color.lerpHSL(beatColor, 0.05);
            }

            if (i === 2) {
                orbLines[i][x].material.color.lerpHSL(melodyColor, 0.05);
            }
        }

        if (destinations[i].frame === destinations[i].points.length-1) {
            destinations[i].frame = 0;
        }

        let sizes = sparks[i].geometry.attributes.size.array;
        if (audioData.getBeatStrength() < 0.4) {
            for (let x = 0; x < sizes.length; x++) {
                sizes[x] = 0;
            }
        } else {
            let lastPosition = destinations[i].frame-75;
            if (lastPosition < 0) {
                lastPosition = destinations[i].points.length - Math.abs(lastPosition);
            }
            let endPosition = lastPosition+70;
            if (endPosition > sizes.length) {
                endPosition -= sizes.length;
            }
            let sparkCounter = 0;
            for (let x = 0; x < sizes.length; x++) {
                if (endPosition < lastPosition) {
                    if (x >= lastPosition) {
                        sizes[x] = 0.01+(sparkCounter*0.001);
                        sparkCounter++;
                    } else if (x <= endPosition) {
                        sizes[x] = 0.01+(sparkCounter*0.001);
                        sparkCounter++;
                    } else {
                        sizes[x] = 0;
                    }
                } else {
                    if (x >= lastPosition && x <= endPosition) {
                        sizes[x] = 0.01+(sparkCounter*0.001);
                        sparkCounter++;
                    } else {
                        sizes[x] = 0;
                    }
                }
            }
        }

        let sparkColors = sparks[i].geometry.attributes.customColor.array;
        for (let x = 0; x < sizes.length; x++) {
            if (i === 0) {
                beatColor.toArray(sparkColors, x * 3);
            } else if (i === 2) {
                melodyColor.toArray(sparkColors, x * 3);
            }
        }

        sparks[i].geometry.attributes.size.needsUpdate = true;
        sparks[i].geometry.attributes.customColor.needsUpdate = true;
    }
}

function generateOrb(scene, color, points, z)
{
    const light = new THREE.PointLight(color, 5, 1, 2);
    const geometry = new THREE.CircleBufferGeometry(0.025, 16);
    geometry.computeVertexNormals();
    const material = new THREE.MeshBasicMaterial({
        color: color,
        transparent: true,
        opacity: 0
    });
    const circle = new THREE.Mesh(geometry, material);
    const x = Math.random();
    const y = Math.random();
    circle.position.set(x, y, z);
    light.position.set(x, y, z+0.1);

    circle.layers.enable(1);

    scene.add(light);
    scene.add(circle);

    orbs.push(circle);
    lights.push(light);

    const myOrbLines = [];
    const lineCount = 30;
    for (let i = 0; i < lineCount; i++) {

        const pathGeometry = new THREE.BufferGeometry().setFromPoints(points);
        const pathMaterial = new THREE.LineBasicMaterial({
            color : color,
            transparent: true,
            opacity: 0,
            linewidth: lineCount-i,
            linecap: 'round'
        });
        const orbLine = new THREE.Line(pathGeometry, pathMaterial);
        orbLine.layers.enable(1);
        orbLine.position.z = z;
        scene.add(orbLine);
        myOrbLines.push(orbLine);
    }

    orbLines.push(myOrbLines);

    const sparkMaterial = new THREE.ShaderMaterial( {
        uniforms: {
            color: { value: new THREE.Color( 0xffffff ) },
            pointTexture: { value: new THREE.TextureLoader().load("/assets/images/spark1.png") }
        },
        vertexShader: PointVert,
        fragmentShader: PointFrag,

        blending: THREE.AdditiveBlending,
        depthTest: false,
        transparent: true
    });

    const positions = new Float32Array(points.length * 3);
    const sizes = new Float32Array(points.length);
    const colors = new Float32Array(points.length * 3);
    const sparkGeometry = new THREE.BufferGeometry();

    let pointCounter = 0;

    const sparkColor = new THREE.Color(color);

    for (let i = 0; i < points.length; i++) {
        positions[pointCounter] = i % 2 === 0 ? points[i].x + Math.random()/10 : points[i].x - Math.random()/10;
        pointCounter++;
        positions[pointCounter] = i % 2 === 0 ? points[i].y + Math.random()/10 : points[i].y - Math.random()/10;
        pointCounter++;
        positions[pointCounter] = z;
        pointCounter++;

        sizes[i] = 0.1 + Math.random()/10;
        sparkColor.toArray(colors, i * 3);
    }

    sparkGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    sparkGeometry.setAttribute('customColor', new THREE.BufferAttribute( colors, 3));
    sparkGeometry.setAttribute('size', new THREE.BufferAttribute(sizes, 1).setUsage(THREE.DynamicDrawUsage));

    const sparkPoints = new THREE.Points(sparkGeometry, sparkMaterial);
    sparkPoints.layers.enable(1);
    sparks.push(sparkPoints);

    scene.add(sparkPoints);
}
