import * as THREE from 'three';
import { FlakesTexture } from 'three/examples/jsm/textures/FlakesTexture';
import * as TranceMath from '/assets/js/math';
import * as noise from "/assets/js/thirdparty/noise";

let melodyObjects;
let bassObjects = [];
const group = new THREE.Group();
let sphere;
let pointLight;
let newColor = null;

const bassQuantity = 12;
const melodyQuantity = 5000;

let centerRing = false;
let centerRotate = false;
let ringCenteringStart = null;

let melodyRadius = 0;
let amorphous = false;
let inhale = false;

let implode = false;

let created = false;

export function preload()
{
    noise.seed(Math.random());
    const normalMap3 = new THREE.CanvasTexture(new FlakesTexture());
    normalMap3.wrapS = THREE.RepeatWrapping;
    normalMap3.wrapT = THREE.RepeatWrapping;
    normalMap3.repeat.x = 10;
    normalMap3.repeat.y = 6;
    normalMap3.anisotropy = 16;

    const melodyMaterial = new THREE.MeshBasicMaterial({
        color: 0x4bcffa,
        transparent: true,
        opacity: 1
    });

    const melodyGeometry = new THREE.SphereBufferGeometry(
        0.002,
        8,
        8
    );

    const bassMaterial =new THREE.MeshPhysicalMaterial({
        clearcoat: 1.0,
        clearcoatRoughness: 0.1,
        metalness: 0.9,
        roughness: 0.5,
        color: 0x0000ff,
        normalMap: normalMap3,
        normalScale: new THREE.Vector2(0.15, 0.15)
    });

    const bassGeometry = new THREE.SphereBufferGeometry(0.075, 64, 64);

    for (let i = 0; i < bassQuantity; i++) {
        const mesh = new THREE.Mesh(bassGeometry.clone(), bassMaterial.clone());
        mesh.position.setFromSphericalCoords(0.75, THREE.MathUtils.degToRad((360/bassQuantity)*i), 1);
        mesh.rotation.x += Math.random();
        mesh.rotation.y += Math.random();
        mesh.rotation.z += Math.random();
        group.add(mesh);
        bassObjects.push(mesh);
    }

    melodyObjects = new THREE.InstancedMesh(melodyGeometry, melodyMaterial, melodyQuantity);
    melodyObjects.instanceMatrix.setUsage(THREE.DynamicDrawUsage);

    pointLight = new THREE.PointLight(0xffffff, 10, 3);
    pointLight.position.set(0, 0, 3);

    const metalTextureNormalMap = new THREE.TextureLoader().load('/assets/images/metal1/everytexture.com-stock-misc-texture-00081-normal-1024.jpg');
    const sphereMaterial = new THREE.MeshPhysicalMaterial({
        color: 0x485460,
        normalMap: metalTextureNormalMap,
        normalScale: new THREE.Vector2(1, 1),
        roughness: 0.9,
        metalness: 0.7
    });
    const sphereGeometry = new THREE.SphereGeometry(0.1, 96, 96);
    sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);

    sphere.position.z = -10;
    group.position.z = -10;
    melodyObjects.position.z = -10;
}

export function create(scene)
{
    scene.add(melodyObjects);
    scene.add(group);
    scene.add(pointLight);
    scene.add(sphere);
    created = true;
}

export function animate(audioData, scene)
{
    if (bassObjects.length === 0 || created === false) {
        return;
    }

    if (sphere && sphere.position.z < 1.05) {
        sphere.position.z = THREE.MathUtils.lerp(sphere.position.z, 1.1, 0.05);
        group.position.z = THREE.MathUtils.lerp(group.position.z, 1.1, 0.05);

        if (melodyObjects !== null) {
            melodyObjects.position.z = THREE.MathUtils.lerp(melodyObjects.position.z, 1.1, 0.05);
        }
    }

    const notes = audioData.getNotes();

    let notePointer = 33;
    const cubeColor = new THREE.Color({
        color: 0x0000ff
    });
    if (centerRing === false) {
        group.rotation.x += audioData.getBeatStrength()/100;
        group.rotation.y += audioData.getMelodyWindowRatio()/100;
        group.rotation.z -= audioData.getMelodyMidiIndex()/100;
        for (let i = 0; i < bassObjects.length; i++) {
            cubeColor.setHSL((0.4+notes[notePointer]/500), 1, 0.5);
            bassObjects[i].scale.set(notes[notePointer]/500, notes[notePointer]/500, notes[notePointer]/500);
            const vector = new THREE.Vector3();
            vector.setFromSphericalCoords(THREE.MathUtils.clamp(notes[notePointer]/500, 0.2, 0.8), THREE.MathUtils.degToRad((360/bassQuantity)*i), 1);
            bassObjects[i].position.x = THREE.MathUtils.lerp(bassObjects[i].position.x, vector.x, 0.1);
            bassObjects[i].position.y = THREE.MathUtils.lerp(bassObjects[i].position.y, vector.y, 0.1);
            if (implode === false) {
                bassObjects[i].position.z = THREE.MathUtils.lerp(bassObjects[i].position.z, vector.z, 0.1);
            }
            bassObjects[i].material.color.lerpHSL(cubeColor, 0.01);
            notePointer++;
        }
    } else {
        if (ringCenteringStart === null) {
            ringCenteringStart = Date.now();
        }

        for (let i = 0; i < bassObjects.length; i++) {
            if (Date.now() - ringCenteringStart > i*400) {
                cubeColor.setHSL((0.4+notes[notePointer]/500), 1, 0.5);
                const controlPoints = TranceMath.controlPointsByHeight(
                    (360/bassObjects.length)*i,
                    0,
                    0,
                    0.5
                );

                bassObjects[i].scale.set(notes[notePointer]/500, notes[notePointer]/500, notes[notePointer]/500);
                bassObjects[i].position.x = THREE.MathUtils.lerp(bassObjects[i].position.x, controlPoints.x, 0.1);
                bassObjects[i].position.y = THREE.MathUtils.lerp(bassObjects[i].position.y, controlPoints.y, 0.1);
                if (implode === false) {
                    bassObjects[i].position.z = THREE.MathUtils.lerp(bassObjects[i].position.z, 0.4, 0.1);
                }
                bassObjects[i].material.color.lerpHSL(cubeColor, 0.01);
                notePointer++;
            }
        }

        if (centerRotate === true) {
            group.rotation.z -= audioData.getBeatStrength()/100;
        } else {
            const quat = new THREE.Quaternion();
            quat.setFromEuler(group.rotation);
            const flatEuler = new THREE.Euler( 0, 0, 0, 'XYZ' );
            const flatQuat = new THREE.Quaternion();
            flatQuat.setFromEuler(flatEuler);
            quat.slerp(flatQuat, 0.01);
            const euler = new THREE.Euler();
            euler.setFromQuaternion(quat, 'XYZ');
            group.rotation.x = euler.x;
            group.rotation.y = euler.y;
            group.rotation.z = euler.z;
        }
    }

    const dummy = new THREE.Object3D;
    const vector = new THREE.Vector3();
    notePointer = 78;
    if (melodyObjects) {
        if (inhale === false) {
            if (newColor !== null) {
                melodyObjects.material.color.lerpHSL(newColor, 0.01);
            }

            melodyObjects.rotation.x -= audioData.getBeatStrength()/100;
            melodyObjects.rotation.y += audioData.getMelodyWindowRatio()/100;
            melodyObjects.rotation.z += audioData.getMelodyOctaveIndex()/80;

            for (let i = 0; i < melodyQuantity; i++) {
                if (notePointer > 127) {
                    notePointer = 78;
                }
                //dummy.scale.set(notes[notePointer]/3000, notes[notePointer]/3000, notes[notePointer]/3000);
                vector.setFromSphericalCoords(melodyRadius - notes[notePointer]/600, THREE.MathUtils.degToRad((360/melodyQuantity)*i), (i+1)*0.9);
                const currMatrix = new THREE.Matrix4();
                melodyObjects.getMatrixAt(i, currMatrix);
                const currVector = new THREE.Vector3();
                currVector.setFromMatrixPosition(currMatrix);
                dummy.position.x = THREE.MathUtils.lerp(currVector.x, vector.x, 0.1);
                dummy.position.y = THREE.MathUtils.lerp(currVector.y, vector.y, 0.1);
                dummy.position.z = THREE.MathUtils.lerp(currVector.z, vector.z, 0.1);

                dummy.updateMatrix();
                melodyObjects.setMatrixAt(i, dummy.matrix);
                notePointer++;
            }

            melodyObjects.instanceMatrix.needsUpdate = true;
        } else {
            if (melodyObjects) {
                melodyObjects.material.opacity -= 0.005;
                for (let i = 0; i < melodyQuantity; i++) {
                    if (notePointer > 127) {
                        notePointer = 78;
                    }
                    vector.setFromSphericalCoords(5 - notes[notePointer]/600, THREE.MathUtils.degToRad((360/melodyQuantity)*i), (i+1)*0.9);
                    const currMatrix = new THREE.Matrix4();
                    melodyObjects.getMatrixAt(i, currMatrix);
                    const currVector = new THREE.Vector3();
                    currVector.setFromMatrixPosition(currMatrix);
                    dummy.position.x = THREE.MathUtils.lerp(currVector.x, vector.x, 0.002);
                    dummy.position.y = THREE.MathUtils.lerp(currVector.y, vector.y, 0.002);
                    dummy.position.z = THREE.MathUtils.lerp(currVector.z, vector.z, 0.002);

                    dummy.updateMatrix();
                    melodyObjects.setMatrixAt(i, dummy.matrix);
                    notePointer++;
                }
                melodyObjects.instanceMatrix.needsUpdate = true;
            }

            if (melodyObjects.material.opacity <= 0) {
                melodyObjects.geometry.dispose();
                melodyObjects.material.dispose();
                scene.remove(melodyObjects);
                melodyObjects = null;
            }


        }
    }

    const time = Date.now()*0.00025;

    pointLight.position.x = -Math.sin(time);
    pointLight.position.y = -Math.cos(time);
    pointLight.position.z = -Math.sin(time);

    if (amorphous === true && sphere !== null) {
        if (implode === true) {
            for (let i = 0; i < bassObjects.length; i++) {
                bassObjects[i].position.z += 0.01;
            }
            let currScale = sphere.scale.x;
            currScale -= 0.01;
            if (currScale <= 0) {
                for (let i = 0; i < bassObjects.length; i++) {
                    bassObjects[i].material.dispose();
                    bassObjects[i].geometry.dispose();
                    scene.remove(bassObjects[i]);
                }
                sphere.geometry.dispose();
                sphere.material.dispose();
                scene.remove(sphere);
                sphere = null;
                return;
            }
            sphere.scale.set(currScale, currScale, currScale);
        }
        sphere.position.z = THREE.MathUtils.lerp(sphere.position.z, 0.75, 0.01);
        const time = performance.now() * 0.003;
        for (let i = 0; i < sphere.geometry.vertices.length; i++) {
            const p = sphere.geometry.vertices[i];
            p.normalize()
                .multiplyScalar(
                    0.5 + 0.3 * noise.perlin3(
                        p.x * (THREE.MathUtils.clamp(audioData.getBeatStrength(), 0.2, 1)*3) + time,
                        p.y * (THREE.MathUtils.clamp(audioData.getBeatStrength(), 0.2, 1)*3),
                        p.z * (THREE.MathUtils.clamp(audioData.getBeatStrength(), 0.2, 1)*3)
                    )
                );
        }

        sphere.geometry.computeVertexNormals();
        sphere.geometry.normalsNeedUpdate = true;
        sphere.geometry.verticesNeedUpdate = true;
    }

    if (sphere) {
        sphere.rotation.x -= audioData.getBeatStrength()/100;
        sphere.rotation.y += audioData.getMelodyWindowRatio()/80;
        sphere.rotation.z += audioData.getMelodyMidiIndex()/100;
    }
}

export function setColor(color)
{
    newColor = color;
}

export function centerOuterRing(boolean)
{
    centerRing = boolean;
}

export function centerRingRotate(boolean)
{
    centerRotate = boolean;
}

export function setAmorphous(boolean)
{
    amorphous = boolean;
}

export function setInhale(boolean)
{
    inhale = boolean;
}

export function setImplode(boolean)
{
    implode = boolean;
}
