import * as THREE from 'three';
import * as TranceMath from "/assets/js/math";

const rings = [];
const ringPositions = [];
const sectionQuantity = 50;

export function destroy(scene)
{
    for (let i = 0; i < rings.length; i++) {
        rings[i].geometry.dispose();
        rings[i].material.dispose();
        scene.remove(rings[i]);
    }
}

export function create(scene)
{
    for (let i = 0; i < 3; i++) {
        const ringMaterial = new THREE.LineBasicMaterial({
            color: 0xffffff,
            linewidth: 3+i,
            transparent: true,
            opacity: 0
        });

        const ringPath = buildPath(new THREE.Path(), 0.7*(i+1));
        const ringGeometry = new THREE.BufferGeometry()
            .setAttribute( 'position', new THREE.BufferAttribute(new Float32Array(sectionQuantity * 3), 3))
            .setFromPoints(ringPath.getPoints());
        const ring = new THREE.Line(ringGeometry, ringMaterial);
        ring.layers.enable(1);
        ring.position.z = 0.3;

        scene.add(ring);
        rings.push(ring);

        ringPositions.push(JSON.parse(JSON.stringify(ringGeometry.attributes.position.array)));
    }
}

export function animate(audioData)
{
    if (rings.length === 0) {
        return;
    }

    for (let i = 0; i < rings.length; i++) {
        rings[i].material.opacity += 0.01;
    }

    let ring1Positions = rings[0].geometry.attributes.position.array;
    let ring2Positions = rings[1].geometry.attributes.position.array;
    let ring3Positions = rings[2].geometry.attributes.position.array;

    const melodicNotes = [].slice.call(audioData.getNotes().slice(78, 129));
    let degreePerI = 360/melodicNotes.length;
    let currentDegree = 0;
    let min = Math.min(...melodicNotes);
    let max = Math.max(...melodicNotes);
    if (min === 0 && max === 0) {
        return;
    }

    if (min === max || max === 0) {
        return;
    }

    let index = 0;

    const melodyColor = new THREE.Color({
        color: 0xffffff
    });
    melodyColor.setHSL(audioData.getMelodyOctaveIndex(), 1, 0.5);

    for (let i = 0; i < melodicNotes.length; i++) {
        let height = ((melodicNotes[i] - min) / (max - min))/5;
        let radian = TranceMath.calculateRadian(degreePerI*currentDegree);
        const x = ringPositions[0][index] + (height * Math.cos(radian));
        ring1Positions[index] = x;
        index++;
        const y = ringPositions[0][index] + (height * Math.sin(radian))
        ring1Positions[index] = y;
        index += 2;
        currentDegree++;
    }

    ring1Positions[ring1Positions.length-3] = ring1Positions[0];
    ring1Positions[ring1Positions.length-2] = ring1Positions[1];

    rings[0].material.color.lerpHSL(melodyColor, 0.1);
    rings[0].geometry.attributes.position.needsUpdate = true;
    rings[0].geometry.computeBoundingSphere();

    const beatNotes = [].slice.call(audioData.getNotes().slice(20, 71));
    degreePerI = 360/beatNotes.length;
    currentDegree = 0;
    min = Math.min(...beatNotes);
    max = Math.max(...beatNotes);
    if (min === 0 && max === 0) {
        return;
    }

    if (min === max || max === 0) {
        return;
    }

    index = 0;

    const beatColor = new THREE.Color({
        color: 0xffffff
    });
    beatColor.setHSL(audioData.getBeatStrength(), 1, 0.5);

    for (let i = 0; i < beatNotes.length; i++) {
        let height = ((beatNotes[i] - min) / (max - min))/5;
        let radian = TranceMath.calculateRadian(degreePerI*currentDegree);
        ring2Positions[index] = ringPositions[1][index] + (height * Math.cos(radian));
        index++;
        ring2Positions[index] = ringPositions[1][index] + (height * Math.sin(radian));
        index += 2;
        currentDegree++;
    }

    ring2Positions[ring2Positions.length-3] = ring2Positions[0];
    ring2Positions[ring2Positions.length-2] = ring2Positions[1];

    rings[1].material.color.lerpHSL(beatColor, 0.1);
    rings[1].geometry.attributes.position.needsUpdate = true;
    rings[1].geometry.computeBoundingSphere();

    const beatMaxColor = new THREE.Color({
        color: 0xffffff
    });
    beatMaxColor.setHSL(audioData.getHihatStrength(), 1, 0.5);
    degreePerI = 360/sectionQuantity;
    index = 0;

    for (let i = 0; i < sectionQuantity; i++) {
        let height = audioData.getBeatStrength()/5;
        let radian = TranceMath.calculateRadian(degreePerI*currentDegree);
        ring3Positions[index] = ringPositions[2][index] + (height * Math.cos(radian));
        index++;
        ring3Positions[index] = ringPositions[2][index] + (height * Math.sin(radian));
        index += 2;
        currentDegree++;
    }

    ring3Positions[ring3Positions.length-3] = ring3Positions[0];
    ring3Positions[ring3Positions.length-2] = ring3Positions[1];

    rings[2].material.color.lerpHSL(beatMaxColor, 0.1);
    rings[2].geometry.attributes.position.needsUpdate = true;
    rings[2].geometry.computeBoundingSphere();


}

function buildPath(path, height)
{
    const degreePerSection = 360/sectionQuantity;
    let currentDegree = 0;

    for (let i = 0; i <= sectionQuantity; i++) {
        let radian = TranceMath.calculateRadian(degreePerSection*currentDegree);
        let x = Math.cos(radian);
        let y = Math.sin(radian);
        let controlPointX = x + (height * Math.cos(radian));
        let controlPointY = y + (height * Math.sin(radian));

        if (i === 0) {
            path.moveTo(controlPointX, controlPointY);
        } else {
            path.lineTo(controlPointX, controlPointY);
        }

        currentDegree++;
    }

    return path;
}
