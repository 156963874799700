import {Chapter} from "/assets/js/chapters/chapter";
import * as WaterRings from '/assets/js/water_rings';
import * as Spinners from "/assets/js/spinners";
import * as Tunnel from "/assets/js/tunnel";
import * as RingParticles from "/assets/js/ringParticles";
import * as Stars from '/assets/js/stars';
import * as THREE from "three";
import {FilmPass} from "three/examples/jsm/postprocessing/FilmPass";
import {Bloom} from "../bloom";
import {GlitchPass} from "../passes/glitch";

export class Chapter2 extends Chapter {
    constructor(cameraFactory, scene, settings = {}) {
        super(cameraFactory, scene, settings);
        this.chapterTimeline = {
            //center rises
            '10:50': function() {
                WaterRings.setCenterPieceRaised(false);
            },
            //drop
            '11:04': function() {
                Spinners.setFog(false);
                WaterRings.setMagnetic(true);
            },
            '11:55': function() {
                Spinners.setRaised(true);
                WaterRings.setBeatPulse(true);
            },
            //breakdown
            '12:00': function() {
                WaterRings.setAnimatePoints(true);
            },
            //laser zaps build up
            '12:27': function() {

            },
            //drop
            '12:41': function() {

            },
            //build up to next track
            '13:38': function() {

            },
            //mix into next track
            '13:51': function() {
                WaterRings.setMagnetic(false);
            },
            //breakdown start
            '14:47': function() {
                Spinners.setRaised(false);
                WaterRings.setBeatPulse(false);
            },
            //violin breakdown
            '14:54': function() {
                WaterRings.setSelfMagnetize(40);
            },
            '15:21': function() {
                WaterRings.setSelfMagnetize(null);
            },
            //intense zappy pre-drop build up
            '15:22': function() {
                WaterRings.triggerFlash();
            },
            //setup
            '16:19': function(chapter) {
                WaterRings.destroyWater(chapter.scene);
                WaterRings.setBeatShoot(true);
                WaterRings.setBeatPulse(true);
                Spinners.setRaised(true);
            },
            '17:00': function() {
                WaterRings.triggerFlash();
            },
            //next track mix in starts
            '17:01': function (chapter) {
                Stars.create(chapter.scene);
                WaterRings.tunnelize();
                WaterRings.setBeatPulse(false);
            },
            '17:05': function(chapter) {
                //Disable film pass
                for (let i = 0; i < chapter.scene.composer.passes.length; i++) {
                    if (chapter.scene.composer.passes[i] instanceof FilmPass) {
                        chapter.scene.composer.passes[i].enabled = false;
                    }
                }
                chapter.bloom.updateStrength(3);
                Tunnel.create(chapter.scene);
                Spinners.setExpansion(0.2);
                Spinners.setFog(false);
                Spinners.setRaised(true);
            },
            '17:21': function ()
            {
                Tunnel.flashUp();
            },
            //cutover
            '17:28': function () {

            },
            '17:35': function () {
                Tunnel.flashDown();
            },
            '17:49': function (chapter) {
                WaterRings.bringRefractorForward(chapter.scene);
                WaterRings.createFastOscillator(chapter.scene);
            },
            //get up.. dance!
            '17:57': function (chapter) {
                RingParticles.create(chapter.scene);
            },
            //drop
            '18:24': function () {

            },
            //breakdown
            '19:21': function () {
                RingParticles.setColor(new THREE.Color({
                    color: 0xd2dae2
                }));
            },
            //build up
            '19:49': function() {
                RingParticles.setColor(new THREE.Color({
                    color: 0x575fcf
                }));
            },
            //drop
            '21:12': function() {
                RingParticles.setColor(new THREE.Color({
                    color: 0xffc048
                }));
            },
            //next track cut
            '22:07': function() {

            },
            //here's to god/inferno speech
            '23:30': function() {
                RingParticles.centerOuterRing(true);
            },
            //build up starts
            '23:57': function() {
                RingParticles.setInhale(true);
            },
            //drop
            '24:25': function(chapter) {
                for (let i = 0; i < chapter.scene.composer.passes.length; i++) {
                    if (chapter.scene.composer.passes[i] instanceof GlitchPass) {
                        chapter.scene.composer.passes[i].glitchMe();
                    }
                }
                RingParticles.centerRingRotate(true);
                RingParticles.setAmorphous(true);
            },
            //here come the stars
            '26:22': function() {
                RingParticles.setImplode(true);
                Stars.destroy();
                WaterRings.windDown();
                Spinners.flyOff();
                Tunnel.end();
            },
        };
    }

    preload() {
        super.preload();
        RingParticles.preload();
    }

    start() {
        super.start();
        this.addComposers();
        this.customizeScene();
    };

    customizeScene() {
        super.customizeScene();
        if (Spinners.getSpinners().length === 0) {
            Spinners.create(this.scene);
        }
        WaterRings.create(this.renderer, this.scene);
    }

    addComposers() {
        super.addComposers();

        ///////////////////////////////////
        // Begin custom scene composers
        this.bloom = new Bloom(6, 1, 0.25, this.renderer, this.getRenderPass());

        // Film Grain Pass
        const filmShaderPass = new FilmPass(0.35, 0.95, 2048, false);
        this.scene.composer.addPass(filmShaderPass);
        this.scene.composer.addPass(this.bloom.getBloomShaderPass());

        //Glitch
        this.glitchPass = new GlitchPass();
        this.scene.composer.addPass(this.glitchPass);

        // End custom scene composers
        ///////////////////////////////////
    }

    animate(audioData) {
        WaterRings.animate(audioData, Spinners.getSpinners(), this.scene);
        Spinners.animate(audioData);
        Tunnel.animate(audioData, this.frustum);
        RingParticles.animate(audioData, this.scene);
        Stars.animate(this.scene);

        //It's important this goes last so the scene this renders is in sync with the main composer
        this.bloom.renderBloom(this.scene);
    }

    end() {
        super.end();
        WaterRings.destroy(this.scene);
        Spinners.destroy(this.scene);
        Tunnel.destroy(this.scene);
        WaterRings.destroy(this.scene);
    };

    ////////////////////////////////////////////
    // Begin Custom methods


    // End Custom methods
    ////////////////////////////////////////////
}
