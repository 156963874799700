import * as THREE from 'three';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader';
import * as Honeycomb from 'honeycomb-grid'
import * as noise from "./thirdparty/noise";
import {MeshSplitter} from "./services/meshSplitter";
import * as HexTunnel from '/assets/js/visualizations/hextunnel';

let cellGeo = null;
const cellSize = 0.35;
let hexGroup = new THREE.Group();
const splitHexes = [];
let flashedHexes = [];
const rune = new THREE.Group();
let hexPositions;
let Grid;
let centerHex;
let currentNeighbor = null;
let myCorners = null;
let currentCorner = 0;
let shootHex = false;
let shootLines = [];
let disperse = false;
let lightning = [];
let splitSpeed = [];
let initiatePortal = false;
let fadeIn = false;

export function destroy(scene)
{
    flashedHexes = [];
    while(hexGroup.children.length > 0) {
        for (let i = 0; i < hexGroup.children.length; i++) {
            for (let x = 0; x < hexGroup.children[i].children.length; x++) {
                scene.remove(hexGroup.children[i].children[x]);
                if (hexGroup.children[i].children[x].geometry) {
                    hexGroup.children[i].children[x].geometry.dispose();
                }
                hexGroup.children[i].children[x].material.dispose();
                hexGroup.children[i].remove(hexGroup.children[i].children[x]);
            }
            scene.remove(hexGroup.children[i]);
            hexGroup.children[i].geometry.dispose();
            hexGroup.children[i].material.dispose();
            hexGroup.remove(hexGroup.children[i]);
        }
    }
    cellGeo.dispose();
    scene.remove(hexGroup);
}

export function create(scene)
{
    noise.seed(Math.random());
    buildHexes(scene);
}

export function animate(audioData, scene)
{
    if (initiatePortal === false) {
        if (audioData.isBeat()) {
            if (disperse === false) {
                for (let i = 0; i < 6; i++) {
                    const flashedHex = THREE.MathUtils.randInt(0, hexGroup.children.length);
                    if (hexGroup.children[flashedHex] && hexGroup.children[flashedHex].name !== 'hex_68' && flashedHexes.indexOf(flashedHex) === -1) {
                        flashedHexes.push(flashedHex);
                        hexGroup.children[flashedHex].material.opacity = 0.25;
                        hexGroup.children[flashedHex].children[0].material.opacity = 0.25;
                    }
                }
            } else {
                if (hexGroup.children.length > 1) {
                    for (let i = 0; i < 6; i++) {
                        disperseHexagon(scene, THREE.MathUtils.randInt(0, hexGroup.children.length));
                    }
                }
            }


            if (shootHex === true) {
                const shootingHex = hexGroup.getObjectByName('hex_68');
                const shootLine = shootingHex.children[0].clone();
                scene.add(shootLine);
                shootLines.push(shootLine);
            }
        }

        if (disperse === false) {
            for (let i = 0; i < flashedHexes.length; i++) {
                if (hexGroup.children[flashedHexes[i]]) {
                    hexGroup.children[flashedHexes[i]].material.opacity += 0.005;
                    hexGroup.children[flashedHexes[i]].children[0].material.opacity += 0.005;
                    if (hexGroup.children[flashedHexes[i]].material.opacity >= 1) {
                        flashedHexes.splice(i, 1);
                    }
                } else {
                    flashedHexes.splice(i, 1);
                }
            }
        }

        const notes = audioData.getNotes();
        const color = new THREE.Color();
        for (let i = 0; i < hexGroup.children.length; i++) {
            const hexName = hexGroup.children[i].name.split('_');
            let zHeight = notes[hexName[1]]/1000;
            color.setHSL(notes[hexName[1]]/500, 1, 0.5);
            if (hexGroup.children[i] && hexGroup.children[i].name === 'hex_68') {
                zHeight += audioData.getHihatStrength()/5;
                color.setHSL(notes[hexName[1]]/200, 1, 0.5);
            }
            if (hexGroup.children[i]) {
                hexGroup.children[i].position.z = THREE.MathUtils.lerp(hexGroup.children[i].position.z, zHeight, 0.5);
                hexGroup.children[i].children[0].material.color.lerpHSL(color, 0.1);
            }
        }

        for (let i = 0; i < shootLines.length; i++) {
            shootLines[i].rotation.z += 0.01;
            shootLines[i].position.z = THREE.MathUtils.lerp(shootLines[i].position.z, 3, 0.01);
            if (shootLines[i].position.z > 2) {
                for (let x = 0; x < shootLines[i].children.length; x++) {
                    shootLines[i].children[x].material.dispose();
                    shootLines[i].children[x].geometry.dispose();
                }
                scene.remove(shootLines[i]);
                shootLines.splice(i, 1);
            }
        }

        for (let i = 0; i < splitHexes.length; i++) {
            const hexName = splitHexes[i].name.split('_');
            color.setHSL(notes[hexName[2]]/500, 1, 0.5);
            for (let x = 0; x < splitHexes[i].children.length; x++) {
                if (splitHexes[i] && splitHexes[i].children[x]) {
                    splitHexes[i].children[x].children[0].material.color.lerpHSL(color, 1);
                    splitHexes[i].children[x].position.z += splitSpeed[i][x];
                    splitHexes[i].children[x].rotation.z += splitSpeed[i][x];
                    splitHexes[i].children[x].rotation.x += splitSpeed[i][x];
                    splitHexes[i].children[x].rotation.y += splitSpeed[i][x];
                    if (splitHexes[i].children[x].position.z > 1.9) {
                        splitHexes[i].children[x].geometry.dispose();
                        splitHexes[i].children[x].material.dispose();
                        splitHexes[i].children[x].children[0].geometry.dispose();
                        splitHexes[i].children[x].children[0].material.dispose();
                        scene.remove(splitHexes[i].children[x]);
                        scene.remove(splitHexes[i].children[x].children[0]);
                        splitHexes[i].children.splice(x, 1);
                        splitSpeed[i].splice(x, 1);
                    }
                }
            }
            if (splitHexes[i].children.length === 0) {
                splitHexes.splice(i, 1);
                splitSpeed.splice(i, 1);
            }
        }
    } else {
        const runedHex = hexGroup.getObjectByName('hex_68');
        if (runedHex) {
            runedHex.position.z += 0.0275;
            if (runedHex.position.z > 1.8) {
                HexTunnel.create(scene);
                destroy(scene);
            }
        }
    }
}

function disperseHexagon(scene, childPosition)
{
    if (!hexGroup.children[childPosition] || hexGroup.children[childPosition].name === 'hex_68') {
        return;
    }
    const meshSplitter = new MeshSplitter();
    const splitHex = meshSplitter.split(hexGroup.children[childPosition]);
    splitHex.name = 'splitHex_' + hexGroup.children[childPosition].name;
    splitHex.position.set(hexGroup.children[childPosition].position.x, hexGroup.children[childPosition].position.y, hexGroup.children[childPosition].position.z);
    for (let i = 0; i < splitHex.children.length; i++) {
        const edges = new THREE.EdgesGeometry(splitHex.children[i].geometry);
        let line = new THREE.LineSegments(edges, new THREE.LineBasicMaterial({
            color: 0xffffff,
            linewidth: 2,
            transparent: true,
            opacity: 1
        }));
        line.layers.enable(1);
        splitHex.children[i].add(line);
    }

    scene.add(splitHex);
    splitHexes.push(splitHex);
    hexGroup.children[0].material.dispose();
    hexGroup.children[0].children[0].geometry.dispose();
    hexGroup.children[0].children[0].material.dispose();
    scene.remove(hexGroup.children[0].children[0]);
    scene.remove(hexGroup.children[0]);
    hexGroup.children.splice(childPosition, 1);
    splitSpeed.push([
        THREE.MathUtils.randFloat(0.01, 0.05),
        THREE.MathUtils.randFloat(0.01, 0.05),
        THREE.MathUtils.randFloat(0.01, 0.05)
    ]);
}

function buildLightning(scene, hexDirection)
{
    currentNeighbor = centerHex;

    const path = new THREE.Path();
    myCorners = centerHex.corners().map(corner => corner.add(centerHex.toPoint()));
    currentNeighbor = centerHex;
    currentCorner = hexDirection;
    const size = centerHex.size;
    path.moveTo(myCorners[currentCorner].x + (size.xRadius/2), myCorners[currentCorner].y);

    while (currentNeighbor !== null) {
        const size = currentNeighbor.size;
        path.lineTo(myCorners[currentCorner].x + (size.xRadius/2), myCorners[currentCorner].y);
        //If I am touching a transition corner, move to the new neighbor
        const adjacentCorners = adjacentCornersByDirection(hexDirection);
        if (adjacentCorners.hasOwnProperty(currentCorner)) {
            currentNeighbor = hexPositions.neighborsOf(currentNeighbor, hexDirection)[0];
            if (currentNeighbor === undefined) {
                break;
            }
            const point = currentNeighbor.toPoint();
            myCorners = currentNeighbor.corners().map(corner => corner.add(point));
            currentCorner = adjacentCorners[currentCorner];
        } else {
            //If I am not, move to my next corner
            currentCorner++;
            if (currentCorner > 5) {
                currentCorner = 0;
            }
        }
    }

    const geometry = new THREE.BufferGeometry().setFromPoints(path.getPoints());
    const material = new THREE.LineBasicMaterial({
        color: 0x34e7e4,
        linewidth: 5,
        transparent: true,
        opacity: 0.5
    });

    const line = new THREE.Line(geometry, material);
    line.geometry.setDrawRange(0, 0);
    line.layers.enable(1);
    line.position.z = 0.25;
    scene.add(line);
    lightning.push(line);
}

function buildHexes(scene)
{
    const loader = new SVGLoader();
    loader.load(
        '/assets/images/odal_rune.svg',
        function (data ) {
            const paths = data.paths;
            for (let i = 0; i < paths.length; i ++ ) {
                const path = paths[i];
                const material = new THREE.MeshBasicMaterial({
                    color: 0xff0000,
                    side: THREE.DoubleSide,
                });
                const shapes = path.toShapes(true );
                for (let j = 0; j < shapes.length; j ++) {
                    const shape = shapes[j];
                    const geometry = new THREE.ShapeGeometry(shape);
                    geometry.center();
                    const mesh = new THREE.Mesh(geometry, material);
                    rune.add(mesh);
                }
            }
        },
    );

    rune.scale.set(0.0005, 0.0005, 0.0005);
    rune.position.z = 1;

    makeGeometry();

    const material = new THREE.MeshLambertMaterial({
        color: 0x000000,
        transparent: true,
        opacity: 1,
        side: THREE.DoubleSide
    });

    const Hex = Honeycomb.extendHex({
        size: cellSize + 0.01,
        orientation: 'flat',
    });
    Grid = Honeycomb.defineGrid(Hex)
    hexPositions = Grid.rectangle({
        width: 16,
        height: 8,
        start: [-8, -4]
    });

    const lineMaterial = new THREE.LineBasicMaterial({
        color: 0xffffff,
        linewidth: 1,
        transparent: true,
        opacity: 1
    });

    for (let i = 0; i < hexPositions.length; i++) {
        const myHex = Hex(hexPositions[i].x, hexPositions[i].y, {
            idPos: i,
        });
        hexPositions.set(i, myHex);
        const edges = new THREE.EdgesGeometry(cellGeo);
        let line = new THREE.LineSegments(edges, lineMaterial.clone());

        if (i === 68) {
            line = new THREE.LineSegments(edges, new THREE.LineBasicMaterial({
                color: 0xffffff,
                linewidth: 5,
                transparent: true,
                opacity: 1
            }));
        }

        line.layers.enable(1);
        const mesh = new THREE.Mesh(cellGeo, material.clone());
        mesh.name = 'hex_' + i;
        mesh.add(line);

        const point = hexPositions[i].toPoint();
        mesh.position.set(point.x, point.y, 0);
        hexGroup.add(mesh);
    }

    scene.add(hexGroup);
    hexGroup.children[68].add(rune);

    const centerHexCoords = Grid.pointToHex(0, 0);
    centerHex = hexPositions.get(centerHexCoords);
}

function adjacentCornersByDirection(hexDirection)
{
    switch (hexDirection) {
        case 0:
            return {
                0: 4,
                1: 3
            };
        case 1:
            return {
                1: 5,
                2: 4,
            };
        case 2:
            return {
                2: 0,
                3: 5
            };
        case 3:
            return {
                3: 1,
                4: 0
            };
        case 4:
            return {
                4: 2,
                5: 1
            };
        case 5:
            return {
                5: 3,
                0: 2
            };
    }
}

function makeGeometry()
{
    const vectors = [];
    for (let i = 0; i < 6; i++) {
        const angle = ((Math.PI*2) / 6) * i;
        vectors.push(new THREE.Vector3((cellSize * Math.cos(angle)), (cellSize * Math.sin(angle)), 0));
    }

    const cellShape = new THREE.Shape();
    cellShape.moveTo(vectors[0].x, vectors[0].y);
    for (let i = 1; i < 6; i++) {
        cellShape.lineTo(vectors[i].x, vectors[i].y);
    }
    cellShape.lineTo(vectors[0].x, vectors[0].y);
    cellShape.autoClose = true;

    cellGeo = new THREE.ShapeGeometry(cellShape);
}

export function shootHexes(boolean)
{
    shootHex = boolean;
}

export function causeDispersion()
{
    disperse = true;
}

export function createPortal()
{
    initiatePortal = true;
}
