import * as THREE from 'three';

export function calculateRadian(degree) {
    if (degree >= 360) {
        degree = degree - 360;
    }
    return THREE.MathUtils.degToRad(degree);
}

export function normalize(value, min, max)
{
    return (value - min) / (max - min);
}

export function controlPointsByHeight(degree, x, y, height)
{
    let radian = calculateRadian(degree);
    let controlPointX = x + (height * Math.cos(radian));
    let controlPointY = y + (height * Math.sin(radian));
    return {
        x: controlPointX,
        y: controlPointY
    }
}

export function buildPath(path, heightAddition, quantity)
{
    let startX = 0;
    let startY = 0;
    let currentDegree = 0;
    for (let i = 0; i < quantity; i++) {
        let radian = calculateRadian((360/quantity)*currentDegree);
        let x = Math.cos(radian);
        let y = Math.sin(radian);
        let controlPointX = x + (heightAddition * Math.cos(radian));
        let controlPointY = y + (heightAddition * Math.sin(radian));
        if (i === 0) {
            path.moveTo(controlPointX, controlPointY);
            startX = controlPointX;
            startY = controlPointY;
        } else {
            path.lineTo(controlPointX, controlPointY);
        }

        currentDegree++;
    }

    path.lineTo(startX, startY);

    return path;
}
