import * as THREE from 'three';
import * as TranceMath from '/assets/js/math';

let rings = [];
let outerRing = null;
let direction = null;
let stretching = false;
let ending = false;

export function destroy(scene)
{
    for (let i = 0; i < rings.length; i++) {
        rings[i].material.dispose();
        rings[i].geometry.dispose();
        scene.remove(rings[i]);
    }

    if (outerRing) {
        outerRing.geometry.dispose();
        outerRing.material.dispose();
        scene.remove(outerRing);
    }
}

export function create(scene)
{
    let startZ = -10;

    const path = TranceMath.buildPath(new THREE.Path(), -5, 128);
    const geometry = new THREE.BufferGeometry();
    const material = new THREE.LineBasicMaterial({
        linewidth: 3,
        transparent: true,
        opacity: 0,
        vertexColors: true
    });

    const colors = [];
    const color = new THREE.Color();
    color.setHSL(0.5, 0.75, 0.4);
    for (let i = 0; i < path.getPoints().length; i++) {
        colors.push(color.r, color.g, color.b);
        if (i < path.getPoints().length/2) {
            color.offsetHSL(0.0078125, 0.0, 0.0);
        } else {
            color.offsetHSL(-0.0078125, 0.0, 0.0);
        }
    }

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(128*3, 3 ))
        .setFromPoints(path.getPoints());
    geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));

    for (let i = 0; i < 128; i++) {
        const ring = new THREE.Line(geometry, material);
        ring.position.z = startZ;
        ring.layers.enable(1);
        scene.add(ring);
        rings.push(ring);
        startZ += 0.1;
    }

    const outerPath = TranceMath.buildPath(new THREE.Path(), -2, 128);
    const outerGeometry = new THREE.BufferGeometry();
    const outerMaterial = new THREE.LineBasicMaterial({
        linewidth: 20,
        color: 0xffffff
    });
    outerGeometry.setAttribute('position', new THREE.Float32BufferAttribute(128*3, 3 ))
        .setFromPoints(outerPath.getPoints());
    outerRing = new THREE.Line(outerGeometry, outerMaterial);
    outerRing.position.z = 2.01;
    scene.add(outerRing);
}

export function animate(audioData, frustum)
{
    if (rings.length === 0) {
        return;
    }

    if (rings.length > 0 && rings[0].material.opacity < 1) {
        rings[0].material.opacity += 0.01;
    }
    for (let x = 0; x < rings.length; x++) {
        rings[x].position.z = THREE.MathUtils.lerp(rings[x].position.z, rings[x].position.z + 0.2, 0.1);
        rings[x].material.color.offsetHSL(0, 0, 0.1);
        if (x % 2 === 0) {
            rings[x].rotation.z += 0.01;
        } else {
            rings[x].rotation.z -= 0.01;
        }

        if (ending === false && rings[x].position.z > 2) {
            rings[x].position.z = -10;
        }
    }

    if (direction === 'up') {
        outerRing.position.z = THREE.MathUtils.lerp(outerRing.position.z, 6, 0.005);
        if (outerRing.position.z > 2) {
            direction = null;
        }
    }

    if (direction === 'down') {
        outerRing.position.z = THREE.MathUtils.lerp(outerRing.position.z, -6, 0.005);
        if (outerRing.position.z < -0.7) {
            direction = null;
        }
    }
}

export function flashUp()
{
    direction = 'up';
    outerRing.position.z = -0.5;
}

export function flashDown()
{
    direction = 'down';
    outerRing.position.z = 2.1;
}

export function stretch()
{
    stretching = true;
}

export function end()
{
    ending = true;
}
