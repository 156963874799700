import * as THREE from 'three';
import Stats from '/assets/js/thirdparty/stats';
import { CameraFactory, DESKTOP_CAMERA, XR_CAMERA } from "/assets/js/utils/cameraFactory";
import { Chapter } from "/assets/js/chapters/chapter";
import { Chapter1 } from "/assets/js/chapters/chapter1";
import { Chapter2 } from "/assets/js/chapters/chapter2";
import { Chapter3 } from "/assets/js/chapters/chapter3";
import { Chapter4 } from '/assets/js/chapters/chapter4';

// VR Test
import { VRButton } from 'three/examples/jsm/webxr/VRButton.js';
import { BoxLineGeometry } from 'three/examples/jsm/geometries/BoxLineGeometry.js';

//import * as Colyseus from "colyseus.js";
import * as Fader from "./fader";
import { AudioData } from "./services/audioData";
//import * as Mousetrap from "./thirdparty/mousetrap";
//import * as ClubberTest from '/assets/js/debug/clubberTest';

// Core owns the render loop, camera, renderer, which are now inside the cameraFactory
let camera;
let cameraFactory;
let scene;
let audioData;
let audio;
let stats;
let audioInfo = {
    duration: null,
    currentTime: null
}
let gainNode;

// XR/Desktop(3D)/Mobile
let room;

// Potential environment settings:
let isLive = false;

let statsContainers = [];

let currentChapterIndex = 0;
let chapterArray = [];

let lastTimestamp = null;
let animateRunning = false;
let reduceGain = false;

//Update this timeline with episodic changes. Each chapter will implement its own internal timeline.
const timeline = {
    '10:05': function () {
        Fader.setBlocked(true);
    },
    '10:06': function () {
        nextChapter();
    },
    '10:07': function () {
        Fader.setBlocked(false);
    },
    '16:18': function () {
        Fader.setBlocked(true);
    },
    '16:20': function () {
        Fader.setBlocked(false);
    },
    '26:32': function () {
        Fader.setBlocked(true);
    },
    '26:33': function () {
        nextChapter();
        Fader.setBlocked(false);
    },
    '38:30': function() {
        Fader.setBlocked(true);
    },
    '38:32': function() {
        nextChapter();
        Fader.setBlocked(false);
    },
    '60:58': function() {
        reduceGain = true;
    },
    '61:00': function() {
        Fader.setBlocked(true);
    },
    '61:02': function() {
        const canvases = document.getElementsByTagName("canvas");
        for (let i = 0; i < canvases.length; i++) {
            canvases[i].remove();
        }
        document.getElementById('theEnd').style.display = 'block';
    }
};

function setup() {
    // Setup base renderer
    setupRendering();

    if (isLive) {
        setupLiveAudio();
    } else {
        const activeChapters = [
            Chapter1,
            Chapter2,
            Chapter3,
            Chapter4,
        ];

        for (let i = 0; i < activeChapters.length; i++) {
            chapterArray.push(new activeChapters[i](cameraFactory, scene));
        }

        Fader.create(scene);
        //ClubberTest.create(scene);

        chapterArray[currentChapterIndex].start();

        /*
        Mousetrap.bind(['alt+d', 'cmd+d'], function(e) {
            e.preventDefault();
            ClubberTest.toggle();
        });
        */

        preload();

        document.getElementById('inner').onclick = function() {
            const audio = setupPrerecordedAudio();
            audio.play();
            document.getElementById('playButton').style.display = "none";
        }
    }
}

function setupPrerecordedAudio()
{
    audio = setupAudio('/assets/tracks/arrival.mp3', false);
    audio.addEventListener("play", function () { play(); }, true);
    audio.addEventListener('timeupdate', function () { timelineEvents(); }, true);
    document.getElementById('audioContainer').appendChild(audio);
    audio.addEventListener('loadedmetadata', (event) => {
        audioInfo.duration = audio.duration;
        //Change the minutes/seconds here if you want to fast forward to a particular point.
        //audio.currentTime = secondsFromTimestamp('60:58');
    });
    return audio;
}

function setupLiveAudio()
{
    const MUSIC_URL = 'https://audio-edge-fp8o9.yyz.d.radiomast.io/2d0181ed-dc79-487d-bef1-ca248f2e62bc';
    audio = setupAudio(MUSIC_URL, true);
    //setup networking if live
    console.log("Setting up netstate connections");
    let STATE_SERVER = "netstate.localhost";
    const stateClient = new Colyseus.Client(`ws://${STATE_SERVER}:9981`);

    stateClient.join("state_handler").then(room => {
        console.log("state_handler", room.sessionId, "joined", room.name);
    }).catch(e => {
        console.log("state_handler JOIN ERROR", e);
    });

    return audio;
}

function preload()
{
    for (let i = 0; i < chapterArray.length; i++) {
        chapterArray[i].preload();
    }
}

function timestampFromSeconds(time) {
    const minutes = Math.floor(time / 60);
    const seconds = '0' + (Math.floor(time - (minutes * 60))).toString();
    return (minutes + ':' + seconds.slice(-2));
}

function secondsFromTimestamp(timestamp) {
    const split = timestamp.split(':');
    return parseInt(parseInt(split[0]*60) + parseInt(split[1]));
}

function setupRendering() {
    scene = new THREE.Scene();

    /* XR */

    /* Desktop 3D */

    /* Desktop Flat */

    // TODO: Move this into config
    let fov = 90;
    let aspect = window.innerWidth / window.innerHeight;
    let nearPlane = 0.1;
    let farPlane = 100;
    cameraFactory = new CameraFactory().createCamera(DESKTOP_CAMERA, fov, aspect, nearPlane, farPlane);
    camera = cameraFactory.camera;
    camera.position.z = 2;
    document.body.appendChild(cameraFactory.renderer.domElement);

    // Debug stats
    // stats = new Stats();
    // stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
    // statsContainers.push(stats);
    // document.getElementById('stats').appendChild(stats.dom);
    window.addEventListener('resize', onWindowResize, false);
}

function animate() {
    animateRunning = true;

    //stats.begin();

    audioData.update();

    // update current scene state, and render it
    chapterArray[currentChapterIndex].animate(audioData);
    chapterArray[currentChapterIndex].scene.composer.render();

    // ClubberTest.animate(audioData);
    Fader.animate();

    // This method tells the browser that you wish to perform an animation
    // and requests that the browser calls a specified function to update
    // an animation before the next repaint.

    // VR TODO
    // XR renderer.setAnimationLoop( render );
    // XRRender();
    requestAnimationFrame(animate); // note no renderer

    if (reduceGain === true ) {
        if (gainNode.gain.value > 0) {
            gainNode.gain.value -= 0.001;
        }
    }

    //stats.end();
}

// TODO: For XR
function XRRender() {
    cameraFactory.renderer.render(scene, camera);
}

function setupAudio(track, live) {
    const audio = new Audio();
    audio.src = track;
    audio.controls = false;

    if (live) {
        audio.crossOrigin = 'anonymous';
        audio.autoplay = true;
    }

    audio.id = 'audio';
    audio.preload = 'metadata';

    const context = new AudioContext({
        sampleRate: 48000
    });
    let analyser = context.createAnalyser();
    gainNode = context.createGain();

    const source = context.createMediaElementSource(audio);
    analyser.fftSize = 2048;
    source.connect(analyser);
    analyser.connect(gainNode);
    gainNode.connect(context.destination);

    audioData = new AudioData(analyser, source);

    return audio;
}

function play() {
    if (animateRunning === false) {
        animate();
    }
}

function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    cameraFactory.renderer.setSize(window.innerWidth, window.innerHeight);

    chapterArray[currentChapterIndex].scene.composer.render();
}

function timelineEvents() {
    audioInfo.currentTime = audio.currentTime;
    const timestamp = timestampFromSeconds(audio.currentTime);
    if (timeline.hasOwnProperty(timestamp) && lastTimestamp !== timestamp) {
        timeline[timestamp]();
    }
    lastTimestamp = timestamp;
    chapterArray[currentChapterIndex].timeline(timestamp);
    updateAudioTracker(timestamp);
}

function nextChapter() {
    chapterArray[currentChapterIndex].end();
    currentChapterIndex++;
    chapterArray[currentChapterIndex].start();
}

function updateAudioTracker(timestamp)
{
    document.getElementById('audioTracker').innerHTML = timestamp;
}

document.addEventListener('DOMContentLoaded', setup);
