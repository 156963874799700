import * as THREE from 'three';

let cloudParticles = [];
let blueLight2;
let orangeLight2;
let melodyLight;
const starCount = 3000;
let rings = [];
let stars = null;

export function destroy(scene)
{
    for (let i = 0; i < cloudParticles.length; i++) {
        cloudParticles[i].geometry.dispose();
        cloudParticles[i].material.dispose();
        scene.remove(cloudParticles[i]);
    }
    cloudParticles = [];

    scene.remove(blueLight2);
    scene.remove(orangeLight2);
    scene.remove(melodyLight);

    for (let i = 0; i < rings.length; i++) {
        rings[i].geometry.dispose();
        rings[i].material.dispose();
        scene.remove(rings[i]);
    }
    rings = [];

    stars.geometry.dispose();
    stars.material.dispose();
    scene.remove(stars);
    stars = null;
}

export function createNebula(scene, withRings)
{
    if (withRings === undefined) {
        withRings = true;
    }

    let loader = new THREE.TextureLoader();
    loader.load("/assets/images/smoke-1.png", function(texture) {
        const cloudGeo = new THREE.PlaneBufferGeometry(10, 10);
        const cloudMaterial = new THREE.MeshLambertMaterial({
            map: texture,
            transparent: true,
            opacity: 0.5,
        });

        for (let i = 0; i < 15; i++) {
            let cloud = new THREE.Mesh(cloudGeo, cloudMaterial);
            cloud.position.set(
                Math.random()*7,
                Math.random()*7,
                -5 + -Math.random()
            );
            cloud.rotation.z = Math.random()*2*Math.PI;
            cloudParticles.push(cloud);
            scene.add(cloud);
        }

        for (let i = 0; i < 15; i++) {
            let cloud = new THREE.Mesh(cloudGeo, cloudMaterial);
            cloud.position.set(
                -Math.random()*7,
                -Math.random()*7,
                -5 + -Math.random()
            );
            cloud.rotation.z = Math.random()*2*Math.PI;
            cloudParticles.push(cloud);
            scene.add(cloud);
        }
    });


    orangeLight2 = new THREE.PointLight(0xcc6600,2,15,1.7);
    orangeLight2.position.set(-3,-3,-5);
    scene.add(orangeLight2);

    melodyLight = new THREE.PointLight(0xd8547e,2,15,1.7);
    melodyLight.position.set(3.5,2,-2);
    scene.add(melodyLight);

    blueLight2 = new THREE.PointLight(0x3677ac,2,10,2.5);
    blueLight2.position.set(3,-1.5,-2);
    scene.add(blueLight2);

    const positions = new Float32Array(starCount * 3);
    for (let i = 0; i < starCount; i++) {
        positions[i*3] = THREE.MathUtils.randFloat(-10, 10);
        positions[(i*3)+1] = THREE.MathUtils.randFloat(-10, 10);
        positions[(i*3)+2] = THREE.MathUtils.randFloat(-5, -2);
    }
    const geometry2 = new THREE.BufferGeometry();
    geometry2.setAttribute( 'position', new THREE.BufferAttribute(positions, 3));
    geometry2.computeBoundingSphere();
    const material2 = new THREE.PointsMaterial({
        size: 0.01
    });
    stars = new THREE.Points(geometry2, material2);
    scene.add(stars);

    if (withRings === true) {
        createRings(scene);
    }
}

export function animate(audioData)
{
    if (cloudParticles.length === 0) {
        return;
    }

    for (let i = 0; i < cloudParticles.length; i++) {
        cloudParticles[i].rotation.z -= 0.001;
    }

    blueLight2.intensity = audioData.getBeatStrength()*50;
    orangeLight2.intensity = audioData.getMelodyOctaveIndex()*45;
    melodyLight.intensity = audioData.getMelodyOctaveIndex()*15;
    const melodyColor = new THREE.Color(0xffffff);
    melodyColor.setHSL(audioData.getMelodyOctaveIndex(), 1, 0.5);
    melodyLight.color.lerpHSL(melodyColor, 0.05);

    let time = Date.now() * 0.00025;
    let d = 2;

    blueLight2.position.x = -Math.sin(time * 3.5) * d;
    blueLight2.position.y = -Math.cos(time * 2.5) * d;

    const color = new THREE.Color();
    for (let i = 0; i < rings.length; i++) {
        color.setHSL(audioData.getNotes()[i]/500, 1, 0.5);
        rings[i].material.opacity = audioData.getNotes()[i]/250;
        rings[i].material.emissiveIntensity = audioData.getNotes()[i]/1000;
        rings[i].material.emissive = color;
    }
}

function createRings(scene)
{
    let radius = 5;
    const material = new THREE.MeshLambertMaterial({
        color: 0x0fbcf9,
        emissive: 0xd2dae2,
        emissiveIntensity: 0.1,
        transparent: true,
        opacity: 0.1
    });
    for (let i = 0; i < 128; i++) {
        const geometry = new THREE.RingBufferGeometry(radius, radius + 0.1, 128);
        const mesh = new THREE.Mesh(geometry, material);
        mesh.position.set(0, 0, -8);
        scene.add(mesh);
        rings.push(mesh);
        radius += 0.2;
    }
}
