import {EffectComposer} from 'three/examples/jsm/postprocessing/EffectComposer';
import {RenderPass} from 'three/examples/jsm/postprocessing/RenderPass';
import {ShaderPass} from "three/examples/jsm/postprocessing/ShaderPass";
import {FXAAShader} from "three/examples/jsm/shaders/FXAAShader";
import * as THREE from 'three';
import {AmbientLight, DirectionalLight, PointLight, SpotLight} from "three";

export class Chapter {
  constructor(cameraFactory, scene, settings = {}) {
    this.scene = scene;
    this.camera = cameraFactory.camera;
    this.frustum = cameraFactory.frustum;
    this.renderer = cameraFactory.renderer;
    this.settings = settings;
    this.scene.composer = new EffectComposer(this.renderer);
    this.renderPass = new RenderPass(this.scene, this.camera);
    this.scene.composer.addPass(this.renderPass);
    this.lastTimestamp = null;
    this.chapterTimeline = {};
    this.lights = {};

    // Anti-Alias
    const fxaaShaderPass = new ShaderPass(FXAAShader);
    const pixelRatio = this.renderer.getPixelRatio();
    fxaaShaderPass.material.uniforms[ 'resolution' ].value.x = 1 / ( window.innerWidth * pixelRatio );
    fxaaShaderPass.material.uniforms[ 'resolution' ].value.y = 1 / ( window.innerHeight * pixelRatio );
    this.scene.composer.addPass(fxaaShaderPass);
  }



  get composer()
  {
    return this.scene.composer;
  }

  getRenderPass()
  {
    return this.renderPass;
  }

  start() {
  };

  customizeScene() {
  }

  addComposers() {
  }

  animate(audioData) {
  }

  preload() {
    //Preload anything needed here
  }

  end() {
    for (const [key, value] of Object.entries(this.lights)) {
      this.scene.remove(value);
    }

    const childCount = this.scene.children.length;
    for (let i = 0; i < childCount; i++) {
      if (this.scene.children[i] instanceof PointLight) {
        this.scene.children[i].intensity = 0;
      }
      if (this.scene.children[i] instanceof DirectionalLight) {
        this.scene.children[i].intensity = 0;
      }
      if (this.scene.children[i] instanceof AmbientLight) {
        this.scene.children[i].intensity = 0;
      }
      if (this.scene.children[i] instanceof SpotLight) {
        this.scene.children[i].intensity = 0;
      }
    }
  };

  timeline(timestamp) {
    if (this.lastTimestamp === null) {
      //Fast forward through the timeline if we're starting further in
      for (const key in this.chapterTimeline) {
        if (key !== timestamp && key < timestamp) {
          this.chapterTimeline[key](this);
        }
      }
    }

    if (this.chapterTimeline.hasOwnProperty(timestamp) && this.lastTimestamp !== timestamp) {
      this.chapterTimeline[timestamp](this);
    }
    this.lastTimestamp = timestamp;
  };
}
