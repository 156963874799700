import * as THREE from 'three';
import * as noise from "/assets/js/thirdparty/noise";
import {AmbientLight, DirectionalLight, PointLight} from "three";

let lastTime = Date.now();

let curve,
    splineMesh,
    tubeMaterial,
    tubeGeometry,
    tubeGeometry_o,
    tubeMesh;

const segments = 256;

export function destroy(scene)
{
    tubeMesh.geometry.dispose();
    tubeMesh.material.dispose();
    scene.remove(tubeMesh);
}

export function preload()
{
    //Preload any items here - don't add them to the scene yet
}

export function create(scene)
{
    noise.seed();
    const points = [];

    for (let i = 0; i < 10; i++) {
        points.push(new THREE.Vector3(0, 0, 10 * (i / 9)));
    }
    points[4].y = -0.06;

    curve = new THREE.CatmullRomCurve3(points);
    curve.type = "catmullrom";

    const geometry = new THREE.Geometry();
    geometry.vertices = curve.getPoints(segments);
    splineMesh = new THREE.Line(geometry, new THREE.LineBasicMaterial());

    tubeMaterial = new THREE.MeshLambertMaterial({
        side: THREE.BackSide,
        vertexColors: THREE.FaceColors
    });

    tubeGeometry = new THREE.TubeGeometry(
        curve,
        segments,
        0.5,
        6,
        false
    );

    for (let i = 0; i < tubeGeometry.faces.length; i++) {
        const f = tubeGeometry.faces[i];
        const p = tubeGeometry.vertices[f.a];
        const color = new THREE.Color(
            "hsl(" +
            (Math.floor(
                Math.abs(noise.simplex3(p.x * 2, p.y * 4, p.z * 2)) * 80 * 100
                ) *
                0.01 +
                180) +
            ",70%,60%)"
        );
        f.color = color;
    }

    tubeGeometry_o = tubeGeometry.clone();
    tubeMesh = new THREE.Mesh(tubeGeometry, tubeMaterial);
    scene.add(tubeMesh);
}

export function animate(audioData)
{
    if (tubeGeometry) {
        // light.intensity = audioData.getBeatStrength() * 2 + audioData.getHihatStrength();
        // if (audioData.isBeat()) {
        //     light.color = new THREE.Color(0x4bcffa);
        // } else {
        //     light.color = new THREE.Color(0xffffff);
        // }
        //
        // if (lightTimer < 1000) {
        //     light.position.z = THREE.MathUtils.lerp(light.position.z, 2, 0.01);
        //     lightTimer++;
        // } else if (lightTimer > 1000 && lightTimer < 2000) {
        //     light.position.z = THREE.MathUtils.lerp(light.position.z, -2, 0.01);
        //     lightTimer++;
        // } else {
        //     lightTimer = 0;
        // }

        let time = Date.now();
        let index = 0;
        let vertice_o = null;
        let vertice = null;
        for (let i = 0; i < tubeGeometry.vertices.length; i++) {
            vertice_o = tubeGeometry_o.vertices[i];
            vertice = tubeGeometry.vertices[i];
            index = Math.floor(i / segments);
            vertice.x += ((vertice_o.x + splineMesh.geometry.vertices[index].x) - vertice.x) / 15;
            vertice.y += ((vertice_o.y + splineMesh.geometry.vertices[index].y) - vertice.y) / 15;
            vertice.applyAxisAngle(
                new THREE.Vector3(0,0,1),
                Math.abs(
                    Math.cos(
                        time * 0.0008 + vertice.z * 3
                    )
                )*0.1
            );
        }
        tubeGeometry.verticesNeedUpdate = true;

        curve.points[2].x = 100 * (1 - 0.9) - 50;
        curve.points[4].x = 100 * (1 - 0.9) - 50;

        curve.points[2].y = 100 * (1 - 0.9) - 50;
        curve.points[4].y = 100 * (1 - 0.9) - 50;

        splineMesh.geometry.verticesNeedUpdate = true;
        splineMesh.geometry.vertices = curve.getPoints(segments);

        const delta = Date.now() - lastTime;
        lastTime = Date.now();
        let f, p, h, rgb;
        for (let i = 0; i < tubeGeometry.faces.length; i++) {
            f = tubeGeometry.faces[i];
            p = tubeGeometry.vertices[f.a];
            h = (
                    Math.floor(
                        Math.abs(
                            noise.simplex3(
                                p.x ,
                                p.y * 2,
                                p.z * 2 + delta*0.0003
                            )
                        )
                        * 80 * 100
                    )
                * 0.01 + 180
            ) / 360;
            h += audioData.getBeatStrength()/6;
            //h -= audioData.getMelodyOctaveIndex() / 25;
            rgb = hslToRgb(h, 0.8,0.6)
            const color = new THREE.Color(rgb[0], rgb[1], rgb[2]);
            f.color = color;
        }
        tubeGeometry.elementsNeedUpdate = true;
    }
}

function hslToRgb(h, s, l){
    let r, g, b;

    if (s === 0){
        r = g = b = l; // achromatic
    } else {
        const hue2rgb = function hue2rgb(p, q, t){
            if(t < 0) t += 1;
            if(t > 1) t -= 1;
            if(t < 1/6) return p + (q - p) * 6 * t;
            if(t < 1/2) return q;
            if(t < 2/3) return p + (q - p) * (2/3 - t) * 6;
            return p;
        }

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hue2rgb(p, q, h + 1/3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1/3);
    }

    return [Math.round(r*100)*0.01, Math.round(g*100)*0.01, Math.round(b*100)*0.01];
}

