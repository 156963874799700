import * as THREE from 'three';
import PerlinVert from '/assets/shaders/vert/perlin.vert';
import PerlinFrag from '/assets/shaders/frag/perlin.frag';

let beast = null;
let animated = false;

const options = {
    perlin: {
        vel: 0.005,
        speed: 0.000395,
        decay: 0.1,
        complex: 0.6,
        waves: 15.0,
        fragment: false,
        redhell: true
    },
    spin: {
        sinVel: 0.2,
        ampVel: 80.0,
    }
}
let mat;
let start = Date.now();

export function destroy(scene)
{
    if (beast !== null) {
        scene.remove(beast);
    }
}

export function create(scene)
{
    beast = new THREE.Object3D();
    mat = new THREE.ShaderMaterial( {
        wireframe: false,
        uniforms: {
            time: {
                type: "f",
                value: 0.0
            },
            pointscale: {
                type: "f",
                value: 0.0
            },
            decay: {
                type: "f",
                value: 0.0
            },
            complex: {
                type: "f",
                value: 0.0
            },
            waves: {
                type: "f",
                value: 0.0
            },
            eqcolor: {
                type: "f",
                value: 0.0
            },
            fragment: {
                type: "i",
                value: false
            },
            redhell: {
                type: "i",
                value: true
            }
        },
        vertexShader: PerlinVert,
        fragmentShader: PerlinFrag
    });
    let geo = new THREE.IcosahedronBufferGeometry(0.1, 7);
    let mesh = new THREE.Points(geo, mat);

    beast.add(mesh);
    beast.position.set(0, 0, -100);
    scene.add(beast);
}

export function animate(audioData)
{
    if (animated === false) {
        return;
    }
    const desiredPosition = new THREE.Vector3(0, 0, -5);
    if (beast !== null) {
        let currentPosition = new THREE.Vector3(beast.position.x, beast.position.y, beast.position.z);
        currentPosition.lerp(desiredPosition, 0.05)
        beast.position.set(currentPosition.x, currentPosition.y, currentPosition.z);

        const performance = Date.now() * 0.003;

        beast.rotation.y += audioData.getBeatStrength()/200;
        beast.rotation.x = (Math.sin(performance * options.spin.sinVel) * options.spin.ampVel )* Math.PI / 180;

        let decay = 0.1;

        mat.uniforms['time'].value = options.perlin.speed * (Date.now() - start);
        mat.uniforms['pointscale'].value = 1;
        mat.uniforms['decay'].value = decay;
        mat.uniforms['complex'].value = options.perlin.complex;
        mat.uniforms['waves'].value = options.perlin.waves;
        mat.uniforms['eqcolor'].value = audioData.getBeatStrength()*100;
        mat.uniforms['fragment'].value = options.perlin.fragment;
        mat.uniforms['redhell'].value = options.perlin.redhell;
    }
}

export function setRedHell(boolean)
{
    options.perlin.redhell = boolean;
}

export function setAnimate(boolean)
{
    animated = boolean;
}
