import * as THREE from 'three';
import * as TranceMath from '/assets/js/math';

let ico;
let bouncers = [];
let melodyObjects;
const melodyQuantity = 128;
let puffer;
let puffers = [];
let controlPoints = [];
let spiralGroups = [];
let spiralSpeed = 0.01;
let pleaseAnimate = true;

export function destroy(scene)
{
    pleaseAnimate = false;
    for (let i = 0; i < spiralGroups.length; i++) {
        spiralGroups[i].position.z = 3;
        spiralGroups[i].visible = false;
        spiralGroups[i].frustumCulled = true;
        scene.remove(spiralGroups[i]);
    }
    spiralGroups[0].geometry.dispose();
    spiralGroups[0].material.dispose();

    ico.geometry.dispose();
    ico.material.dispose();
    scene.remove(ico);

    puffer.geometry.dispose();
    puffer.material.dispose();
    scene.remove(puffer);

    const bouncerCount = bouncers.length;
    for (let i = 0; i < bouncerCount; i++) {
        const childrenCount = bouncers[i].children.length;
        for (let x = 0; x < childrenCount; x++) {
            bouncers[i].children[x].material.dispose();
            bouncers[i].children[x].geometry.dispose();
            scene.remove(bouncers[i].children[x]);
        }
        scene.remove(bouncers[i]);
    }
}

export function create(scene)
{
    const geo = new THREE.IcosahedronBufferGeometry(0.75);
    const material = new THREE.MeshPhongMaterial({
        color: 0x1e272e,
        shininess: 60,
        specular: 0x485460
    });
    ico = new THREE.Mesh(geo, material);
    ico.receiveShadow = true;
    ico.position.set(0, 0, -1);

    scene.add(ico);

    const bouncer = createBouncer(0xd2dae2);
    bouncer.position.set(0, 0, 1);
    scene.add(bouncer);
    bouncers.push(bouncer);

    const pufferMaterial = new THREE.MeshBasicMaterial({
        color: 0x4bcffa,
        transparent: true,
        opacity: 1
    });

    const pufferGeometry = new THREE.IcosahedronBufferGeometry(
        0.0015,
    );

    pufferGeometry.setAttribute( 'lifeStart', new THREE.BufferAttribute(new Float32Array(1), 1) );
    pufferGeometry.setAttribute( 'direction', new THREE.BufferAttribute(new Float32Array(1), 1) );

    puffer = new THREE.Mesh(pufferGeometry, pufferMaterial);

    buildSpiral();
    for (let i = 0; i < spiralGroups.length; i++) {
        scene.add(spiralGroups[i]);
    }
}

export function animate(audioData, scene)
{
    if (ico && pleaseAnimate === true) {
        ico.rotation.x += 0.01;
        ico.rotation.y += 0.01;
        ico.rotation.z -= 0.01;

        let time = performance.now() * 0.001;

        const color = new THREE.Color();
        color.setHSL(audioData.getHihatStrength() + audioData.getBeatStrength(), 1, 0.6);

        bouncers[0].color.lerpHSL(color, 0.1);
        bouncers[0].intensity = (audioData.getBeatStrength() + audioData.getMelodyWindowRatio())*2;

        bouncers[0].position.z = 0.75 + Math.sin( time * 0.8 );

        bouncers[0].rotation.x = time;
        bouncers[0].rotation.z = time;

        bouncers[0].children[0].rotation.x += 0.01;
        bouncers[0].children[0].rotation.y += 0.01;
        bouncers[0].children[0].rotation.z += 0.01;

        bouncers[0].children[0].scale.set(
            audioData.getBeatStrength()*2,
            audioData.getBeatStrength()*2,
            audioData.getBeatStrength()*2,
        );

        melodyObjects.rotation.x -= audioData.getBeatStrength()/100;
        melodyObjects.rotation.y += audioData.getMelodyWindowRatio()/100;
        melodyObjects.rotation.z += audioData.getMelodyOctaveIndex()/80;

        const notes = audioData.getNotes();

        const dummy = new THREE.Object3D;
        const vector = new THREE.Vector3();
        let notePointer = 78;
        if (melodyObjects) {
            for (let i = 0; i < melodyQuantity; i++) {
                if (notePointer > 127) {
                    notePointer = 78;
                }
                vector.setFromSphericalCoords(notes[notePointer] / 2000, THREE.MathUtils.degToRad((360 / melodyQuantity) * i), (i+1)*0.9);
                const currMatrix = new THREE.Matrix4();
                melodyObjects.getMatrixAt(i, currMatrix);
                const currVector = new THREE.Vector3();
                currVector.setFromMatrixPosition(currMatrix);
                dummy.position.x = THREE.MathUtils.lerp(currVector.x, vector.x, 0.1);
                dummy.position.y = THREE.MathUtils.lerp(currVector.y, vector.y, 0.1);
                dummy.position.z = THREE.MathUtils.lerp(currVector.z, vector.z, 0.1);

                dummy.updateMatrix();
                melodyObjects.setMatrixAt(i, dummy.matrix);
                notePointer++;
            }

            melodyObjects.instanceMatrix.needsUpdate = true;
        }

        // if (audioData.isBeat()) {
        //     spawnTemporaryPuffer(scene);
        // }

        for (let i = 0; i < puffers.length; i++) {
            const vector3 = new THREE.Vector3(controlPoints[i].x, controlPoints[i].y, 2);
            const startVector = new THREE.Vector3(puffers[i].position.x, puffers[i].position.y, puffers[i].position.z);
            startVector.lerp(vector3, 0.01);
            puffers[i].position.set(
                startVector.x,
                startVector.y,
                startVector.z
            );

            color.setHSL((notes[i]*25)/25, 1, 0.8);
            puffers[i].material.color.lerpHSL(color, 0.5);

            puffers[i].rotation.x += 0.05;
            puffers[i].rotation.y -= 0.05;
            puffers[i].rotation.z += 0.05;

            puffers[i].scale.set(notes[i]/100, notes[i]/100, notes[i]/100);

            if (puffers[i].position.z > 2) {
                scene.remove(puffers[i]);
                puffers[i].geometry.dispose();
                puffers[i].material.dispose();
                puffers.splice(i, 1);
            }
        }

        for (let i = 0; i < spiralGroups.length; i++) {
            let desiredSpiralSpeed = (audioData.getBeatStrength() / (100 + (i*5))) + 0.005;
            spiralSpeed = THREE.MathUtils.lerp(spiralSpeed, desiredSpiralSpeed, 0.01);
            const spiralGroup = spiralGroups[i];
            spiralGroup.rotation.y += spiralSpeed;

            let colors = spiralGroup.geometry.attributes.color.array;
            const vector = new THREE.Vector3();
            const matrix = new THREE.Matrix4();
            const dummy = new THREE.Object3D();
            for (let x = 0; x < 120; x++) {
                color.setHSL((notes[x]/250)+i*0.01, 1, notes[x]/250);
                const existingColor = new THREE.Color(colors[x*3], colors[(x*3)+1], colors[(x*3)+2]);
                existingColor.lerpHSL(color, 0.25);
                existingColor.toArray(colors, x * 3);
                spiralGroup.getMatrixAt(x, matrix);
                vector.setFromMatrixPosition(matrix);
                dummy.position.set(vector.x, vector.y, vector.z);
                dummy.scale.set(notes[x]/200, notes[x]/200, notes[x]/200);
                dummy.updateMatrix();
                spiralGroup.setMatrixAt(x, dummy.matrix);
            }
            spiralGroup.geometry.attributes.color.needsUpdate = true;
            spiralGroup.instanceMatrix.needsUpdate = true;
        }
    }
}

function spawnTemporaryPuffer(scene)
{
    const temporaryPuffer = puffer.clone();
    temporaryPuffer.position.set(bouncers[0].position.x, bouncers[0].position.y, bouncers[0].position.z);
    temporaryPuffer.geometry.attributes.lifeStart.array[0] = Date.now();
    scene.add(temporaryPuffer);
    puffers.push(temporaryPuffer);
    const controlPoint = TranceMath.controlPointsByHeight(
        puffers.length*6,
        puffers[puffers.length-1].position.x,
        puffers[puffers.length-1].position.y,
        0.05
    );
    temporaryPuffer.layers.enable(1);
    controlPoints.push(controlPoint);
}

function createBouncer(color)
{
    const pointLight = new THREE.PointLight(color, 1.5, 20);
    pointLight.castShadow = true;
    pointLight.shadow.camera.near = 1;
    pointLight.shadow.camera.far = 60;
    pointLight.shadow.bias = - 0.005; // reduces self-shadowing on double-sided objects

    const geometry2 = new THREE.IcosahedronBufferGeometry(0.01);
    const material2 = new THREE.MeshLambertMaterial({
        color: color
    });
    material2.color.multiplyScalar(1.5);
    const sphereLight = new THREE.Mesh(geometry2, material2);
    pointLight.add( sphereLight );

    const texture = new THREE.CanvasTexture(generateTexture());
    texture.magFilter = THREE.NearestFilter;
    texture.wrapT = THREE.RepeatWrapping;
    texture.wrapS = THREE.RepeatWrapping;
    texture.repeat.set(1, 4.5);

    const geometry3 = new THREE.SphereBufferGeometry(0.1, 64, 64);
    const material3 = new THREE.MeshPhongMaterial({
        side: THREE.DoubleSide,
        alphaMap: texture,
        alphaTest: 0.5
    });

    const bouncer = new THREE.Mesh(geometry3, material3);
    bouncer.castShadow = true;
    bouncer.receiveShadow = true;
    pointLight.add(bouncer);

    bouncer.customDistanceMaterial = new THREE.MeshDistanceMaterial({
        alphaMap: material3.alphaMap,
        alphaTest: material3.alphaTest
    });

    const melodyMaterial = new THREE.MeshLambertMaterial({
        color: 0x4bcffa,
        transparent: true,
        opacity: 1
    });

    const melodyGeometry = new THREE.IcosahedronBufferGeometry(
        0.005,
    );

    melodyObjects = new THREE.InstancedMesh(melodyGeometry, melodyMaterial, melodyQuantity);
    melodyObjects.instanceMatrix.setUsage(THREE.DynamicDrawUsage);

    pointLight.add(melodyObjects);

    return pointLight;
}

function generateTexture()
{
    const canvas = document.createElement( 'canvas' );
    canvas.width = 2;
    canvas.height = 2;

    const context = canvas.getContext( '2d' );
    context.fillStyle = 'white';
    context.fillRect( 0, 1, 2, 1 );

    return canvas;
}

function buildSpiral()
{
    const radius = 0.2;
    const turns = 4;
    const objPerTurn = 30;

    const angleStep = (Math.PI * 2) / objPerTurn;
    const heightStep = 0.025;

    const instancedGeom = new THREE.InstancedBufferGeometry().fromGeometry(new THREE.TetrahedronGeometry(0.005));
    const color = new Float32Array((turns * objPerTurn) * 3);
    instancedGeom.setAttribute('color', new THREE.InstancedBufferAttribute(color, 3));

    const spiral = new THREE.InstancedMesh(instancedGeom, new THREE.MeshBasicMaterial({ vertexColors: true }), turns * objPerTurn);
    spiral.instanceMatrix.setUsage(THREE.DynamicDrawUsage);
    spiral.layers.enable(1);

    const dummy = new THREE.Object3D();
    for (let i = 0; i < turns * objPerTurn; i++) {
        const matrix = new THREE.Matrix4();
        spiral.getMatrixAt(i, matrix);
        const vector = new THREE.Vector3();
        vector.setFromMatrixPosition(matrix);
        dummy.position.x = Math.cos(angleStep * i) * radius;
        dummy.position.y = heightStep * i;
        dummy.position.z = Math.sin(angleStep * i) * radius;

        dummy.rotation.y = -angleStep * i;
        dummy.updateMatrix();
        spiral.setMatrixAt(i, dummy.matrix);
    }
    spiral.rotation.x = 1.55;
    spiral.position.z = -0.75;
    spiral.position.y -= 0;
    spiralGroups.push(spiral);
    for (let i = 0; i < 12; i++) {
        const spiralGroup2 = spiral.clone();
        spiralGroup2.rotation.y = i+1;
        spiralGroup2.position.z = -0.75 + (i/10);
        spiralGroups.push(spiralGroup2);
    }
}
