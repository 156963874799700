import * as THREE from 'three';

const quantity = 1250;
let stars = null;
let stationaryStars = null;
let end = false;

export function destroy()
{
    end = true;
}

export function create(scene)
{
    if (stars !== null) {
        return;
    }

    const positions = new Float32Array(quantity * 3);
    for (let i = 0; i < quantity; i++) {
        const position = positionStar(true);
        positions[i*3] = position.x;
        positions[(i*3)+1] = position.y;
        positions[(i*3)+2] = position.z;
    }
    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute( 'position', new THREE.BufferAttribute(positions, 3));
    geometry.computeBoundingSphere();
    const material = new THREE.PointsMaterial({
        size: 0.003,
        transparent: true,
        opacity: 0
    });
    stars = new THREE.Points(geometry, material);
    stars.layers.enable(1);
    scene.add(stars);

    const positions2 = new Float32Array(quantity * 3);
    for (let i = 0; i < quantity; i++) {
        const position = positionStar(false);
        positions2[i*3] = position.x;
        positions2[(i*3)+1] = position.y;
        positions2[(i*3)+2] = position.z;
    }
    const geometry2 = new THREE.BufferGeometry();
    geometry2.setAttribute( 'position', new THREE.BufferAttribute(positions2, 3));
    geometry2.computeBoundingSphere();
    const material2 = new THREE.PointsMaterial({
        size: 0.01,
        transparent: true,
        opacity: 0
    });
    stationaryStars = new THREE.Points(geometry2, material2);
    scene.add(stationaryStars);
}

export function animate(scene)
{
    if (stars === null) {
        return;
    }

    if (end === false) {
        if (stars.material.opacity < 0.5) {
            stars.material.opacity += 0.001;
        }

        if (stationaryStars.material.opacity < 0.5) {
            stationaryStars.material.opacity += 0.001;
        }
    } else {
        stars.material.opacity -= 0.001;
        stationaryStars.material.opacity -= 0.001;

        if (stars.material.opacity === 0) {
            stars.material.dispose();
            stars.geometry.dispose();
            stationaryStars.material.dispose();
            stationaryStars.geometry.dispose();
            scene.remove(stars);
            scene.remove(stationaryStars);
            stars = null;
            stationaryStars = null;
            return;
        }
    }

    let positions = stars.geometry.attributes.position.array;
    for (let i = 0; i < positions.length; i++) {
        positions[(i*3)+2] += 0.01;
        if (positions[(i*3)+2] > 2) {
            const newPosition = positionStar(true);
            positions[i*3] = newPosition.x;
            positions[(i*3)+1] = newPosition.y;
            positions[(i*3)+2] = newPosition.z;
        }
    }
    stars.geometry.attributes.position.needsUpdate = true;
}

function positionStar(moving)
{
    const vector = new THREE.Vector3();
    let x = 0;
    let y = 0;
    if (moving === true) {
        while (x > -0.2 && x < 0.2) {
            x = THREE.MathUtils.randFloat(-10, 10);
        }
        while (y > -0.2 && y < 0.2) {
            y = THREE.MathUtils.randFloat(-10, 10);
        }
        vector.z = THREE.MathUtils.randFloat(-10, -5);
    } else {
        y = THREE.MathUtils.randFloat(-10, 10);
        x = THREE.MathUtils.randFloat(-10, 10);
        vector.z = THREE.MathUtils.randFloat(-5, -2);
    }

    vector.x = x;
    vector.y = y;
    return vector;
}
