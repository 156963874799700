import {
    DataTexture,
    FloatType,
    MathUtils,
    RGBFormat,
    ShaderMaterial,
    UniformsUtils
} from "three"
import {Pass} from "three/examples/jsm/postprocessing/Pass";
import {DigitalGlitch} from "../../shaders/js/digitalglitch";

var GlitchPass = function ( dt_size ) {

    Pass.call( this );

    if ( DigitalGlitch === undefined ) {
        console.error( "GlitchPass relies on DigitalGlitch" );
    }

    var shader = DigitalGlitch;
    this.uniforms = UniformsUtils.clone(shader.uniforms);
    this.glitchme = false;
    this.curF = 0;

    if (dt_size == undefined) {
        dt_size = 64;
    }


    this.uniforms["tDisp"].value = this.generateHeightmap(dt_size);


    this.material = new ShaderMaterial( {
        uniforms: this.uniforms,
        vertexShader: shader.vertexShader,
        fragmentShader: shader.fragmentShader
    } );

    this.fsQuad = new Pass.FullScreenQuad( this.material );
};

GlitchPass.prototype = Object.assign( Object.create( Pass.prototype ), {

    constructor: GlitchPass,

    render: function ( renderer, writeBuffer, readBuffer /*, deltaTime, maskActive */ ) {

        this.uniforms[ "tDiffuse" ].value = readBuffer.texture;
        this.uniforms[ 'seed' ].value = Math.random();//default seeding
        this.uniforms[ 'byp' ].value = 0;

        if (this.curF === 180) {
            this.uniforms[ 'amount' ].value = Math.random() / 30;
            this.uniforms[ 'angle' ].value = MathUtils.randFloat( - Math.PI, Math.PI );
            this.uniforms[ 'seed_x' ].value = MathUtils.randFloat( - 1, 1 );
            this.uniforms[ 'seed_y' ].value = MathUtils.randFloat( - 1, 1 );
            this.uniforms[ 'distortion_x' ].value = MathUtils.randFloat( 0, 1 );
            this.uniforms[ 'distortion_y' ].value = MathUtils.randFloat( 0, 1 );
            this.curF = 0;
            this.glitchme = false;
        } else if (this.curF % 180 < 180 / 5 && this.curF !== 0) {
            this.uniforms[ 'amount' ].value = Math.random() / 90;
            this.uniforms[ 'angle' ].value = MathUtils.randFloat( - Math.PI, Math.PI );
            this.uniforms[ 'distortion_x' ].value = MathUtils.randFloat( 0, 1 );
            this.uniforms[ 'distortion_y' ].value = MathUtils.randFloat( 0, 1 );
            this.uniforms[ 'seed_x' ].value = MathUtils.randFloat( - 0.3, 0.3 );
            this.uniforms[ 'seed_y' ].value = MathUtils.randFloat( - 0.3, 0.3 );
        } else {
            this.uniforms[ 'byp' ].value = 1;
        }

        if (this.glitchme === true) {
            this.curF++;
        }

        if ( this.renderToScreen ) {
            renderer.setRenderTarget( null );
            this.fsQuad.render( renderer );
        } else {
            renderer.setRenderTarget( writeBuffer );
            if ( this.clear ) renderer.clear();
            this.fsQuad.render( renderer );
        }
    },

    glitchMe: function()
    {
        this.glitchme = true;
    },

    generateHeightmap: function ( dt_size ) {

        var data_arr = new Float32Array( dt_size * dt_size * 3 );
        var length = dt_size * dt_size;

        for ( var i = 0; i < length; i ++ ) {

            var val = MathUtils.randFloat( 0, 1 );
            data_arr[ i * 3 + 0 ] = val;
            data_arr[ i * 3 + 1 ] = val;
            data_arr[ i * 3 + 2 ] = val;

        }

        return new DataTexture( data_arr, dt_size, dt_size, RGBFormat, FloatType );

    }

} );

export { GlitchPass };
