import * as THREE from 'three';
import * as TranceMath from "/assets/js/math";

let outerRing = null;
let innerRing;
let connectors;
let ringBlockingShape;
let innerGlass;

let outerRingPositions;
let innerRingPositions;

let fadeOut = false;

let newColor = null;
let flyInto = false;

export function destroy(scene)
{
    if (outerRing) {
        outerRing.material.dispose();
        outerRing.geometry.dispose();
        scene.remove(outerRing);
        outerRing = null;
        innerRing.material.dispose();
        innerRing.geometry.dispose();
        scene.remove(innerRing);
        innerRing = null;
        ringBlockingShape.material.dispose();
        ringBlockingShape.geometry.dispose();
        scene.remove(ringBlockingShape);
        ringBlockingShape = null;
        connectors.material.dispose();
        connectors.geometry.dispose();
        scene.remove(connectors);
        connectors = null;
        innerGlass.material.dispose();
        innerGlass.geometry.dispose();
        scene.remove(innerGlass);
        innerGlass = null;
        outerRingPositions = null;
        innerRingPositions = null;
    }
}

export function createViz(scene)
{
    const material = new THREE.LineBasicMaterial({
        color: 0x4bcffa,
        linewidth: 2,
        transparent: true
    });

    const outerRingPath = new THREE.Path();
    const outerRingGeometry = new THREE.BufferGeometry()
        .setAttribute( 'position', new THREE.BufferAttribute(new Float32Array(128 * 3), 3))
        .setFromPoints(buildPath(outerRingPath, 0.2).getPoints());
    outerRing = new THREE.Line(outerRingGeometry, material);
    outerRing.layers.enable(1);

    scene.add(outerRing);

    outerRingPositions = JSON.parse(JSON.stringify(outerRingGeometry.attributes.position.array));

    const innerRingPath = new THREE.Path();
    const innerRingGeometry = new THREE.BufferGeometry()
        .setAttribute( 'position', new THREE.BufferAttribute(new Float32Array(128 * 3), 3))
        .setFromPoints(buildPath(innerRingPath, 0).getPoints());
    innerRing = new THREE.Line(innerRingGeometry, material);
    innerRing.layers.enable(1);

    scene.add(innerRing);

    innerRingPositions = JSON.parse(JSON.stringify(innerRingGeometry.attributes.position.array));

    const connectorGeometry = new THREE.BufferGeometry()
        .setAttribute( 'position', new THREE.BufferAttribute(new Float32Array(128 * 3), 3))
        .setFromPoints(buildConnectors().getPoints());
    connectors = new THREE.LineSegments(connectorGeometry, material);

    scene.add(connectors);

    const blockingShape = buildPath(new THREE.Shape(), 0);
    const blockingShapeHole = buildPath(new THREE.Path(), -0.01);
    blockingShape.holes.push(blockingShapeHole);

    const blockingGeometry = new THREE.ShapeGeometry(blockingShape);
    const blockingMaterial = new THREE.MeshBasicMaterial({
        color: 0x000000,
        transparent: true
    });
    ringBlockingShape = new THREE.Mesh(blockingGeometry, blockingMaterial);
    ringBlockingShape.position.set(0, 0, -0.0001);

    scene.add(ringBlockingShape);

    const transparentMaterial = new THREE.MeshBasicMaterial({
        color: 0x000000,
        transparent: true,
        opacity: 0.5
    });
    const transparentInner = buildPath(new THREE.Shape(), -0.01);
    const transparentGeometry = new THREE.ShapeBufferGeometry(transparentInner);
    innerGlass = new THREE.Mesh(transparentGeometry, transparentMaterial);
    innerGlass.position.set(0, 0, -0.0002);
    scene.add(innerGlass);
}

export function showPointNumbers(scene)
{
    const loader = new THREE.FontLoader();
    loader.load( '/assets/fonts/optimer_regular.typefaceon', function ( font ) {
        const path = buildPath(new THREE.Path(), 1);
        const points = path.getPoints();
        for (let i = 0; i < points.length; i++) {
            const geometry = new THREE.TextBufferGeometry(i.toString(), {
                font: font,
                size: 0.03,
                height: 0,
                curveSegments: 12,
                bevelEnabled: false,
            });

            const material = new THREE.MeshBasicMaterial({
                color: 0xff0000
            });

            const text = new THREE.Mesh(geometry, material);
            text.position.set(points[i].x, points[i].y, 0);
            scene.add(text);
        }
    });
}

export function updateViz(audioData)
{
    if (outerRing === null) {
        return;
    }
    let currentOuterRingPositions = outerRing.geometry.attributes.position.array;
    let currentInnerRingPositions = innerRing.geometry.attributes.position.array;
    let connectorsPositions = connectors.geometry.attributes.position.array;
    let currentBlockerVertices = ringBlockingShape.geometry.vertices;

    let index = 3;
    let connectorIndex = 0;
    let notePointer = 0;

    const selectedNotes = [].slice.call(audioData.getNotes().slice(78, 129));

    const degreePerI = 360/128;
    let currentDegree = 0;
    const min = Math.min(...selectedNotes);
    const max = Math.max(...selectedNotes);

    if (min === max || max === 0) {
        return;
    }

    let innerCounter = 128;
    for (let i = 127; i >= 0; i--) {
        if (notePointer === selectedNotes.length) {
            notePointer = 0;
        }

        let height = ((selectedNotes[notePointer] - min) / (max - min))/5;

        if (i === 42) {
            height = audioData.getMelodyStrength()*3;
        }
        else if (i === 41 || i === 43) {
            height = audioData.getMelodyStrength()*2;
            if (height < 0.2) {
                height = 0.2;
            }
        }
        else if (i === 74) {
            height = audioData.getBeatStrength();
        }
        else if (i === 73 || i === 75) {
            height = audioData.getBeatStrength()/2;
        }
        else if (i === 126) {
            height = audioData.getHihatStrength()/1.5;
            if (height < 0.1) {
                height = 0.1;
            }
        }
        else if (i === 125 || i === 127) {
            height = audioData.getHihatStrength()/2;
            if (height < 0.05) {
                height = 0.05;
            }
        }
        else if (i === 60) {
            height = audioData.getMelodyStrength()*2;
        }
        else if (i === 93) {
            height = audioData.getMelodyStrength()*2;
            if (height < 0.07) {
                height = 0.07;
            }
        }
        else if (i === 92 || i === 94) {
            height = audioData.getMelodyStrength();
            if (height < 0.05) {
                height = 0.05;
            }
        }

        if (height < 0) {
            height = 0;
        }

        let radian = TranceMath.calculateRadian(degreePerI*currentDegree);

        let outerControlPointX = outerRingPositions[index] + (height * Math.cos(radian));
        let innerControlPointX = innerRingPositions[index] - ((height/5) * Math.cos(radian));

        currentOuterRingPositions[index] = outerControlPointX;
        currentInnerRingPositions[index] = innerControlPointX;
        connectorsPositions[connectorIndex] = innerControlPointX;
        currentBlockerVertices[i].x = outerControlPointX;
        currentBlockerVertices[innerCounter].x = innerControlPointX;

        index++;
        connectorIndex++;

        let outerControlPointY = outerRingPositions[index] + (height * Math.sin(radian));
        let innerControlPointY = innerRingPositions[index] - (height/5 * Math.sin(radian));

        currentOuterRingPositions[index] = outerControlPointY;
        currentInnerRingPositions[index] = innerControlPointY;
        connectorsPositions[connectorIndex] = innerControlPointY;
        currentBlockerVertices[i].y = outerControlPointY;
        currentBlockerVertices[innerCounter].y = innerControlPointY;

        index += 2; //skip z
        connectorIndex += 2;

        connectorsPositions[connectorIndex] = outerControlPointX;

        connectorIndex++;

        connectorsPositions[connectorIndex] = outerControlPointY;

        connectorIndex += 2;

        currentDegree++;
        notePointer++;
        innerCounter++;
    }

    currentInnerRingPositions[0] = currentInnerRingPositions[384];
    currentInnerRingPositions[1] = currentInnerRingPositions[385];
    currentInnerRingPositions[2] = currentInnerRingPositions[386];

    currentOuterRingPositions[0] = currentOuterRingPositions[384];
    currentOuterRingPositions[1] = currentOuterRingPositions[385];
    currentOuterRingPositions[2] = currentOuterRingPositions[386];



    outerRing.geometry.attributes.position.needsUpdate = true;
    outerRing.geometry.computeBoundingSphere();

    innerRing.geometry.attributes.position.needsUpdate = true;
    innerRing.geometry.computeBoundingSphere();

    connectors.geometry.attributes.position.needsUpdate = true;
    connectors.geometry.computeBoundingSphere();

    ringBlockingShape.geometry.verticesNeedUpdate = true;

    if (newColor !== null) {
        outerRing.material.color.lerpHSL(newColor, 0.01);
    }

    if (fadeOut === true) {
        outerRing.material.opacity -= 0.01;
        ringBlockingShape.material.opacity -= 0.01;
        innerGlass.material.opacity -= 0.01;

        if (outerRing.material.opacity === 0) {
            outerRing.material.dispose();
            outerRing.geometry.dispose();
            innerRing.geometry.dispose();
            connectors.geometry.dispose();
            ringBlockingShape.geometry.dispose();
            ringBlockingShape.material.dispose();
            innerGlass.geometry.dispose();
            innerGlass.material.dispose();
        }
    }

}

export function fade()
{
    fadeOut = true;
}

export function changeColor(color)
{
    newColor = color;
}

function buildConnectors()
{
    const path = new THREE.Path();
    for (let i = 0; i < 128*3; i++) {
        const startX = innerRingPositions[i];
        const endX = outerRingPositions[i];

        i++;

        const startY = innerRingPositions[i];
        const endY = outerRingPositions[i];
        path.moveTo(startX, startY);
        path.lineTo(endX, endY);

        i++;
    }

    return path;
}

function buildPath(path, heightAddition)
{
    const degreePerI = 360/128;
    const height = heightAddition * 0.1;

    let startX = 0;
    let startY = 0;
    let currentDegree = 0;
    for (let i = 0; i < 128; i++) {
        let radian = TranceMath.calculateRadian(degreePerI*currentDegree);
        let x = Math.cos(radian);
        let y = Math.sin(radian);
        let controlPointX = x + (height * Math.cos(radian));
        let controlPointY = y + (height * Math.sin(radian));
        if (i === 0) {
            path.moveTo(controlPointX, controlPointY);
            startX = controlPointX;
            startY = controlPointY;
        } else {
            path.lineTo(controlPointX, controlPointY);
        }

        currentDegree++;
    }

    path.lineTo(startX, startY);

    return path;
}
