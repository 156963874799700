import * as THREE from "three";
import {UnrealBloomPass} from "three/examples/jsm/postprocessing/UnrealBloomPass";
import {EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import {ShaderPass} from "three/examples/jsm/postprocessing/ShaderPass";
import BloomVert from "../shaders/vert/bloom.vert";
import BloomFrag from "../shaders/frag/bloom.frag";

const materials = {};
let bloomLayer = new THREE.Layers();
let darkMaterial;

export class Bloom {
    constructor(strength, radius, threshold, renderer, renderPass) {
        darkMaterial = new THREE.MeshBasicMaterial({ color: "black" });
        bloomLayer.set(1);

        //resolution, strength, radius, threshold
        this.bloomPass = new UnrealBloomPass(
            new THREE.Vector2(window.innerWidth, window.innerHeight),
            strength,
            radius,
            threshold
        );

        this.bloomComposer = new EffectComposer(renderer);
        this.bloomComposer.renderToScreen = false
        this.bloomComposer.addPass(renderPass);
        this.bloomComposer.addPass(this.bloomPass);

        // Bloom
        this.bloomShaderPass = new ShaderPass(
            new THREE.ShaderMaterial( {
                uniforms: {
                    baseTexture: { value: null },
                    bloomTexture: { value: this.bloomComposer.renderTarget2.texture }
                },
                vertexShader: BloomVert,
                fragmentShader: BloomFrag,
                defines: {}
            } ), "baseTexture"
        );
        this.bloomShaderPass.needsSwap = true;
    }

    updateStrength(strength) {
        this.bloomPass.strength = strength;
    }

    getBloomShaderPass()
    {
        return this.bloomShaderPass;
    }

    renderBloom(scene)
    {
        scene.traverse(function(obj) {
            if (bloomLayer.test(obj.layers) === false) {
                materials[obj.uuid] = obj.material;
                obj.material = darkMaterial;
            }
        });
        this.bloomComposer.render();
        scene.traverse(function(obj) {
            if (materials[obj.uuid]) {
                obj.material = materials[obj.uuid];
                delete materials[obj.uuid];
            }
        });
    }
}
