export class AudioData {
    constructor(analyser, source) {
        this.clubber = new Clubber({
            size: 2048,
            mute: false,
            analyser: analyser,
        });

        this.clubber.listen(source);

        /**
         0 - Strongest power note index
         1 - Weakest powered note index
         2 - Power weighted note average
         3 - Power of the strongest note
         4 - Average energy of active notes
         5 - Power weighted average midi index
         6 - Power weighted average octave index
         7 - Ratio of spectrum window area covered
         8 - Adaptive low threshold relative to absolute limits
         9 - Adaptive high threshold relative to absolute limits
         */

        this.bands = {
            beat: this.clubber.band({
                template: '3',
                from: 33,
                to: 39,
                low: 0,
                high: 128,
                smooth: [0.25, 0.25, 0.25, 0.25],
                adapt: [1, 0.25, 1, 0.25],
                snap: 0.33
            }),
            melody: this.clubber.band({
                template: '4576',
                from: 64,
                to: 96,
                low: 0,
                high: 256,
                smooth: [0.25, 0.25, 0.25, 0.25],
                adapt: [1, 0.25, 1, 0.25],
                snap: -0.33
            }),
            hihat: this.clubber.band({
                template: '1',
                from: 125,
                to: 128,
                low: 32,
                high: 64,
                smooth: [1, 1, 1, 1],
                adapt: [1, 1, 1, 1],
                snap: -0.33
            })
        }

        this.audioData = {
            beat: new Float32Array(1),
            melody: new Float32Array(6),
            hihat: new Float32Array(1),
        };

        this.clubber.update();
        this.inBeat = false;
        this.beat = false;
    }

    update()
    {
        this.clubber.update();

        this.bands.beat(this.audioData.beat);
        this.bands.melody(this.audioData.melody);
        this.bands.hihat(this.audioData.hihat);

        if (this.audioData.beat[0] >= 0.85 && this.inBeat === false) {
            this.beat = true;
            this.inBeat = true;
        } else if (this.audioData.beat[0] >= 0.9 && this.inBeat === true) {
            this.beat = false;
        }

        if (this.audioData.beat[0] < 0.9) {
            this.beat = false;
            this.inBeat = false;
        }
    }

    getNotes()
    {
        return this.clubber.notes;
    }

    getBeatStrength()
    {
        return this.audioData.beat[0];
    }

    getHihatStrength()
    {
        return this.audioData.hihat[0];
    }

    getMelodyStrength()
    {
        return this.audioData.melody[0];
    }

    getMelodyMidiIndex()
    {
        return this.audioData.melody[1];
    }

    getMelodyWindowRatio()
    {
        return this.audioData.melody[2];
    }

    getMelodyOctaveIndex()
    {
        return this.audioData.melody[3];
    }

    isBeat()
    {
        return this.beat;
    }
}
