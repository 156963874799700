import * as THREE from 'three';
import * as TranceMath from "/assets/js/math";

let organicLines = [];
const lines = 128;
const height = 5.2;
const startHeight = 0.5;
const heightStep = 0.1;
let tremble = false;
let animate = false;

export function destroy(scene)
{
    for (let i = 0; i < lines.length; i++) {
        organicLines[i].geometry.dispose();
        organicLines[i].material.dispose();
        scene.remove(organicLines[i]);
    }
    organicLines = [];
}

export function createLines(scene)
{
    const degreesPerLine = 360/lines;
    const color = new THREE.Color(0xffffff);

    for (let i = 0; i < lines; i++) {
        const path = new THREE.Path();
        let radian = TranceMath.calculateRadian(degreesPerLine*i);
        let x = Math.cos(radian) + (startHeight * Math.cos(radian));
        let y = Math.sin(radian) + (startHeight * Math.sin(radian));
        path.moveTo(x, y);
        let currHeight = startHeight;
        while (currHeight < height) {
            const random = Math.random();
            if (random >= 0.2 && random <= 0.5) {
                radian = TranceMath.calculateRadian(degreesPerLine*i+Math.random());
            } else if (random >= 0.7) {
                radian = TranceMath.calculateRadian(degreesPerLine*i-Math.random());
            }

            let controlPointX = x + (currHeight * Math.cos(radian));
            let controlPointY = y + (currHeight * Math.sin(radian));
            currHeight += heightStep;
            let endX = x + (currHeight * Math.cos(radian));
            let endY = y + (currHeight * Math.sin(radian));
            currHeight += heightStep;
            path.quadraticCurveTo(controlPointX, controlPointY, endX, endY);
        }

        if (i < lines/2) {
            color.setHSL( 0.5 + 0.1 * ( i / 32 ), 0.7, 0.5 );
        } else {
            color.setHSL( 0.5 + 0.1 * ( (lines-i) / 32 ), 0.7, 0.5 );
        }

        const material = new THREE.LineBasicMaterial({
            color: color.getHex(),
            transparent: true,
            opacity: 0.75,
            linewidth: 3
        });
        const geometry = new THREE.BufferGeometry()
            .setAttribute( 'position', new THREE.BufferAttribute(new Float32Array(Math.round(((height - startHeight)/heightStep)) * 3), 3))
            .setFromPoints(path.getPoints());

        const organicLine = new THREE.Line(geometry, material);
        organicLine.position.set(0, 0, -1);
        organicLine.layers.enable(1);
        scene.add(organicLine);
        geometry.setDrawRange(0, 0);
        organicLines.push(organicLine);
    }
}

export function animateLines(audioData)
{
    const minNote = Math.min(...audioData.getNotes());
    const maxNote = Math.max(...audioData.getNotes());

    if (tremble === true) {
        const color = new THREE.Color(0xffffff);
        for (let i = 0; i < organicLines.length; i++) {
            const percentageDecimal = TranceMath.normalize(audioData.getNotes()[i], minNote, maxNote);
            color.setHSL(percentageDecimal, 1, audioData.getNotes()[i]/300);
            organicLines[i].material.color.lerpHSL(color, 0.25);
            if (Math.random() > 0.5 || organicLines[i].geometry.drawRange.count < 5) {
                organicLines[i].geometry.setDrawRange(0, organicLines[i].geometry.drawRange.count+1);
            } else {
                organicLines[i].geometry.setDrawRange(0, organicLines[i].geometry.drawRange.count-1);
            }
        }
    } else if (animate === true) {
        const pointCount = organicLines[0].geometry.attributes.position.array.length/3;

        const color = new THREE.Color(0xffffff);
        for (let i = 0; i < organicLines.length; i++) {
            const percentageDecimal = TranceMath.normalize(audioData.getNotes()[i], minNote, maxNote);
            const points = Math.floor(percentageDecimal * pointCount);
            organicLines[i].geometry.setDrawRange(0, points);
            color.setHSL(percentageDecimal, 1, audioData.getNotes()[i]/300);
            organicLines[i].material.color.lerpHSL(color, 0.25);
        }
    } else {
        for (let i = 0; i < organicLines.length; i++) {
            if (organicLines[i].geometry.drawRange.count > 0) {
                organicLines[i].geometry.setDrawRange(0, organicLines[i].geometry.drawRange.count-1);
            }
        }
    }
}

export function setTremble(boolean)
{
    tremble = boolean;
}

export function setAnimate(boolean)
{
    animate = boolean;
}
