import * as THREE from 'three';

export const DESKTOP_CAMERA = "desktop";
export const XR_CAMERA = "xr";

export class CameraFactory {
    constructor() {}

    createCamera = function (type, fov, aspect, nearPlane, farPlane) {
        let camera;

        if (type === DESKTOP_CAMERA) {
            camera = new TranceportDesktopCamera(fov, aspect, nearPlane, farPlane);
        } else if (type === XR_CAMERA) {
            camera = new TranceportXRCamera(fov, aspect, nearPlane, farPlane);
        }

        camera.type = type;
        return camera;
    }
}

export class TranceportCamera {
    constructor(fov, aspect, nearPlane, farPlane) {
        this.fov = fov;
        this.aspect = aspect;
        this.nearPlane = nearPlane;
        this.farPlane = farPlane;
    }
}

export class TranceportDesktopCamera extends TranceportCamera {
    constructor(fov, aspect, nearPlane, farPlane) {
        super(fov, aspect, nearPlane, farPlane);
        this.init();
    }

    init() {
        this.camera = new THREE.PerspectiveCamera(this.fov, this.aspect, this.nearPlane, this.farPlane);
        this.camera.updateMatrix();
        this.camera.updateMatrixWorld();

        this.frustum = new THREE.Frustum();
        this.frustum.setFromProjectionMatrix(
            new THREE.Matrix4().multiplyMatrices(
                this.camera.projectionMatrix,
                this.camera.matrixWorldInverse
            )
        );

        this.renderer = new THREE.WebGLRenderer({
            alpha: true,
            stencil: false,
            powerPreference: "high-performance",
            outputEncoding: THREE.sRGBEncoding,
            localClippingEnabled: true,
            shadowMap: {
                enabled: true,
                type: THREE.PCFSoftShadowMap
            },
            toneMapping: THREE.ReinhardToneMapping,
            toneMappingExposure: Math.pow(1, 4.0),
        });

        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.renderer.setPixelRatio(2); //This increases performance significantly on high DPI devices
        const size = this.renderer.getDrawingBufferSize(new THREE.Vector2());
        this.renderTarget = new THREE.WebGLMultisampleRenderTarget(size.width, size.height, {
            format: THREE.RGBFormat,
            stencilBuffer: false,
            depthBuffer: true,
        });
        this.renderer.setRenderTarget(this.renderTarget);
    }
}

export class TranceportXRCamera extends TranceportCamera {
    constructor(fov, aspect, nearPlane, farPlane) {
        super(fov, aspect, nearPlane, farPlane);
        this.init();
    }

    init() {
        this.camera = new THREE.PerspectiveCamera(this.fov, this.aspect, this.nearPlane, this.farPlane);

        this.renderer = new THREE.WebGLRenderer({
            powerPreference: "low-power",
            antialias: true
        });

        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.renderer.outputEncoding = THREE.sRGBEncoding;
        this.renderer.xr.enabled = true;

        // document.body.appendChild(VRButton.createButton(this.renderer)); // This is messy, I am not sure how to refactor
        this.renderer.xr.enabled = true;

        // TODO: VR
        //this.renderer.setAnimationLoop(function () {
        //    this.renderer.render(this.scene, this.camera);
        //});
    }
}
