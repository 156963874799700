import * as THREE from 'three';
import * as TranceMath from '/assets/js/math';
import {CatmullRomCurve3} from "three";
import PointVert from '/assets/shaders/vert/point.vert';
import PointFrag from '/assets/shaders/frag/point.frag';

let noteLine;
let ico;
let ico2;
let light;
let curves = new THREE.Group();
let curvesCopies = new THREE.Group();
let fallingSparks = false;
let grow = false;
let react = false;
let sparkPoints;
let heart;
let heartGroup = [];

export function create(scene) {
    const material = new THREE.LineBasicMaterial({
        linewidth: 5,
        color: 0x0be881,
        vertexColors: false
    });

    const noteRingGeometry = new THREE.BufferGeometry()
        .setAttribute( 'position', new THREE.BufferAttribute(new Float32Array(128 * 3), 3))
            .setFromPoints(drawCircle(0.2).getPoints())
    noteLine = new THREE.Line(noteRingGeometry, material);
    noteLine.layers.enable(1);
    scene.add(noteLine);

    const icoGeometry = new THREE.DodecahedronBufferGeometry( 0.4);
    const icoMaterial = new THREE.MeshLambertMaterial( { color: 0x575fcf } );
    ico = new THREE.Mesh(icoGeometry, icoMaterial);
    ico.position.z = 0.5;

    const icoGeometry2 = new THREE.DodecahedronBufferGeometry( 0.55);
    const icoMaterial2 = new THREE.MeshBasicMaterial( { color: 0xd2dae2 } );
    ico2 = new THREE.Mesh(icoGeometry2, icoMaterial2);
    ico2.position.z = 0;
    ico2.layers.enable(1);
    scene.add(ico2);
    scene.add(ico);

    light = new THREE.PointLight(0x4bcffa, 5, 2);
    light.position.set(0, 0, 1);
    scene.add(light);

    let startY = 3.6;
    const lineMaterial = new THREE.LineBasicMaterial({
        color : 0x1e272e,
        linewidth: 6,
        transparent: true,
        opacity: 0.5,
    });
    for (let i = 0; i < 16; i++) {
        const curve = new THREE.CatmullRomCurve3([
            new THREE.Vector3(-15, startY, -2),
            new THREE.Vector3(15, startY, -2)
        ]);

        const geometry = new THREE.BufferGeometry().setFromPoints(curve.getPoints(16));

        const curveObject = new THREE.Line(geometry, lineMaterial.clone());
        curves.add(curveObject);
        curveObject.layers.enable(1);

        lineMaterial.color.offsetHSL(0.05, 0.05, 0);
        startY -= 0.5;
    }

    scene.add(curves);

    for (let i = 0; i < 32; i++) {
        const clone = curves.clone();
        clone.position.z = -0.2*(i+1);
        curvesCopies.add(clone);
    }

    scene.add(curvesCopies);

    sparkPoints = createSparks();
    scene.add(sparkPoints);

    heart = createHearts();
}

export function animate(audioData, scene) {
    if (noteLine) {
        const selectedNotes = [].slice.call(audioData.getNotes().slice(45, 100));
        let notePointer = 0;
        let positions = noteLine.geometry.attributes.position.array;
        for (let i = 0; i < 129; i++) {
            if (notePointer === selectedNotes.length) {
                notePointer = 0;
            }
            positions[(i*3)+2] = selectedNotes[notePointer]/500;
            notePointer++;
        }
        positions[0] = positions[384];
        positions[1] = positions[385];
        positions[2] = positions[386];
        noteLine.geometry.attributes.position.needsUpdate = true;

        noteLine.position.z = audioData.getBeatStrength()/3;
        const melodyColor = new THREE.Color();
        melodyColor.setHSL(audioData.getMelodyMidiIndex(), 1, 0.5);
        noteLine.material.color.lerpHSL(melodyColor, 0.2);

        ico.rotation.x += 0.01;
        ico.rotation.y += 0.01;
        ico.rotation.z -= 0.01;
        ico2.rotation.x += 0.01;
        ico2.rotation.y += 0.01;
        ico2.rotation.z -= 0.01;

        const spectrumColor = new THREE.Color();
        spectrumColor.setHSL(audioData.getMelodyWindowRatio(), 1, 0.5);
        light.color.lerpHSL(spectrumColor, 0.3);
        light.intensity = audioData.getBeatStrength()*5;
        ico.scale.set(audioData.getBeatStrength(), audioData.getBeatStrength(), audioData.getBeatStrength());
        ico2.scale.set(audioData.getBeatStrength(), audioData.getBeatStrength(), audioData.getBeatStrength());

        let startY = 3;
        const notes = audioData.getNotes();
        notePointer = 0;
        for (let i = 0; i < curves.children.length; i++) {
            const points = [];
            let startX = -15;
            for (let x = i * 16; x < (i*16)+16; x++) {
                if (notePointer === 128) {
                    notePointer = 0;
                }
                points.push(new THREE.Vector3(startX, startY+(notes[notePointer]/100), -2));
                startX += 2;
                notePointer++;
            }

            const curve = new CatmullRomCurve3(points);
            curves.children[i].geometry.attributes.position.copyVector3sArray(curve.getPoints(16));
            curves.children[i].geometry.attributes.position.needsUpdate = true;
            startY -= 0.5;
        }

        if (fallingSparks === true) {
            const positions = sparkPoints.geometry.attributes.position.array;
            for (let i = 0; i < 128; i++) {
                if (positions[(i*3)+1] > -3) {
                    positions[(i*3)+1] -= THREE.MathUtils.randFloat(0.0001, 0.01);
                }
            }
            sparkPoints.geometry.attributes.position.needsUpdate = true;
        }
    }

    if (react === true) {
        const notes = audioData.getNotes();
        const positions = sparkPoints.geometry.attributes.position.array;
        for (let i = 0; i < 128; i++) {
            positions[(i*3)+1] = THREE.MathUtils.lerp(positions[(i*3)+1], -3+notes[i]/100, 0.1);
        }
    }

    if (grow === true) {
        if (audioData.isBeat()) {
            const positions = sparkPoints.geometry.attributes.position.array;
            for (let i = 0; i < 4; i++) {
                const vector = new THREE.Vector3();
                const rand = THREE.MathUtils.randInt(0, 127);
                vector.x = positions[rand*3];
                vector.y = positions[(rand*3)+1];
                vector.z = positions[(rand*3)+2];
                const myHeart = heart.clone();
                myHeart.rotation.set(
                    THREE.MathUtils.randFloat(0.1, 1),
                    THREE.MathUtils.randFloat(0.1, 1),
                    THREE.MathUtils.randFloat(0.1, 1)
                );
                myHeart.position.set(vector.x, vector.y, vector.z);
                scene.add(myHeart);
                heartGroup.push(myHeart);
            }
        }
    }

    for (let i = 0; i < heartGroup.length; i++) {
        heartGroup[i].position.y += 0.005 - THREE.MathUtils.randFloat(0, 0.002);
        if (heartGroup[i].position.y > 15) {
            scene.remove(heartGroup[i]);
        }
        heartGroup[i].rotation.x += 0.01;
        heartGroup[i].rotation.y += 0.01;
        heartGroup[i].rotation.z -= 0.01;
    }
}

export function destroy(scene) {

}

function drawCircle(heightAddition) {
    const path = new THREE.Path();
    const degreePerI = 360/128;
    const height = heightAddition * 0.1;

    let startX = 0;
    let startY = 0;
    let currentDegree = 0;
    for (let i = 0; i < 128; i++) {
        let radian = TranceMath.calculateRadian(degreePerI*currentDegree);
        let x = Math.cos(radian);
        let y = Math.sin(radian);
        let controlPointX = x + (height * Math.cos(radian));
        let controlPointY = y + (height * Math.sin(radian));
        if (i === 0) {
            path.moveTo(controlPointX, controlPointY);
            startX = controlPointX;
            startY = controlPointY;
        } else {
            path.lineTo(controlPointX, controlPointY);
        }

        currentDegree++;
    }

    path.lineTo(startX, startY);

    return path;
}

function createHearts()
{
    const x = 0, y = 0;

    const heartShape = new THREE.Shape();

    heartShape.moveTo( x + 5, y + 5 );
    heartShape.bezierCurveTo( x + 5, y + 5, x + 4, y, x, y );
    heartShape.bezierCurveTo( x - 6, y, x - 6, y + 7,x - 6, y + 7 );
    heartShape.bezierCurveTo( x - 6, y + 11, x - 3, y + 15.4, x + 5, y + 19 );
    heartShape.bezierCurveTo( x + 12, y + 15.4, x + 16, y + 11, x + 16, y + 7 );
    heartShape.bezierCurveTo( x + 16, y + 7, x + 16, y, x + 10, y );
    heartShape.bezierCurveTo( x + 7, y, x + 5, y + 5, x + 5, y + 5 );

    const geometry = new THREE.ShapeBufferGeometry(heartShape);
    const material = new THREE.MeshBasicMaterial({
        color: 0xf53b57,
        side: THREE.DoubleSide
    });
    const mesh = new THREE.Mesh(geometry, material) ;
    mesh.scale.set(0.002, 0.002, 0.002);
    mesh.position.set(0, 0, 1);
    const edges = new THREE.EdgesGeometry(geometry);
    const line = new THREE.LineSegments(
        edges,
        new THREE.LineBasicMaterial({
            color: 0xef5777,
            linewidth: 1,
        })
    );
    line.layers.enable(1);
    mesh.add(line);
    return mesh;
}

function createSparks()
{
    const sparkMaterial = new THREE.ShaderMaterial( {
        uniforms: {
            color: { value: new THREE.Color( 0xffffff ) },
            pointTexture: { value: new THREE.TextureLoader().load("/assets/images/spark1.png") }
        },
        vertexShader: PointVert,
        fragmentShader: PointFrag,

        blending: THREE.AdditiveBlending,
        depthTest: true,
        transparent: true,
        alphaTest: 0.5,
    });

    const positions = new Float32Array(128 * 3);
    const sizes = new Float32Array(128);
    const colors = new Float32Array(128 * 3);
    const sparkGeometry = new THREE.BufferGeometry();
    sparkGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    sparkGeometry.setAttribute('customColor', new THREE.BufferAttribute( colors, 3));
    sparkGeometry.setAttribute('size', new THREE.BufferAttribute(sizes, 1).setUsage(THREE.DynamicDrawUsage));

    const sparkPoints = new THREE.Points(sparkGeometry, sparkMaterial);
    sparkPoints.layers.enable(1);
    const sparkColor = new THREE.Color(0xffffff);

    for (let i = 0; i < 128; i++) {
        positions[i*3] = THREE.MathUtils.randFloat(-10, 10);
        positions[(i*3)+1] = 3;
        positions[(i*3)+2] = THREE.MathUtils.randFloat(-1, -5);
        sizes[i] = THREE.MathUtils.randFloat(0.1, 0.3);
        sparkColor.toArray(colors, i * 3);
    }

    sparkPoints.geometry.attributes.position.needsUpdate = true;
    sparkPoints.geometry.attributes.customColor.needsUpdate = true;
    sparkPoints.geometry.attributes.size.needsUpdate = true;

    return sparkPoints;
}

export function sparksFall() {
    fallingSparks = true;
}

export function sparksGrow() {
    grow = true;
}

export function sparksReact() {
    react = true;
}
