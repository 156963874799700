import * as THREE from 'three';
import {LegacyGLTFLoader} from '/assets/js/thirdparty/LegacyGLTFLoader';

function loadGLTF(assetURL) {
	return new Promise(function(resolve, reject) {

		let loader = new LegacyGLTFLoader();
		loader.load( assetURL, function( response ) {
			resolve(response);
		});

		// Handle network errors
		loader.onerror = function() {
			reject(Error("Network Error"));
		};
	});
}

function download(url) {
	return new Promise(function(resolve, reject) {

		let request = new XMLHttpRequest();
		request.open( 'GET', url, true );

		request.onload = function() {

			if (request.status == 200) {
				// Resolve the promise with the response text
				resolve(request.response);
			}
			else {
				// Otherwise reject with the status text
				// which will hopefully be a meaningful error
				reject(Error(request.statusText));
			}
	  	};

		// Handle network errors
		request.onerror = function() {
			reject(Error("Network Error"));
		};

		// Make the request
		request.send(null);
	});
}

function loadAsset(scene, id) {
	const key = process.env.POLY_API_KEY;
	let URL = `https://poly.googleapis.com/v1/assets/${id}/?key=${key}`;
	const group = new THREE.Group();

	download( URL )
	.then(function( jsonData ) {
		return JSON.parse( jsonData );
	})
	.then(function( asset ) {
		let format = asset.formats.find( format => { return format.formatType === 'GLTF'; } );
		if ( format !== undefined ) {
			const url = format.root.url;
			return loadGLTF(url);
		} else {
			return Error("Asset Error");
		}
	  })
	.then( function( assetScene ) {
		if (assetScene) {
			for (let i = 0; i < assetScene.scene.children.length; i++) {
				group.add(assetScene.scene.children[i]);
			}
			scene.add(group);
		} else {
			return Error("Error loading asset scene");
		}
	});
}

export function addToScene(scene, polyID)
{
	return loadAsset(scene, polyID);
}

export function animatePoly(notes, beat, melody)
{
	// animate the tiltbrush shaders
}
