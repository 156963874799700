import * as THREE from 'three';
import { TessellateModifier } from '/assets/js/tessellate';
import { LightningStrike } from 'three/examples/jsm/geometries/LightningStrike';
import TessVert from '/assets/shaders/vert/tess.vert';
import TessFrag from '/assets/shaders/frag/tess.frag';
import LavaVert from '/assets/shaders/vert/lava.vert';
import LavaFrag from '/assets/shaders/frag/lava.frag';

const meshes = [];
const clock = new THREE.Clock();
const orbUniforms = [];
let expansion = 0.25;
let desiredExpansion = 0.25;
let fog = true;
let raised = false;
let shooting = false;

let lasers = [];
const laserMeshes = [];
let d = 1.75;
let flyAway = false;

export function destroy(scene)
{
    for (let i = 0; i < meshes.length; i++) {
        for (let x = 0; x < meshes[i].children.length; x++) {
            meshes[i].children[x].geometry.dispose();
            meshes[i].children[x].material.dispose();
            scene.remove(meshes[i].children[x]);
        }
        meshes[i].geometry.dispose();
        meshes[i].material.dispose();
        scene.remove(meshes[i]);
    }
}

export function create(scene)
{
    for (let i = 0; i < 2; i++) {
        let startGeometry = new THREE.IcosahedronGeometry(0.1, 0);
        let tessellateModifier = new TessellateModifier(1);
        tessellateModifier.modify(startGeometry);
        let geometry = new THREE.BufferGeometry().fromGeometry(startGeometry);

        const outlineGeometry = new THREE.BufferGeometry().fromGeometry(startGeometry);
        const outlineMaterial = new THREE.LineBasicMaterial({
            color: 0xd2dae2,
            linewidth: 3,
            transparent: true,
            opacity: 0.7
        });
        const outline = new THREE.Line(outlineGeometry, outlineMaterial);

        let numFaces = geometry.attributes.position.count / 3;
        let colors = new Float32Array( (numFaces * 3) * 3 );
        let displacement = new Float32Array( (numFaces * 3) * 3 );
        let color = new THREE.Color();

        for (let f = 0; f < numFaces; f ++) {
            let index = 9 * f;
            let h = 0.55;
            let s = 1;
            let l = 0.7;
            color.setHSL(h, s, l);
            let d = 0.1;
            for (let i = 0; i < 3; i ++) {
                colors[index + ( 3 * i )] = color.r;
                colors[index + ( 3 * i ) + 1] = color.g;
                colors[index + ( 3 * i ) + 2] = color.b;

                displacement[index + ( 3 * i )] = d;
                displacement[index + ( 3 * i ) + 1] = d;
                displacement[index + ( 3 * i ) + 2] = d;
            }
        }

        const shaderMaterial = new THREE.ShaderMaterial( {
            uniforms: {
                amplitude: { value: 0.0 }
            },
            vertexShader: TessVert,
            fragmentShader: TessFrag
        });

        geometry.setAttribute( 'customColor', new THREE.BufferAttribute( colors, 3 ) );
        geometry.setAttribute( 'displacement', new THREE.BufferAttribute( displacement, 3 ) );

        const mesh = new THREE.Mesh(geometry, shaderMaterial);
        mesh.layers.enable(1);
        const orb = createOrb();
        mesh.add(orb);
        mesh.add(outline);
        meshes.push(mesh);
        scene.add(mesh);
    }

    meshes[0].position.set(2, 1, 2.5);
    meshes[1].position.set(-2, -1, 2.5);
}

export function animate(audioData)
{
    d = raised === false ? THREE.MathUtils.lerp(d, 1.75, 0.01) : THREE.MathUtils.lerp(d, 1.25, 0.01);
    let time;

    if (expansion < desiredExpansion) {
        expansion = desiredExpansion;
    } else if (expansion > desiredExpansion) {
        expansion -= 0.01;
    }
    for (let i = 0; i < meshes.length; i++) {
        const colors = meshes[i].geometry.attributes.customColor.array;
        const color = new THREE.Color(0x000000);
        if (i % 2 === 0) {
            time = Date.now() * 0.00025 + 3;

            let notePointer = 33;
            for (let x = 0; x < colors.length; x++) {
                if (notePointer > 60) {
                    notePointer = 0;
                }

                color.setHSL(0.3 + (audioData.getNotes()[notePointer]/500), 1, (THREE.MathUtils.clamp(audioData.getBeatStrength(), 0.3, 0.7)));
                color.toArray(colors, x * 3);

                notePointer++;
            }
            meshes[i].geometry.attributes.customColor.needsUpdate = true;

            meshes[i].rotation.x += THREE.MathUtils.clamp(audioData.getHihatStrength() / 40, 0.01, 100);
            meshes[i].rotation.y += THREE.MathUtils.clamp(audioData.getBeatStrength() / 40, 0.01, 100);
            meshes[i].rotation.z += THREE.MathUtils.clamp(audioData.getBeatStrength() / 40, 0.01, 100);
            meshes[i].material.uniforms.amplitude.value = (audioData.getBeatStrength() * 3) * expansion;
        } else {
            time = Date.now() * 0.00025;

            let notePointer = 60;
            for (let x = 0; x < colors.length; x++) {
                if (notePointer > 100) {
                    notePointer = 0;
                }

                color.setHSL(0.75 + (audioData.getNotes()[notePointer]/500), 1, (THREE.MathUtils.clamp(audioData.getBeatStrength(), 0.3, 0.7)));
                color.toArray(colors, x * 3);

                notePointer++;
            }
            meshes[i].geometry.attributes.customColor.needsUpdate = true;

            meshes[i].rotation.x -= THREE.MathUtils.clamp(audioData.getMelodyStrength() / 10, 0.01, 1);
            meshes[i].rotation.y -= audioData.getMelodyOctaveIndex() / 10;
            meshes[i].rotation.z -= audioData.getMelodyWindowRatio() / 10;
            meshes[i].material.uniforms.amplitude.value = (audioData.getBeatStrength() * 3) * expansion;
        }

        meshes[i].position.x = -Math.sin(time) * d;
        meshes[i].position.y = -Math.cos(time) * d;
        if (flyAway === false) {
            if (raised === true) {
                meshes[i].position.z = THREE.MathUtils.lerp(meshes[i].position.z, 0.5, 0.01);
            } else {
                meshes[i].position.z = THREE.MathUtils.lerp(meshes[i].position.z, 0.2, 0.01);
            }
        } else {
            meshes[i].position.z = THREE.MathUtils.lerp(meshes[i].position.z, 2.5, 0.01);
        }
    }

    for (let i = 0; i < orbUniforms.length; i++) {
        const delta = 5 * clock.getDelta();
        orbUniforms[i]["time"].value += 0.2 * delta;
        if (fog === true) {
            orbUniforms[i].fogDensity = {
                value: 0.9
            };
        } else {
            orbUniforms[i].fogDensity = {
                value: 0.3
            };
        }
    }
}

export function setExpansion(amount)
{
    desiredExpansion = THREE.MathUtils.clamp(amount, 0.25, 1);
}

export function setFog(boolean)
{
    fog = boolean;
}

export function stopShoot(scene)
{
    for (let i = 0; i < laserMeshes.length; i++) {
        scene.remove(laserMeshes[i]);
    }
    lasers = [];
}

export function shoot(kdtree, scene, triangles)
{
    createLasers(scene);
    if (lasers.length > 0) {
        let count = 0;
        for (let i = 0; i < meshes.length; i++) {
            const objectPositionsInRange = kdtree.nearest([meshes[i].position.x, meshes[i].position.y, meshes[i].position.z], 2, 0.5);
            for (let b = 0; b < objectPositionsInRange.length; b++) {
                lasers[count].rayParameters.sourceOffset.copy(meshes[i].position);
                lasers[count].rayParameters.destOffset = new THREE.Vector3(objectPositionsInRange[b][0].obj[0], objectPositionsInRange[b][0].obj[1], objectPositionsInRange[b][0].obj[2]);
                lasers[count].update(Date.now());
                count++;
                triangles[objectPositionsInRange[b][0].obj[3]].material.color = new THREE.Color(0xffffff);
                triangles[objectPositionsInRange[b][0].obj[3]].children[0].material.color = new THREE.Color(0xffffff);
            }
        }
    }
}

export function getSpinners()
{
    return meshes;
}

function createLasers(scene)
{
    if (laserMeshes.length === 0) {
        for (let i = 0; i < meshes.length*2; i++) {
            const lightningMaterial = new THREE.MeshBasicMaterial({
                color: 0x4bcffa,
            });
            const laserParams = {
                sourceOffset: new THREE.Vector3(),
                destOffset: new THREE.Vector3(),
                radius0: 0.0025,
                radius1: 0.0025,
                minRadius: 0.0025,
                maxIterations: 7,
                isEternal: true,

                timeScale: 0.01,

                propagationTimeFactor: 0.05,
                vanishingTimeFactor: 0.95,
                subrayPeriod: 0,
                subrayDutyCycle: 0,
                maxSubrayRecursion: 0,
                ramification: 0,
                recursionProbability: 0,

                roughness: 1,
                straightness: 0.7
            };
            const lightningStrike = new LightningStrike(laserParams);
            const lightningStrikeMesh = new THREE.Mesh(lightningStrike, lightningMaterial);
            laserMeshes.push(lightningStrikeMesh);
            lightningStrikeMesh.layers.enable(1);
            lasers.push(lightningStrike);
            scene.add(lightningStrikeMesh);
        }
    }
}

function createOrb()
{
    const textureLoader = new THREE.TextureLoader();
    const orbUniform = {
        "fogDensity": { value: 0.9 },
        "fogColor": { value: new THREE.Vector3( 0, 0, 0 ) },
        "time": { value: 1.0 },
        "uvScale": { value: new THREE.Vector2( 3.0, 1.0 ) },
        "texture1": { value: textureLoader.load( '/assets/images/cloud.png' ) },
        "texture2": { value: textureLoader.load( '/assets/images/lavatile.jpg' ) }
    };

    orbUniform[ "texture1" ].value.wrapS = orbUniform[ "texture1" ].value.wrapT = THREE.RepeatWrapping;
    orbUniform[ "texture2" ].value.wrapS = orbUniform[ "texture2" ].value.wrapT = THREE.RepeatWrapping;

    const material = new THREE.ShaderMaterial( {
        uniforms: orbUniform,
        vertexShader: LavaVert,
        fragmentShader: LavaFrag,
    });

    orbUniforms.push(orbUniform);

    const orb = new THREE.Mesh( new THREE.SphereBufferGeometry(0.04, 16, 16), material );
    orb.layers.enable(1);
    return orb;
}

export function setRaised(boolean)
{
    raised = boolean;
}

export function setShoot(boolean)
{
    shooting = boolean;
}

export function flyOff()
{
    flyAway = true;
}
