import * as THREE from 'three';

let plane = null;
let blocked = false;
let blockedSlow = false;

export function create(scene)
{
    const geometry = new THREE.PlaneBufferGeometry(50, 50);
    const material = new THREE.MeshBasicMaterial({
        color: 0x000000,
        transparent: true,
        opacity: 1,
        side: THREE.DoubleSide,
        visible: true
    });
    plane = new THREE.Mesh(geometry, material);
    plane.position.set(0, 0, -50);
    scene.add(plane);
}

export function destroy(scene)
{
    plane.geometry.dispose();
    plane.material.dispose();
    scene.remove(plane);
}

export function animate()
{
    if (plane !== null) {
        if (blocked === true && plane.material.opacity < 1) {
            plane.position.z = 1.5;
            plane.material.visible = true;
            plane.material.opacity += 0.025;
        } else if (blocked === false && plane.material.opacity > 0) {
            plane.material.opacity -= 0.025;
            plane.position.z = 1.5;
        }

        if (plane.material.opacity <= 0 && blocked === false) {
            plane.material.visible = false;
            plane.position.z = -50;
        }

        if (blockedSlow === true && plane.material.opacity < 1) {
            plane.position.z = 1.5;
            plane.material.visible = true;
            plane.material.opacity += 0.01;
        }
    }
}

export function setBlocked(boolean)
{
    blocked = boolean;
}

export function setBlockedSlow(boolean)
{
    blockedSlow = true;
}

