import {Chapter} from "/assets/js/chapters/chapter";
import * as THREE from 'three';

// Scene objects
import * as SharpViz from "/assets/js/sharpviz";
import * as Wave from "/assets/js/wave";
import * as Organic from "/assets/js/organic";
import * as Nebula from "/assets/js/nebula";
import * as PerlinBeast from "/assets/js/perlinbeast";
import * as Spinners from "/assets/js/spinners";
import * as TrailOrbs from "/assets/js/trailOrbs";
import * as Circles from "/assets/js/circles";
import * as Triangles from "/assets/js/triangles";

// Imports for custom render passes
import { FilmPass } from 'three/examples/jsm/postprocessing/FilmPass';
import {Bloom} from "../bloom";
import {TypedArrayUtils} from "three/examples/jsm/utils/TypedArrayUtils";

export class Chapter1 extends Chapter {
    constructor(cameraFactory, scene, settings = {}) {
        super(cameraFactory, scene, settings);
        this.spinnersShoot = false;

        this.chapterTimeline = {
            '0:49': function() {
                Organic.setTremble(true);
            },
            //First drop
            '0:56': function() {
                Organic.setTremble(false);
                Organic.setAnimate(true);
                Spinners.setExpansion(0.6);
                Spinners.setFog(false);
            },
            //First breakdown
            '1:51': function() {
                Organic.setAnimate(false);
                Spinners.setExpansion(0.25);
                Spinners.setFog(true);
            },
            '1:56': function(chapter) {
                Triangles.createTriangles(chapter.scene);
            },
            //Second drop
            '3:27': function(chapter) {
                chapter.spinnersShoot = true;
                Triangles.setCycle(true);
                Spinners.setExpansion(0.6);
                Spinners.setFog(false);
            },
            //Mix into 2nd track
            '4:35': function() {
                Wave.changeColorBase(0.85);
                SharpViz.changeColor(new THREE.Color(0xff5e57));
            },
            '4:40': function() {
                Triangles.destroyTriangles();
            },
            '4:43': function(chapter) {
                chapter.spinnersShoot = false;
            },
            '4:49': function(chapter) {
                Circles.create(chapter.scene);
            },
            //Like the day they arrived
            '6:25': function(chapter) {
                Wave.expand();
                SharpViz.changeColor(new THREE.Color(0xd2dae2));
                PerlinBeast.setAnimate(true);
                TrailOrbs.createOrbs(chapter.scene);
            },
            //intensity rise
            '7:20': function() {
                Wave.setGrey(true);
            },
            //2nd track 2nd breakdown
            '8:18': function() {

            },
            //2nd track 2nd breakdown intensity - need to trigger a change here
            '8:42': function() {
                PerlinBeast.setRedHell(false);
            },
            //2nd track final drop, mix into 3rd track
            '9:10': function() {

            },
            //First "neh neh neh neh" + into first breakdown
            '10:05': function() {
                Spinners.setExpansion(0.25);
                Spinners.setFog(true);
                TrailOrbs.destroy(scene);
            },
        };
    }

    start() {
        super.start();
        this.addComposers();
        this.customizeScene();
    };

    customizeScene() {
        super.customizeScene();

        this.lights.ambient = new THREE.AmbientLight(0x555555, 0.1);
        this.scene.add(this.lights.ambient);
        this.lights.ambient2 = new THREE.AmbientLight(0x404040, 0.25);
        this.scene.add(this.lights.ambient2);

        SharpViz.createViz(this.scene);
        Wave.createWave(this.scene);
        Organic.createLines(this.scene);
        Nebula.createNebula(this.scene);
        Spinners.create(this.scene);
        PerlinBeast.create(this.scene);
    }

    addComposers() {
        super.addComposers();
        ///////////////////////////////////
        // Begin custom scene composers
        this.bloom = new Bloom(6, 1, 0.25, this.renderer, this.getRenderPass());

        // Film Grain Pass
        const filmShaderPass = new FilmPass(
            0.35, 0.75, 2048, false
        );
        this.scene.composer.addPass(filmShaderPass);
        this.scene.composer.addPass(this.bloom.getBloomShaderPass());

        // End custom scene composers
        ///////////////////////////////////
    }

    animate(audioData) {
        SharpViz.updateViz(audioData);

        Wave.animate(audioData, Spinners.getSpinners());

        Organic.animateLines(audioData);

        Nebula.animate(audioData);

        PerlinBeast.animate(audioData);

        TrailOrbs.animateOrbs(audioData);

        Circles.animate(audioData);

        Spinners.animate(audioData);

        if (this.spinnersShoot === true) {
            const triangles = Triangles.getTriangles();
            const positions = new Float32Array( triangles.length * 4 );
            for (let i = 0; i < triangles.length; i++) {
                positions[i*4] = triangles[i].position.x;
                positions[i*4+1] = triangles[i].position.y;
                positions[i*4+2] = triangles[i].position.z;
                positions[i*4+3] = i;
            }
            const kdtree = new TypedArrayUtils.Kdtree(positions, this.distanceFunction, 4);
            Spinners.shoot(kdtree, this.scene, triangles);
        } else {
            Spinners.stopShoot(this.scene);
        }

        Triangles.animate(audioData);

        //It's important this goes last so the scene this renders is in sync with the main composer
        this.bloom.renderBloom(this.scene);
    }

    end() {
        super.end();
        SharpViz.destroy(this.scene);
        Wave.destroy(this.scene);
        Organic.destroy(this.scene);
        Nebula.destroy(this.scene);
        PerlinBeast.destroy(this.scene);
        TrailOrbs.destroy(this.scene);
        Circles.destroy(this.scene);
        Triangles.remove(this.scene);
    };

    ////////////////////////////////////////////
    // Custom methods
    ////////////////////////////////////////////

    distanceFunction(a, b) {
        return Math.pow( a[ 0 ] - b[ 0 ], 2 ) + Math.pow( a[ 1 ] - b[ 1 ], 2 ) + Math.pow( a[ 2 ] - b[ 2 ], 2 );
    }
}
