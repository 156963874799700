import * as THREE from 'three';
import * as TranceMath from "/assets/js/math";

const triangles = [];
const triangleCount = 100;
const triangleSpeeds = [];
const triangleRotationSpeeds = [];
const degreePerI = 360/triangleCount;
let destroy = false;
let cycle = false;

export function remove(scene)
{
    for (let i = 0; i < triangles.length; i++) {
        triangles[i].material.dispose();
        triangles[i].geometry.dispose();
        scene.remove(triangles[i]);
    }
}

export function createTriangles(scene)
{
    destroy = false;

    const path = new THREE.Path();
    path.lineTo(0.5, 0);
    path.lineTo(0.25, -0.4);
    path.lineTo(0, 0);

    const shape = new THREE.Shape();
    shape.lineTo(0.5, 0);
    shape.lineTo(0.25, -0.4);
    shape.lineTo(0, 0);

    for (let i = 0; i < triangleCount; i++) {
        let degreeMultiplier = i;

        const triangleMaterial = new THREE.MeshBasicMaterial({
            color: 0x4bcffa,
            transparent: true,
            opacity: 0.2,
        });

        const outlineMaterial = new THREE.LineBasicMaterial({
            color: 0x4bcffa,
            linewidth: 2,
            transparent: true,
            opacity: 0.7
        });

        const triangleGeometry = new THREE.ShapeBufferGeometry(shape, triangleMaterial);
        const outlineGeometry = new THREE.BufferGeometry().setFromPoints(path.getPoints());

        let triangle = new THREE.Mesh(triangleGeometry, triangleMaterial);
        const outline = new THREE.Line(outlineGeometry, outlineMaterial);
        outline.layers.enable(1);
        outline.scale.multiplyScalar(1.001);
        triangle.add(outline);

        triangle = placeTriangle(triangle, degreeMultiplier*degreePerI);
        scene.add(triangle);
        triangles.push(triangle);
        triangle.rotateX(Math.random());
        triangle.rotateY(Math.random());
        triangle.name = i;
        let scale = Math.random()/5;
        if (scale < 0.1) {
            scale = 0.1;
        }
        triangle.scale.set(scale, scale, scale);
        setTriangleSpeeds(i);
    }
}

export function animate(audioData)
{
    const minNote = Math.min(...audioData.getNotes());
    const maxNote = Math.max(...audioData.getNotes());
    let notePointer = 0;

    for (let i = 0; i < triangles.length; i++) {
        if (notePointer >= audioData.getNotes().length) {
            notePointer = 0;
        }
        triangles[i].rotation.x += triangleRotationSpeeds[i];
        triangles[i].rotation.y += triangleRotationSpeeds[i];
        triangles[i].rotation.z += triangleRotationSpeeds[i];

        if (cycle === true) {
            triangles[i].translateOnAxis(triangles[i].worldToLocal(new THREE.Vector3(0, 0, 35)), triangleSpeeds[i]+audioData.getBeatStrength()/50000);
            const percentageDecimal = TranceMath.normalize(audioData.getNotes()[notePointer], minNote, maxNote);

            const color = new THREE.Color(0xffffff);
            color.setHSL(percentageDecimal, audioData.getBeatStrength(), 0.5);
            triangles[i].material.color.lerpHSL(color, 0.2);
            triangles[i].children[0].material.color.lerpHSL(color, 0.2);

            triangles[i].children[0].material.linewidth = audioData.getBeatStrength()*3;
        } else {
            triangles[i].translateOnAxis(triangles[i].worldToLocal(new THREE.Vector3(0, 0, 35)), triangleSpeeds[i]);
            triangles[i].material.color.setHex(0x4bcffa);
            triangles[i].children[0].material.color.setHex(0x4bcffa);
        }

        if (triangles[i].position.z > 2 && destroy === false) {
            setTimeout(function () {
                setTriangleSpeeds(i);
                placeTriangle(triangles[i], i*degreePerI);
            }, 250);
        }

        notePointer++;
    }
}

export function getTriangles()
{
    return triangles;
}

export function destroyTriangles()
{
    destroy = true;
}

function setTriangleSpeeds(i)
{
    triangleSpeeds[i] = Math.random()/50000;
    triangleRotationSpeeds[i] = Math.random()/100 - Math.random()/50 + Math.random()/100;
    if (triangleRotationSpeeds[i] < 0) {
        triangleRotationSpeeds[i] = 0.01;
    }

    if (triangleSpeeds[i] < 0.00001) {
        triangleSpeeds[i] = 0.00001;
    }
}

function placeTriangle(triangle, degree)
{
    const height = 0.5;
    let radian = TranceMath.calculateRadian(degree);
    let x = Math.cos(radian);
    let y = Math.sin(radian);
    let controlPointX = x + (height * Math.cos(radian));
    let controlPointY = y + (height * Math.sin(radian));
    triangle.position.set(controlPointX, controlPointY, (-1 - Math.random()/100));

    return triangle;
}

export function setCycle(boolean)
{
    cycle = boolean;
}
