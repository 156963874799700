import {Chapter} from "/assets/js/chapters/chapter";
import * as THREE from 'three';

// Scene objects
import * as Puffs from '/assets/js/puffs';
import * as Hex from '/assets/js/hex';
import * as Nebula from '/assets/js/nebula';
import * as Hexasphere from '/assets/js/trancehexasphere';
import * as Spinners from '/assets/js/spinners';
import * as HexTunnel from '/assets/js/visualizations/hextunnel';
import * as NoteIcos from '/assets/js/visualizations/noteicos';

// Imports for custom render passes
import {Bloom} from "../bloom";
import {AmbientLight, DirectionalLight, PointLight} from "three";

export class Chapter3 extends Chapter {
    constructor(cameraFactory, scene, settings = {}) {
        super(cameraFactory, scene, settings);

        this.chapterTimeline = {
            //verse starts
            '27:18': function() {
            },
            //like the setting sun breakdown
            '28:12': function() {

            },
            //drop
            '29:08': function() {
                Hex.shootHexes(true);
            },
            '30:03': function() {
                Hex.shootHexes(false);
                Hex.causeDispersion();
            },
            '30:30': function() {
                Hex.createPortal();
                Puffs.flyAway();
            },
            '30:31': function() {
                Nebula.destroy(scene);
                Puffs.destroy(scene);
                NoteIcos.create(scene);
            },
            //mix into next track
            '30:58': function() {

            },
            //breakdown
            '32:50': function() {

            },
            //build up
            '3412:': function() {

            },
            //drop
            '35:07': function() {

            },
            //breakdown
            '37:10': function() {

            },
            //drop
            '37:25': function() {

            },
            //next track mix in
            '38:20': function() {

            },
            //my dreams
            '38:31': function() {
                HexTunnel.destroy(scene);
                NoteIcos.destroy(scene);
            },
        };
    }

    start() {
        super.start();
        this.addComposers();
        this.customizeScene();
    };

    customizeScene() {
        super.customizeScene();

        this.lights.ambient = new THREE.AmbientLight(0x555555, 0.1);
        this.scene.add(this.lights.ambient);
        this.lights.ambient2 = new THREE.AmbientLight(0x404040, 0.25);
        this.scene.add(this.lights.ambient2);
        this.lights.directional = new THREE.DirectionalLight(0xffffff, 5);
        this.scene.add(this.lights.directional);
        this.lights.directional.position.set(0, 0, -1);

        Puffs.create(this.scene);
        Hex.create(this.scene);
        Nebula.createNebula(this.scene, false);
    }

    addComposers() {
        super.addComposers();
        ///////////////////////////////////
        // Begin custom scene composers
        this.bloom = new Bloom(3, 1, 0.25, this.renderer, this.getRenderPass());
        this.scene.composer.addPass(this.bloom.getBloomShaderPass());

        // End custom scene composers
        ///////////////////////////////////
    }

    animate(audioData) {
        Puffs.animate(audioData, this.scene);
        Hex.animate(audioData, this.scene);
        Nebula.animate(audioData);
        Hexasphere.animate(audioData);
        Spinners.animate(audioData);
        HexTunnel.animate(audioData);
        NoteIcos.animate(audioData, this.scene);

        //It's important this goes last so the scene this renders is in sync with the main composer
        this.bloom.renderBloom(this.scene);
    }

    end() {
        super.end();
        // HexTunnel.destroy(this.scene);
        // NoteIcos.destroy(this.scene);
    };

    cleanUpScene()
    {
        super.cleanUpScene();
    }

    ////////////////////////////////////////////
    // Custom methods
    ////////////////////////////////////////////
}
