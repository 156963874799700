import * as THREE from 'three';
import PointVert from '/assets/shaders/vert/point.vert';
import PointFrag from '/assets/shaders/frag/point.frag';
import * as noise from "/assets/js/thirdparty/noise";
import LavaVert from '/assets/shaders/vert/lava.vert';
import LavaFrag from '/assets/shaders/frag/lava.frag';

const clock = new THREE.Clock();
let sphere;
const sparksPerSphere = 4096;
let orbUniform;
let equalizer;
let fog = 0.2;
let desiredFog = 0.2;
let blueLava;
let equalizers = [];

export function destroy(scene)
{
    sphere.children[0].geometry.dispose();
    sphere.children[0].material.dispose();
    scene.remove(sphere.children[0]);
    sphere.geometry.dispose();
    sphere.material.dispose();
    scene.remove(sphere);
    sphere = null;
    equalizer.geometry.dispose();
    equalizer.material.dispose();
    scene.remove(equalizer);
    for (let i = 0; i < equalizers.length; i++) {
        equalizers[i].geometry.dispose();
        equalizers[i].material.dispose();
        scene.remove(equalizers[i]);
    }
}

export function create(scene)
{
    noise.seed(Math.random());
    createPlanet();
    createSparks();
    createEqualizers();

    scene.add(sphere);
    scene.add(equalizer);

    for (let i = 1; i < 25; i++) {
        const equalizer2 = equalizer.clone();
        equalizer2.position.z = -0.01 * i;
        equalizer2.position.y -= 0.2 * i;
        scene.add(equalizer2);
        equalizers.push(equalizer2);
    }
}

export function animate(audioData)
{
    if (!sphere) {
        return;
    }

    if (desiredFog !== fog) {
        fog = THREE.MathUtils.lerp(fog, desiredFog, 0.01);
    }

    const positions = sphere.children[0].geometry.attributes.position.array;
    const sizes = sphere.children[0].geometry.attributes.size.array;
    const sparkColors = sphere.children[0].geometry.attributes.customColor.array;

    const notes = audioData.getNotes();
    let noteCounter = 0;

    const color = new THREE.Color();
    color.setHSL(0.1, 1, 0.75);
    const vector3 = new THREE.Vector3();
    const time = performance.now() * 0.0003;

    for (let i = 0; i < sparksPerSphere; i++) {
        if (noteCounter >= notes.length) {
            noteCounter = 0;
        }

        color.offsetHSL(0.0005, 0, 0);

        vector3.setFromSphericalCoords(0.25, THREE.MathUtils.degToRad((360/sparksPerSphere)*i), 1 + (i * 0.5));
        vector3
            .normalize()
            .multiplyScalar(
                1 + 0.3 * noise.perlin3(
                    vector3.x + notes[noteCounter]/100 + time,
                    vector3.y + notes[noteCounter]/150 + time/2,
                    vector3.z + audioData.getBeatStrength()*3
                )
            );

        positions[i*3] = vector3.x;
        positions[(i*3)+1] = vector3.y;
        positions[(i*3)+2] = vector3.z;
        sizes[i] = notes[noteCounter]/1500;
        color.toArray(sparkColors, i * 3);
        noteCounter++;
    }

    sphere.children[0].geometry.attributes.position.needsUpdate = true;
    sphere.children[0].geometry.attributes.size.needsUpdate = true;
    sphere.children[0].geometry.attributes.customColor.needsUpdate = true;

    sphere.children[0].rotation.z += 0.005;
    sphere.children[0].rotation.x += 0.005;
    sphere.children[0].rotation.y += 0.005;

    const delta = 5 * clock.getDelta();
    orbUniform["time"].value += audioData.getBeatStrength() * delta;
    orbUniform['fogDensity'].value = fog;

    let vertexCounter = 0;
    for (let i = 0; i < 128; i++) {
        equalizer.geometry.vertices[vertexCounter].y = 1.95 + notes[i]/4000;
        vertexCounter++;
        equalizer.geometry.vertices[vertexCounter].y = 1.95 - notes[i]/4000;
        vertexCounter++;
    }
    equalizer.geometry.computeVertexNormals();
    equalizer.geometry.normalsNeedUpdate = true;
    equalizer.geometry.verticesNeedUpdate = true;
}

function createPlanet()
{
    const textureLoader = new THREE.TextureLoader();
    orbUniform = {
        "fogDensity": { value: fog },
        "fogColor": { value: new THREE.Vector3( 0, 0, 0 ) },
        "time": { value: 1.0 },
        "uvScale": { value: new THREE.Vector2( 3.0, 1.0 ) },
        "texture1": { value: textureLoader.load( '/assets/images/cloud.png' ) },
        "texture2": { value: textureLoader.load( '/assets/images/lavatile.jpg' ) }
    };

    blueLava = textureLoader.load( '/assets/images/lightningtile.jpg' );

    orbUniform[ "texture1" ].value.wrapS = orbUniform[ "texture1" ].value.wrapT = THREE.RepeatWrapping;
    orbUniform[ "texture2" ].value.wrapS = orbUniform[ "texture2" ].value.wrapT = THREE.RepeatWrapping;

    const material = new THREE.ShaderMaterial( {
        uniforms: orbUniform,
        vertexShader: LavaVert,
        fragmentShader: LavaFrag,
    });

    sphere = new THREE.Mesh( new THREE.SphereBufferGeometry(
        0.4,
        64,
        64
    ), material );
    sphere.layers.enable(1);
}

function createSparks()
{
    const sparkMaterial = new THREE.ShaderMaterial( {
        uniforms: {
            color: { value: new THREE.Color( 0xffffff ) },
            pointTexture: { value: new THREE.TextureLoader().load("/assets/images/spark1.png") }
        },
        vertexShader: PointVert,
        fragmentShader: PointFrag,

        blending: THREE.AdditiveBlending,
        depthTest: true,
        transparent: true,
        alphaTest: 0.5,
    });

    const positions = new Float32Array(sparksPerSphere * 3);
    const sizes = new Float32Array(sparksPerSphere);
    const colors = new Float32Array(sparksPerSphere * 3);
    const sparkGeometry = new THREE.BufferGeometry();
    sparkGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    sparkGeometry.setAttribute('customColor', new THREE.BufferAttribute( colors, 3));
    sparkGeometry.setAttribute('size', new THREE.BufferAttribute(sizes, 1).setUsage(THREE.DynamicDrawUsage));

    const sparkPoints = new THREE.Points(sparkGeometry, sparkMaterial);
    sparkPoints.layers.enable(1);
    sphere.add(sparkPoints);
}

function createEqualizers()
{
    const geo = new THREE.Geometry();
    let startX = -5;
    for (let i = 0; i < 128; i++) {
        geo.vertices.push(new THREE.Vector3(startX, 1.95, 0));
        geo.vertices.push(new THREE.Vector3(startX, 1.95, 0));
        startX += 0.077;
    }

    const color = new THREE.Color(0x1e272e);
    const colors = [];
    for (let i = 0; i < 256; i++) {
        colors.push(color.clone());
        if (i < 128) {
            color.offsetHSL(0.005, 0, 0);
        } else {
            color.offsetHSL(-0.005, 0, 0);
        }
    }

    geo.colors = colors;
    geo.colorsNeedUpdate = true;

    const material = new THREE.LineBasicMaterial({
        //color: 0x1e272e,
        linewidth: 10,
        vertexColors: true
    });

    equalizer = new THREE.LineSegments(geo, material);
}

export function setFog(float)
{
    desiredFog = float;
}

export function lightningMode()
{
    orbUniform['texture2'].value = blueLava;
    orbUniform["texture2"].value.wrapS = orbUniform[ "texture2" ].value.wrapT = THREE.RepeatWrapping;
}
