import * as THREE from 'three';

/**
 * This takes a mesh, splits its geometry into multiple geometries based on the
 * faces, and then returns a THREE.Group that is a collection of meshes made up of the
 * input mesh faces.
 */
export class MeshSplitter {
    split(mesh)
    {
        let geometry;
        if (mesh.geometry.isBufferGeometry) {
            geometry = new THREE.Geometry().fromBufferGeometry(mesh.geometry);
        } else {
            geometry = mesh.geometry.clone();
        }
        geometry.mergeVertices();
        geometry.computeFaceNormals();
        geometry.computeVertexNormals();

        const group = new THREE.Group();

        for (let i = 0; i < geometry.faces.length; i++) {
            const newGeometry = new THREE.Geometry();
            newGeometry.vertices.push(new THREE.Vector3(
                geometry.vertices[geometry.faces[i].a].x,
                geometry.vertices[geometry.faces[i].a].y,
                geometry.vertices[geometry.faces[i].a].z,
            ));
            newGeometry.vertices.push(new THREE.Vector3(
                geometry.vertices[geometry.faces[i].b].x,
                geometry.vertices[geometry.faces[i].b].y,
                geometry.vertices[geometry.faces[i].b].z,
            ));
            newGeometry.vertices.push(new THREE.Vector3(
                geometry.vertices[geometry.faces[i].c].x,
                geometry.vertices[geometry.faces[i].c].y,
                geometry.vertices[geometry.faces[i].c].z,
            ));
            newGeometry.faces.push(new THREE.Face3(0,1,2));
            newGeometry.computeFaceNormals();
            newGeometry.computeVertexNormals();
            newGeometry.mergeVertices();
            const newMesh = new THREE.Mesh(newGeometry, mesh.material.clone());
            group.add(newMesh);
        }

        geometry.dispose();
        return group;
    }
}
