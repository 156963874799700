import * as THREE from 'three';
import { Ocean } from '/assets/js/water';
import * as TranceMath from "/assets/js/math";
import { Refractor } from 'three/examples/jsm/objects/Refractor';
import { WaterRefractionShader } from 'three/examples/jsm/shaders/WaterRefractionShader';
import { TypedArrayUtils } from 'three/examples/jsm/utils/TypedArrayUtils';
import { TessellateModifier } from '/assets/js/tessellate';
import { LightningStrike } from 'three/examples/jsm/geometries/LightningStrike';
import TessVert from '/assets/shaders/vert/tess.vert';
import TessFrag from '/assets/shaders/frag/tess.frag';
import LavaVert from '/assets/shaders/vert/lava.vert';
import LavaFrag from '/assets/shaders/frag/lava.frag';

let ocean;
let oceanBlocker;
let lastTime = (new Date()).getTime();
let camera;
let light;
let rings = [];
const ringCount = 128;
let noteShapes = [];
let noteGroups = [];
let refractor;
let kdtree;
let originalPositions = [];
let magnetic = false;
let centerPieceRaised = true;
let centerPiece = null;
let uniforms = ({
    amplitude: { value: 0.0 }
});
let lightningMeshes = [];
let lightning = [];
let oscillator = null;
let oscillatorPositions;
let movingPoints = [];
let spotLight;
let animatePoints = false;
let beatPulse = false;
let pulses = [];
let selfMagnetize = null;
let flash = null;
let lastPreFlashColor = null;
let beatShoot = false;
let projectiles = [];
let tunnel = false;
let tunnelStart = null;
let refractorForward = false;
let oscillatorForward = true;
let oscillator2 = null;
let refractor2 = null;
let ending = false;

export function destroy(scene)
{
    destroyWater(scene);
    destroyMovingPoints(scene);
    oscillator.material.dispose();
    oscillator.geometry.dispose();
    scene.remove(oscillator);

    if (oscillator2) {
        oscillator2.material.dispose();
        oscillator2.geometry.dispose();
        scene.remove(oscillator2);
    }

    scene.remove(refractor);
    scene.remove(refractor2);

    for (let i = 0; i < refractor.children.length; i++) {
        scene.remove(refractor.children[i]);
        refractor.children[i].material.dispose();
        refractor.children[i].geometry.dispose();
    }
    refractor.material.dispose();
    refractor.geometry.dispose();

    if (refractor2) {
        for (let i = 0; i < refractor2.children.length; i++) {
            scene.remove(refractor2.children[i]);
            refractor2.children[i].material.dispose();
            refractor2.children[i].geometry.dispose();
        }
        refractor2.material.dispose();
        refractor2.geometry.dispose();
    }

    for (let i = 0; i < projectiles.length; i++) {
        projectiles[i].material.dispose();
        projectiles[i].geometry.dispose();
        scene.remove(projectiles[i]);
    }
}

export function create(renderer, scene)
{
    makeWater(scene, renderer);
    makeRings(scene);
    refractor = makeRefractor();
    scene.add(refractor);
    makeCenterPiece(scene);
    makeOscillator(scene);
    makeMovingPoints(scene);

    spotLight = new THREE.SpotLight(0xffffff, 1);
    spotLight.position.set(0, 0, 2);
    scene.add(spotLight);

    light = new THREE.PointLight(0xd2dae2, 1, 100);
    light.position.set(0, 3, 0.5);
    scene.add(light);
}

function createLightning(scene, quantity, color)
{
    if (lightningMeshes.length === 0) {
        for (let i = 0; i < quantity; i++) {
            const lightningMaterial = new THREE.MeshBasicMaterial({
                color: color,
            });
            const laserParams = {
                sourceOffset: new THREE.Vector3(),
                destOffset: new THREE.Vector3(),
                radius0: 0.0025,
                radius1: 0.0025,
                minRadius: 0.0025,
                maxIterations: 3,
                isEternal: true,

                timeScale: 0.01,

                propagationTimeFactor: 0.05,
                vanishingTimeFactor: 0.01,
                subrayPeriod: 0,
                subrayDutyCycle: 0,
                maxSubrayRecursion: 0,
                ramification: 0,
                recursionProbability: 0,

                roughness: 1,
                straightness: 0.8
            };
            const lightningStrike = new LightningStrike(laserParams);
            const lightningStrikeMesh = new THREE.Mesh(lightningStrike, lightningMaterial);
            lightningMeshes.push(lightningStrikeMesh);
            lightningStrikeMesh.layers.enable(1);
            lightning.push(lightningStrike);
            scene.add(lightningStrikeMesh);
        }
    }
}

export function setCenterPieceRaised(boolean)
{
    centerPieceRaised = boolean;
}

export function animate(audioData, spinners, scene)
{
    const min = Math.min(...audioData.getNotes());
    const max = Math.max(...audioData.getNotes());

    animateOcean(audioData);
    animateRingsAndNotes(audioData, min, max);
    animateRefractor(audioData);
    animateSpinners(spinners, audioData, scene);
    animateCenterPiece(audioData, min, max);
    animateLights(audioData);
    doBeatPulse(audioData);
    doSelfMagnetize(audioData);
    doFlash();
    doBeatShoot(scene);
    executeTunnel(scene);

    if (ending === true) {
        startCleanup();
    }
}

function startCleanup()
{
    if (refractor) {
        refractor.position.z += 0.01;
    }

    if (refractor2) {
        refractor2.position.z += 0.01;
    }

    if (oscillator) {
        oscillator.position.z += 0.01;
    }

    if (oscillator2) {
        oscillator2.position.z += 0.01;
    }

    for (let i = 0; i < projectiles.length; i++) {
        projectiles[i].position.z += 0.005;
    }
}

function distanceFunction(a, b) {
    return Math.pow( a[ 0 ] - b[ 0 ], 2 ) + Math.pow( a[ 1 ] - b[ 1 ], 2 ) + Math.pow( a[ 2 ] - b[ 2 ], 2 );
}

export function setMagnetic(bool)
{
    magnetic = bool;
}

function stopLightning(scene)
{
    for (let i = 0; i < lightningMeshes.length; i++) {
        scene.remove(lightningMeshes[i]);
    }
    lightningMeshes = [];
    lightning = [];
}

function shootLightning(spinners, scene)
{
    if (lightning.length === 0) {
        createLightning(scene, 2, 0xff3f34);
    }
    if (lightning.length > 0) {
        for (let i = 0; i < spinners.length; i++) {
            lightning[i].rayParameters.sourceOffset.copy(centerPiece.position);
            lightning[i].rayParameters.destOffset = new THREE.Vector3(spinners[i].position.x, spinners[i].position.y, spinners[i].position.z);
            lightning[i].update(Date.now()*0.5);
        }
    }
}

function makeOscillator(scene)
{
    const oscMaterial = new THREE.LineBasicMaterial({
        color: 0x4bcffa,
        linewidth: 2,
        transparent: true
    });

    const oscPath = new THREE.Path();
    const oscGeometry = new THREE.BufferGeometry()
        .setAttribute( 'position', new THREE.BufferAttribute(new Float32Array(128 * 3), 3))
        .setFromPoints(TranceMath.buildPath(oscPath, -0.3, 128).getPoints());
    oscillator = new THREE.Line(oscGeometry, oscMaterial);
    oscillator.layers.enable(1);

    scene.add(oscillator);

    oscillatorPositions = JSON.parse(JSON.stringify(oscillator.geometry.attributes.position.array));
    oscillator.position.z = 20;
}

function makeWater(scene, renderer)
{
    camera = new THREE.PerspectiveCamera(90, window.innerWidth / window.innerHeight, 0.1, 1000);

    camera.position.set(0, 475, 50);
    camera.rotation.set(-1.5, 0, 0);
    camera.lookAt(0, 0, 0);

    camera.updateProjectionMatrix();
    var gsize = 512;
    var res = 1024;
    var gres = res / 4;
    ocean = new Ocean(renderer, camera, scene, {
        USE_HALF_FLOAT: false,
        INITIAL_SIZE: 256,
        INITIAL_WIND: [ 10.0, 10.0 ],
        INITIAL_CHOPPINESS: 5,
        CLEAR_COLOR: [ 1.0, 1.0, 1.0, 0.0 ],
        GEOMETRY_ORIGIN: [ 0, 0 ],
        SUN_DIRECTION: [ 0.5, 0.5, 0 ],
        OCEAN_COLOR: new THREE.Vector3( 0.004, 0.016, 0.047 ),
        SKY_COLOR: new THREE.Vector3( 32, 16, 10 ),
        EXPOSURE: 0.2,
        GEOMETRY_RESOLUTION: gres,
        GEOMETRY_SIZE: gsize,
        RESOLUTION: res
    });

    ocean.materialOcean.uniforms[ "u_projectionMatrix" ] = { value: camera.projectionMatrix };
    ocean.materialOcean.uniforms[ "u_viewMatrix" ] = { value: camera.matrixWorldInverse };
    ocean.materialOcean.uniforms[ "u_cameraPosition" ] = { value: camera.position };

    scene.add(ocean.oceanMesh);

    const geometry = new THREE.RingBufferGeometry( 0.9, 1.4, 64 );
    const material = new THREE.MeshBasicMaterial({
        color: 0x000000
    });
    oceanBlocker = new THREE.Mesh(geometry, material);
    scene.add(oceanBlocker);
}

function makeRings(scene)
{
    const ringColor = new THREE.Color(0xffa801);
    const emissiveColor = ringColor.clone();
    emissiveColor.offsetHSL(0.005, 0, 0);

    const ringMaterial = new THREE.LineBasicMaterial({
        color: ringColor,
        linewidth: 3,
        transparent: true,
        opacity: 0.5
    });
    let height = -0.14;

    const noteMaterial = new THREE.MeshPhongMaterial({
        color: ringColor,
        emissive: emissiveColor,
        emissiveIntensity: 0.5,
        shininess: 30,
        specular: 0xd2dae2,
        transparent: true,
        opacity: 1
    });
    let noteHeight = 0.02;
    let degreeShift = 0;

    for (let i = 0; i < ringCount; i++) {
        const clonedMaterial = ringMaterial.clone();
        ringColor.offsetHSL(-0.005, 0, 0);
        clonedMaterial.color = ringColor.clone();
        const ringPath = new THREE.Path();
        const ringGeometry = new THREE.BufferGeometry()
            .setAttribute( 'position', new THREE.BufferAttribute(new Float32Array(ringCount * 3), 3))
            .setFromPoints(TranceMath.buildPath(ringPath, height, 128).getPoints());

        const ring = new THREE.Line(ringGeometry, clonedMaterial);
        ring.position.z = 0.1;
        scene.add(ring);
        rings.push(ring);
        height += 0.03;
        if (i % 10 === 0) {
            ring.layers.enable(1);
        }

        const clonedNoteMaterial = noteMaterial.clone();
        clonedNoteMaterial.color = ringColor.clone();
        emissiveColor.offsetHSL(-0.005, 0, 0);
        clonedNoteMaterial.emissive = emissiveColor.clone();

        const noteGroup = new THREE.Group();
        if (i % 10 === 0) {
            for (let b = 0; b < 10; b++) {
                let radian = TranceMath.calculateRadian(((360/10)*b)+degreeShift);
                let x = Math.cos(radian);
                let y = Math.sin(radian);
                let controlPointX = x + (noteHeight * Math.cos(radian));
                let controlPointY = y + (noteHeight * Math.sin(radian));
                const noteGeometry = new THREE.DodecahedronBufferGeometry(0.015, 0);
                const note = new THREE.Mesh(noteGeometry, clonedNoteMaterial);
                note.position.set(controlPointX, controlPointY, 0.1);
                note.layers.enable(1);
                noteShapes.push(note);
                noteGroup.add(note);
                scene.add(note);
            }
        }
        noteGroups.push(noteGroup);


        noteHeight += 0.03;
        degreeShift += 0;

        originalPositions.push(JSON.parse(JSON.stringify(ring.geometry.attributes.position.array)))
    }

    const ringPositionsExtended = new Float32Array(ringCount * 4);
    for (let i = 0; i < rings[40].geometry.attributes.position.array.length; i++) {
        ringPositionsExtended[i*4] = rings[40].geometry.attributes.position.array[i*3];
        ringPositionsExtended[i*4+1] = rings[40].geometry.attributes.position.array[i*3+1];
        ringPositionsExtended[i*4+2] = rings[40].geometry.attributes.position.array[i*3+2];
        ringPositionsExtended[i*4+3] = i;
    }
    kdtree = new TypedArrayUtils.Kdtree(ringPositionsExtended, distanceFunction, 4);
}

function makeRefractor()
{
    const refractorGeometry = new THREE.RingBufferGeometry(0.5, 0.6, 64);
    const refractor = new Refractor(refractorGeometry, {
        color: 0xd2dae2,
        textureWidth: 1024,
        textureHeight: 1024,
        shader: WaterRefractionShader
    });

    refractor.position.set(0, 0, 0.1);

    const refractorOutline = new THREE.RingBufferGeometry(0.49, 0.51, 64);
    const refractorOutlineMaterial = new THREE.MeshLambertMaterial({
        color: 0xffffff
    });
    const refractorRing = new THREE.Mesh(refractorOutline, refractorOutlineMaterial);
    const refractorOutline2 = new THREE.RingBufferGeometry(0.6, 0.61, 64);
    const refractorRing2 = new THREE.Mesh(refractorOutline2, refractorOutlineMaterial);

    refractor.add(refractorRing);
    refractor.add(refractorRing2);
    refractor.position.set(0, 0, 0.1);

    const dudvMap = new THREE.TextureLoader().load( '/assets/images/waterdudv.jpg', function () {
        dudvMap.wrapS = dudvMap.wrapT = THREE.RepeatWrapping;
        refractor.material.uniforms["tDudv"].value = dudvMap;
    });

    return refractor;
}

function makeCenterPiece(scene)
{
    const startGeometry = new THREE.OctahedronGeometry(0.2);
    let tessellateModifier = new TessellateModifier(1);
    tessellateModifier.modify(startGeometry);
    let centerPieceGeometry = new THREE.BufferGeometry().fromGeometry(startGeometry);

    let numFaces = centerPieceGeometry.attributes.position.count / 3;
    let colors = new Float32Array( (numFaces * 3) * 3 );
    let displacement = new Float32Array( (numFaces * 3) * 3 );
    let color = new THREE.Color();

    for (let f = 0; f < numFaces; f ++) {
        let index = 9 * f;
        let h = 0.3;
        let s = 1;
        let l = 0.6;
        color.setHSL(h, s, l);
        let d = 0.1;
        for (let i = 0; i < 3; i ++) {
            colors[index + ( 3 * i )] = color.r;
            colors[index + ( 3 * i ) + 1] = color.g;
            colors[index + ( 3 * i ) + 2] = color.b;

            displacement[index + ( 3 * i )] = d;
            displacement[index + ( 3 * i ) + 1] = d;
            displacement[index + ( 3 * i ) + 2] = d;
        }
    }

    const shaderMaterial = new THREE.ShaderMaterial({
        uniforms: uniforms,
        vertexShader: TessVert,
        fragmentShader: TessFrag,
        side: THREE.DoubleSide
    });

    centerPieceGeometry.setAttribute( 'customColor', new THREE.BufferAttribute(colors, 3));
    centerPieceGeometry.setAttribute( 'displacement', new THREE.BufferAttribute(displacement, 3));

    centerPiece = new THREE.Mesh(centerPieceGeometry, shaderMaterial);
    centerPiece.position.set(0, 0, 0.5);
    centerPiece.layers.enable(1);
    centerPiece.position.z = 20;

    const outlineGeometry = new THREE.BufferGeometry().fromGeometry(new THREE.OctahedronGeometry(0.2));
    const outlineMaterial = new THREE.LineBasicMaterial({
        color: 0xffffff,
        linewidth: 3,
        transparent: true,
        opacity: 0.7
    });
    const outline = new THREE.Line(outlineGeometry, outlineMaterial);
    centerPiece.add(outline);

    const textureLoader = new THREE.TextureLoader();
    const orbUniform = {
        "fogDensity": { value: 0.9 },
        "fogColor": { value: new THREE.Vector3( 75, 207, 250 ) },
        "time": { value: 1.0 },
        "uvScale": { value: new THREE.Vector2( 3.0, 1.0 ) },
        "texture1": { value: textureLoader.load( '/assets/images/cloud.png' ) },
        "texture2": { value: textureLoader.load( '/assets/images/lightningtile.jpg' ) }
    };
    orbUniform[ "texture1" ].value.wrapS = orbUniform[ "texture1" ].value.wrapT = THREE.RepeatWrapping;
    orbUniform[ "texture2" ].value.wrapS = orbUniform[ "texture2" ].value.wrapT = THREE.RepeatWrapping;

    const orbMaterial = new THREE.ShaderMaterial( {
        uniforms: orbUniform,
        vertexShader: LavaVert,
        fragmentShader: LavaFrag,
    });

    const orb = new THREE.Mesh(new THREE.SphereBufferGeometry(0.04, 16, 16), orbMaterial);
    orb.layers.enable(1);
    centerPiece.add(orb);

    scene.add(centerPiece);
}

function makeMovingPoints(scene)
{
    for (let i = 0; i < 128; i++) {
        const material = new THREE.MeshLambertMaterial({
            color: 0x000000
        });
        material.color.setHSL(0, 0, 0);

        const geometry = new THREE.SphereBufferGeometry( 0.01, 16, 16 );
        const sphere = new THREE.Mesh(geometry, material);
        const position = TranceMath.controlPointsByHeight((360/128)*i, 0, 0, 0.65);
        sphere.position.set(position.x, position.y, 0.5);
        scene.add(sphere);
        movingPoints.push(sphere);
    }
}

export function setAnimatePoints(boolean)
{
    animatePoints = boolean;
}

export function setBeatPulse(boolean)
{
    beatPulse = boolean;
}

export function setSelfMagnetize(ring)
{
    selfMagnetize = ring;
}

export function triggerFlash()
{
    flash = 0;
}

export function destroyWater(scene)
{
    if (ocean) {
        ocean.oceanMesh.geometry.dispose();
        ocean.oceanMesh.material.dispose();
        scene.remove(ocean.oceanMesh);
        oceanBlocker.geometry.dispose();
        oceanBlocker.material.dispose();
        scene.remove(oceanBlocker);
        ocean = null;
    }
}

export function setBeatShoot(boolean)
{
    beatShoot = boolean;
}

export function tunnelize()
{
    tunnel = true;
}

export function windDown()
{
    ending = true;
}

function destroyMovingPoints(scene)
{
    for (let i = 0; i < movingPoints.length; i++) {
        movingPoints[i].material.dispose();
        movingPoints[i].geometry.dispose();
        scene.remove(movingPoints[i]);
    }
}

export function bringRefractorForward()
{
    refractorForward = true;
}

export function createFastOscillator(scene)
{
    if (oscillator2 === null) {
        oscillator2 = oscillator.clone();
        oscillator2.position.z = 2.1;
        scene.add(oscillator2);
    }
}

function animateOcean(audioData)
{
    if (!ocean) {
        return;
    }

    const currentTime = new Date().getTime();
    ocean.deltaTime = (currentTime - lastTime)/1000 || 0.0;
    lastTime = currentTime;
    ocean.render(ocean.deltaTime);
    ocean.overrideMaterial = ocean.materialOcean;

    ocean.choppiness = THREE.MathUtils.clamp(audioData.getBeatStrength()*5, 3, 100);
    ocean.windX = audioData.getBeatStrength()*5;
    ocean.windY = audioData.getMelodyMidiIndex()*10;

    ocean.changed = true;

    if (ocean.changed) {
        ocean.materialOcean.uniforms[ "u_size" ].value = ocean.size;
        ocean.materialOcean.uniforms[ "u_sunDirection" ].value.set( ocean.sunDirectionX, ocean.sunDirectionY, ocean.sunDirectionZ );
        ocean.materialOcean.uniforms[ "u_exposure" ].value = ocean.exposure;
        ocean.changed = false;
    }

    ocean.materialOcean.uniforms[ "u_normalMap" ].value = ocean.normalMapFramebuffer.texture;
    ocean.materialOcean.uniforms[ "u_oceanColor" ].value = new THREE.Vector3(audioData.getBeatStrength()/10, audioData.getBeatStrength()/20, audioData.getMelodyStrength());
    ocean.materialOcean.uniforms[ "u_displacementMap" ].value = ocean.displacementMapFramebuffer.texture;
    ocean.materialOcean.uniforms[ "u_projectionMatrix" ].value = camera.projectionMatrix;
    ocean.materialOcean.uniforms[ "u_viewMatrix" ].value = camera.matrixWorldInverse;
    ocean.materialOcean.uniforms[ "u_cameraPosition" ].value = camera.position;
    ocean.materialOcean.depthTest = true;
}

function animateRingsAndNotes(audioData, min, max)
{
    if (rings.length === 0) {
        return;
    }

    const notes = audioData.getNotes();

    for (let i = 0; i < rings.length; i++) {
        if (i % 10 !== 0) {
            rings[i].material.opacity = THREE.MathUtils.clamp(TranceMath.normalize(notes[i], min, max), 0, 0.8);
        }
    }

    for (let i = 0; i < noteShapes.length; i++) {
        noteShapes[i].position.z = notes[i]/1000;
    }
}

function animateRefractor(audioData)
{
    if (!refractor) {
        return;
    }

    light.intensity = audioData.getBeatStrength();

    const time = Date.now() * 0.00025;
    light.position.x = -Math.sin(time)*0.3;
    light.position.y = -Math.cos(time)*0.3;

    refractor.material.uniforms[ "time" ].value += audioData.getBeatStrength()/100;
    if (refractor2) {
        refractor2.material.uniforms[ "time" ].value += audioData.getBeatStrength()/100;
    }
}

function animateSpinners(spinners, audioData, scene)
{
    const notes = audioData.getNotes();
    for (let i = 0; i < spinners.length; i++) {
        let height = -0.14;
        const startRing = 1;
        for (let c = startRing; c < 80; c++) {
            //40 is closest to the spinners
            let divisor = THREE.MathUtils.clamp(50*(Math.abs(40-c)), 300, 3500);
            const positions = rings[c].geometry.attributes.position.array;
            for (let i = 0; i < ringCount; i++) {
                if (originalPositions[c][i*3] !== positions[i*3]) {
                    const origVector = new THREE.Vector3(originalPositions[c][i*3], originalPositions[c][i*3+1], originalPositions[c][i*3+2]);
                    const currVector = new THREE.Vector3(positions[i*3], positions[i*3+1], positions[i*3+2]);
                    currVector.lerp(origVector, 0.01);
                    positions[i*3] = currVector.x;
                    positions[i*3+1] = currVector.y;
                }
            }
            if (magnetic === true && audioData.getBeatStrength() > 0.8) {
                const objectPositionsInRange = kdtree.nearest([spinners[i].position.x, spinners[i].position.y, spinners[i].position.z], 16, 5);
                for (let b = 0; b < objectPositionsInRange.length; b++) {
                    let radian = TranceMath.calculateRadian((360/ringCount)*objectPositionsInRange[b][0].obj[3]);
                    let x = Math.cos(radian);
                    let y = Math.sin(radian);
                    let controlPointX = x + (((height+(0.03*c)) + notes[objectPositionsInRange[b][0].obj[3]]/divisor) * Math.cos(radian));
                    let controlPointY = y + (((height+(0.03*c)) + notes[objectPositionsInRange[b][0].obj[3]]/divisor) * Math.sin(radian));
                    const currVector = new THREE.Vector3(
                        positions[objectPositionsInRange[b][0].obj[3]*3],
                        positions[(objectPositionsInRange[b][0].obj[3]*3)+1],
                        positions[(objectPositionsInRange[b][0].obj[3]*3)+2],
                    );
                    const desiredVector = new THREE.Vector3(
                        controlPointX,
                        controlPointY,
                        positions[(objectPositionsInRange[b][0].obj[3]*3)+2]
                    );
                    currVector.lerp(desiredVector, 0.05);
                    positions[objectPositionsInRange[b][0].obj[3]*3] = currVector.x;
                    positions[(objectPositionsInRange[b][0].obj[3]*3)+1] = currVector.y;
                    if (b < 8) {
                        divisor = divisor*0.95;
                    } else {
                        divisor = divisor*1.05;
                    }
                }
                shootLightning(spinners, scene);
            } else {
                if (beatShoot !== true) {
                    stopLightning(scene);
                }
            }
            rings[c].geometry.attributes.position.needsUpdate = true;
        }
    }
}

function animateCenterPiece(audioData, min, max)
{
    const notes = audioData.getNotes();
    let notePointer = 33;

    if (centerPiece !== null) {
        let colors = centerPiece.geometry.attributes.customColor.array;
        const color = new THREE.Color();
        for (let x = 0; x < colors.length; x++) {
            if (notePointer === notes.length) {
                notePointer = 0;
            }

            color.setHSL(0.3 + (notes[notePointer]/250), 1, (THREE.MathUtils.clamp(audioData.getMelodyStrength(), 0.3, 0.8)));
            color.toArray(colors, x * 3);
            notePointer++;
        }
        centerPiece.geometry.attributes.customColor.needsUpdate = true;

        centerPiece.rotation.x += audioData.getBeatStrength() / 40;
        centerPiece.rotation.y += audioData.getMelodyMidiIndex() / 40;
        centerPiece.rotation.z += audioData.getMelodyWindowRatio() / 40;
        uniforms.amplitude.value = (audioData.getBeatStrength())*1.25;

        if (centerPieceRaised === false) {
            let desiredPosition = new THREE.Vector3(0, 0, 0.5);
            let currentPosition = new THREE.Vector3(centerPiece.position.x, centerPiece.position.y, centerPiece.position.z);
            currentPosition.lerp(desiredPosition, 0.01);
            centerPiece.position.set(currentPosition.x, currentPosition.y, currentPosition.z);

            desiredPosition = new THREE.Vector3(0, 0, 0);
            currentPosition = new THREE.Vector3(oscillator.position.x, oscillator.position.y, oscillator.position.z);
            currentPosition.lerp(desiredPosition, 0.01);
            oscillator.position.set(currentPosition.x, currentPosition.y, currentPosition.z);
        }
    }

    if (oscillator !== null) {
        //animate oscillator
        let currentOscillatorPositions = oscillator.geometry.attributes.position.array;

        if (min === max || max === 0) {
            return;
        }

        const selectedNotes = [].slice.call(notes.slice(78, 129));
        notePointer = 0;

        for (let i = 0; i < 128; i++) {
            if (notePointer === selectedNotes.length) {
                notePointer = 0;
            }
            let height = (TranceMath.normalize(selectedNotes[notePointer], min, max)/8)*audioData.getBeatStrength();
            let radian = TranceMath.calculateRadian((360/128) * i);
            currentOscillatorPositions[i*3] = oscillatorPositions[i*3] + (height * Math.cos(radian));
            currentOscillatorPositions[i*3+1] = oscillatorPositions[i*3+1] + (height * Math.sin(radian));
            notePointer++;
        }

        currentOscillatorPositions[384] = currentOscillatorPositions[0];
        currentOscillatorPositions[385] = currentOscillatorPositions[1];
        currentOscillatorPositions[386] = currentOscillatorPositions[2];
        oscillator.geometry.attributes.position.needsUpdate = true;
        oscillator.rotation.z += 0.01;
    }
}

function animateLights(audioData)
{
    const notes = audioData.getNotes();
    if (animatePoints === true) {
        const pointColor = new THREE.Color();
        for (let i = 0; i < movingPoints.length; i++) {
            if (i > 64) {
                pointColor.setHSL(0.5 + notes[i]/500, 0.75, audioData.getMelodyOctaveIndex());
            } else {
                pointColor.setHSL(0.1 + notes[i]/500, 0.75, audioData.getBeatStrength());
            }

            movingPoints[i].material.color.lerpHSL(pointColor, 0.1);
            movingPoints[i].layers.enable(1);
        }
    } else {
        const pointColor = new THREE.Color();
        pointColor.setHSL(0, 0, 0);
        for (let i = 0; i < movingPoints.length; i++) {
            movingPoints[i].material.color.lerpHSL(pointColor, 0.1);
            movingPoints[i].layers.disable(1);
        }
    }
}

function doBeatPulse(audioData)
{
    if (beatPulse === true) {
        if (audioData.getBeatStrength() > 0.9) {
            pulses.push(0);
        }

        for (let i = 0; i < rings.length; i++) {
            if (pulses.indexOf(i) !== -1) {
                rings[i].position.z = 0.3;
            } else {
                rings[i].position.z = THREE.MathUtils.lerp(rings[i].position.z, 0.1, 0.01);
            }
        }

        for (let i = 0; i < pulses.length; i++) {
            pulses[i] += 1;
            if (pulses[i] >= 127) {
                pulses.splice(i, 1);
            }
        }
    } else {
        for (let i = 0; i < rings.length; i++) {
            if (rings[i].position.z !== 0.1) {
                rings[i].position.z = THREE.MathUtils.lerp(rings[i].position.z, 0.1, 0.01);
            }
        }
    }
}

function doSelfMagnetize(audioData)
{
    const notes = audioData.getNotes();
    if (selfMagnetize !== null) {
        let height = -0.14;
        let currentRingPosition = selfMagnetize-10;
        while (currentRingPosition < selfMagnetize+10) {
            const positions = rings[currentRingPosition].geometry.attributes.position.array;
            let divisor = 500 + (Math.abs(currentRingPosition - selfMagnetize)*100);
            for (let i = 0; i < ringCount; i++) {
                let radian = TranceMath.calculateRadian((360/ringCount)*i);
                let x = Math.cos(radian);
                let y = Math.sin(radian);
                let controlPointX = x + (((height+(0.03*currentRingPosition)) + notes[i]/divisor) * Math.cos(radian));
                let controlPointY = y + (((height+(0.03*currentRingPosition)) + notes[i]/divisor) * Math.sin(radian));
                const currVector = new THREE.Vector3(
                    positions[i*3],
                    positions[(i*3)+1],
                    positions[(i*3)+2],
                );
                const desiredVector = new THREE.Vector3(
                    controlPointX,
                    controlPointY,
                    positions[(positions[i]*3)+2]
                );
                currVector.lerp(desiredVector, 0.1);
                positions[i*3] = currVector.x;
                positions[(i*3)+1] = currVector.y;
            }
            rings[currentRingPosition].geometry.attributes.position.needsUpdate = true;
            currentRingPosition++;
        }
    }
}

function doFlash()
{
    if (flash !== null) {
        if (lastPreFlashColor !== null) {
            rings[flash].material.color = lastPreFlashColor;
        }
        flash++;
        if (flash >= rings.length) {
            flash = null;
            return;
        }
        lastPreFlashColor = rings[flash].material.color;
        rings[flash].material.color = new THREE.Color({
            color: 0xffffff
        });
        rings[flash].material.opacity = 1;
    }
}

function doBeatShoot(scene)
{
    if (beatShoot === true) {
        if (projectiles.length === 0) {
            const material = new THREE.MeshLambertMaterial();
            material.color.setHSL(0.3, 1, 0.6);
            createLightning(scene, 6, 0x4bcffa);
            for (let i = 0; i < 6; i++) {
                material.color.offsetHSL(0.1, 0, 0);
                const geometry = new THREE.OctahedronBufferGeometry(0.05);
                const mesh = new THREE.Mesh(geometry, material.clone());
                const position = TranceMath.controlPointsByHeight((360/6)*projectiles.length, 0, 0, 1);
                mesh.position.x = position.x;
                mesh.position.y = position.y;
                mesh.position.z = 0.75;
                mesh.layers.enable(1);
                projectiles.push(mesh);
                scene.add(mesh);
                lightning[i].rayParameters.sourceOffset.copy(centerPiece.position);
                lightning[i].rayParameters.destOffset = new THREE.Vector3(projectiles[i].position.x, projectiles[i].position.y, projectiles[i].position.z);
            }
        }

        const time = Date.now() * 0.00025;

        for (let i = 0; i < projectiles.length; i++) {
            projectiles[i].rotation.z += 0.01;
            projectiles[i].rotation.x += 0.01;
            projectiles[i].rotation.y += 0.01;
            if (lightning.length > 0) {
                lightning[i].rayParameters.sourceOffset.copy(centerPiece.position);
                lightning[i].rayParameters.destOffset = new THREE.Vector3(projectiles[i].position.x, projectiles[i].position.y, projectiles[i].position.z);
                lightning[i].update(Date.now()*0.5);
            }

            projectiles[i].position.x = -Math.sin(time+i)*0.75;
            projectiles[i].position.y = -Math.cos(time+i)*0.75;
        }
    }
}

function executeTunnel(scene)
{
    if (tunnel === true) {
        if (tunnelStart === null) {
            tunnelStart = Date.now();
            stopLightning(scene);
        }
        if (Date.now() - tunnelStart > 4000) {
            destroyMovingPoints(scene);
            for (let i = 0; i < rings.length; i++) {
                rings[i].position.z = THREE.MathUtils.lerp(rings[i].position.z, 10*(i+1), 0.001);
                rings[i].material.opacity -= 0.001;
                if (rings[i].position.z > 1) {
                    rings[i].geometry.dispose();
                    rings[i].material.dispose();
                    scene.remove(rings[i]);
                }
            }
            for (let i = 0; i < noteShapes.length; i++) {
                noteShapes[i].geometry.dispose();
                noteShapes[i].material.dispose();
                scene.remove(noteShapes[i]);
            }

            if (ending === false) {
                refractor.position.z = THREE.MathUtils.lerp(refractor.position.z, 0.4, 0.01);
            }
            refractor.rotation.z -= 0.004;

            if (refractorForward === true) {
                if (!refractor2) {
                    refractor2 = refractor.clone();
                    scene.add(refractor2);
                }
                if (ending === false) {
                    refractor2.position.z = THREE.MathUtils.lerp(refractor2.position.z, 1.6, 0.05);
                }
                refractor2.rotation.z += 0.005;
            }

            if (centerPiece !== null) {
                centerPiece.position.z = THREE.MathUtils.lerp(centerPiece.position.z, -100, 0.01);
            }

            if (ending === false) {
                if (oscillatorForward === true) {
                    oscillator.position.z = THREE.MathUtils.lerp(oscillator.position.z, 1.5, 0.001);
                } else {
                    oscillator.position.z = THREE.MathUtils.lerp(oscillator.position.z, 0, 0.001);
                }

                if (oscillator.position.z > 1.3) {
                    oscillatorForward = false;
                } else if (oscillator.position.z < 0.2) {
                    oscillatorForward = true;
                }

                if (oscillator2 !== null) {
                    oscillator2.position.z = THREE.MathUtils.lerp(oscillator2.position.z, 0.4, 0.001);
                    if (oscillator2.position.z < 0.6) {
                        oscillator2.position.z = 3;
                    }
                }
            }

            if (Date.now() - tunnelStart > 10000 && centerPiece !== null) {
                centerPiece.geometry.dispose();
                centerPiece.material.dispose();
                for (let i = 0; i < centerPiece.children; i++) {
                    centerPiece.children[i].material.dispose();
                    centerPiece.children[i].geometry.dispose();
                    scene.remove(centerPiece.children[i]);
                }
                scene.remove(centerPiece);
                centerPiece = null;
            }
        } else {
            for (let i = 0; i < noteShapes.length; i++) {
                noteShapes[i].position.x = THREE.MathUtils.lerp(noteShapes[i].position.x, 0, 0.05);
                noteShapes[i].position.y = THREE.MathUtils.lerp(noteShapes[i].position.y, 0, 0.05);
                noteShapes[i].position.z = THREE.MathUtils.lerp(noteShapes[i].position.z, -5, 0.02);
            }

            let ringZ = -8;
            for (let i = 0; i < rings.length; i++) {
                rings[i].position.z = THREE.MathUtils.lerp(rings[i].position.z, ringZ, 0.015);
                ringZ += 0.1;
            }
            centerPiece.position.z = THREE.MathUtils.lerp(centerPiece.position.z, -8, 0.015);
        }
    }
}
