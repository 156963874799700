import * as THREE from 'three';
import * as noise from "./thirdparty/noise";

let bigPuff;
let escaping = false;

export function destroy(scene)
{
    if (bigPuff) {
        bigPuff.geometry.dispose();
        bigPuff.material.dispose();
        scene.remove(bigPuff);
        bigPuff = null;
    }
}

export function create(scene)
{
    noise.seed(Math.random());
    const sphereMaterial = new THREE.MeshLambertMaterial({
        color: 0x485460,
        emissive: 0x4bcffa,
        emissiveIntensity: 1,
        transparent: true,
        opacity: 0
    });
    const sphereGeometry = new THREE.SphereGeometry(1, 128, 128);

    bigPuff = new THREE.Mesh(sphereGeometry, sphereMaterial);
    bigPuff.position.set(0.001, 0.001, -1.5);
    scene.add(bigPuff);
}

export function animate(audioData, scene)
{
    if (!bigPuff) {
        return;
    }

    const time = performance.now() * 0.003;

    if (audioData.getBeatStrength() >= 0.9) {
        if (bigPuff.material.opacity < 1) {
            bigPuff.material.opacity += 0.1;
        }
    } else {
        if (bigPuff.material.opacity > 0) {
            bigPuff.material.opacity -= 0.1;
        }
    }

    for (let i = 0; i < bigPuff.geometry.vertices.length; i++) {
        const p = bigPuff.geometry.vertices[i];
        p.normalize()
            .multiplyScalar(
                1  + 0.5 * noise.perlin3(
                p.x * (THREE.MathUtils.clamp(audioData.getBeatStrength(), 0.2, 1)*3) + time,
                p.y * (THREE.MathUtils.clamp(audioData.getBeatStrength(), 0.2, 1)*3),
                p.z * (THREE.MathUtils.clamp(audioData.getBeatStrength(), 0.2, 1)*3)
                )
            );
    }

    if (audioData.isBeat() === true) {
        bigPuff.material.emissive.offsetHSL(0.05, 0, 0);
    }

    bigPuff.geometry.computeVertexNormals();
    bigPuff.geometry.normalsNeedUpdate = true;
    bigPuff.geometry.verticesNeedUpdate = true;

    bigPuff.rotation.x += audioData.getMelodyStrength()/100;
    bigPuff.rotation.y += audioData.getHihatStrength()/100;
    bigPuff.rotation.z += audioData.getMelodyOctaveIndex()/100;

    if (escaping === true) {
        bigPuff.position.z = THREE.MathUtils.lerp(bigPuff.position.z, -50, 0.02);
        if (bigPuff.position.z < -35) {
            bigPuff.geometry.dispose();
            bigPuff.material.dispose();
            scene.remove(bigPuff);
            bigPuff = null;
        }
    }
}

export function flyAway()
{
    escaping = true;
}
