import * as THREE from 'three';
import * as TranceMath from '/assets/js/math';

let sunburst = new THREE.Group();
let ico;
let rotate = false;

export function destroy(scene)
{
    if (sunburst) {
        for (let i = 0; i < sunburst.children.length; i++) {
            sunburst.children[i].material.dispose();
            sunburst.children[i].geometry.dispose();
            scene.remove(sunburst.children[i]);
        }
        scene.remove(sunburst);
        sunburst = null;

        ico.geometry.dispose();
        ico.material.dispose();
        scene.remove(ico);
        ico = null;
    }
}

export function create(scene)
{
    buildSunburst(scene);
}

export function animate(audioData)
{
    if (ico) {
        const notes = audioData.getNotes();

        ico.rotation.x += 0.01;
        ico.rotation.y += 0.01;
        ico.rotation.z += 0.01;
        const icoScale = 1 + audioData.getBeatStrength();
        ico.scale.set(icoScale, icoScale, icoScale);
        const color = new THREE.Color();
        color.setHSL(audioData.getMelodyOctaveIndex(), 1, audioData.getHihatStrength());
        ico.material.color.lerpHSL(color, 0.5);

        for (let i = 0; i < sunburst.children.length; i++) {
            sunburst.children[i].material.opacity = THREE.MathUtils.clamp(notes[i]/250, 0.01, 1);
            sunburst.children[i].material.linewidth = THREE.MathUtils.clamp(notes[i]/50, 2, 50);
            if (notes[i] > 150) {
                sunburst.children[i].layers.enable(1);
            } else {
                sunburst.children[i].layers.disable(1);
            }
        }

        if (rotate === true) {
            sunburst.rotation.z += 0.001;
        }
    }
}

function buildSunburst(scene)
{
    const material = new THREE.LineBasicMaterial({
        color:0xff3f34,
        linewidth: 3,
        transparent: true,
        opacity: 0
    });

    material.color.offsetHSL(0, 0, 0.05);

    const geometry = new THREE.Geometry()
    for (let i = 0; i < 100; i++) {
        material.color.offsetHSL(0.01, 0, 0);
        const myGeo = geometry.clone();
        myGeo.vertices.push(new THREE.Vector3(0, 0, -10));
        const endControlPoint = TranceMath.controlPointsByHeight((360/100)*i, 0, 0, 5);
        myGeo.vertices.push(new THREE.Vector3(endControlPoint.x, endControlPoint.y, 2));
        const line = new THREE.LineSegments(myGeo, material.clone());
        sunburst.add(line);
    }
    scene.add(sunburst);

    const icoMaterial = new THREE.MeshBasicMaterial({
        color: 0x000000
    });
    const icoGeo = new THREE.IcosahedronBufferGeometry(0.6);
    ico = new THREE.Mesh(icoGeo, icoMaterial);
    ico.position.set(0, 0, -5);
    const edges = new THREE.EdgesGeometry(icoGeo);
    const line = new THREE.LineSegments(
        edges,
        new THREE.LineBasicMaterial({
            color: 0xd2dae2,
            linewidth: 3
        })
    );
    line.layers.enable(1);
    ico.add(line);
    scene.add(ico);
}

export function setRotate(boolean)
{
    rotate = boolean;
}
