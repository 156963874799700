import * as THREE from 'three';
import * as noise from "/assets/js/thirdparty/noise";

const waves = new THREE.Group();
let bubbler;
const originalPoints = [];

export function destroy(scene) {
    const children = waves.children.length;
    for (let i = 0; i < children; i++) {
        waves.children[i].geometry.dispose();
        waves.children[i].material.dispose();
        scene.remove(waves.children[i]);
    }
    scene.remove(waves);

    bubbler.geometry.dispose();
    bubbler.material.dispose();
    scene.remove(bubbler);
}

export function create(scene)
{
    createWaves();
    createBubbler();

    noise.seed(Math.random());

    scene.add(bubbler);
    scene.add(waves);
}

export function animate(audioData)
{
    if (bubbler) {
        const notes = audioData.getNotes();
        let noteCounter = 0;
        const dummy = new THREE.Object3D();
        const vector = new THREE.Vector3();
        let thetaCounter = 2048;
        for (let i = 0; i < 2048; i++) {
            if (noteCounter === 128) {
                noteCounter = 0;
            }
            vector.setFromSphericalCoords(
                1.5 + notes[noteCounter]/500,
                THREE.MathUtils.degToRad((360 / 2048) * i),
                thetaCounter
            );
            dummy.position.x = vector.x;
            dummy.position.y = vector.y;
            dummy.position.z = vector.z;
            dummy.updateMatrix();
            bubbler.setMatrixAt(i, dummy.matrix);
            thetaCounter--;
            noteCounter++;
        }

        for (let i = 0; i < originalPoints.length; i++) {
            const currentPoints = waves.children[i].geometry.attributes.position.array;
            for (let x = 0; x < notes.length; x++) {
                if (i % 2 === 0) {
                    currentPoints[(x*3)+2] = THREE.MathUtils.lerp(currentPoints[(x*3)+2] , originalPoints[i][x].z + notes[x]/(150 - i), 0.1);
                } else {
                    currentPoints[(x*3)+2] = THREE.MathUtils.lerp(currentPoints[(x*3)+2] , originalPoints[i][x].z - notes[x]/(150 - i), 0.1);
                }

            }
            waves.children[i].geometry.attributes.position.needsUpdate = true;
        }

        bubbler.instanceMatrix.needsUpdate = true;
        bubbler.rotation.x += 0.005;
        bubbler.rotation.y += 0.005;
        bubbler.rotation.z += 0.005;
        bubbler.position.z = Math.sin(Date.now() * 0.0003);
        bubbler.position.x = Math.sin(Date.now() * 0.0001);
        bubbler.children[0].position.x = Math.sin(Date.now() * 0.0001);
        bubbler.children[0].position.y = Math.sin(Date.now() * 0.0001);

        bubbler.children[0].material.color.offsetHSL(0.001, 0, 0);

        const time = performance.now() * 0.003;
        for (let i = 0; i < bubbler.children[0].geometry.vertices.length; i++) {
            const p = bubbler.children[0].geometry.vertices[i];
            p.normalize()
                .multiplyScalar(
                    0.25 + 0.1 * noise.perlin3(
                        p.x * (THREE.MathUtils.clamp(audioData.getBeatStrength(), 0.2, 1)) + time,
                        p.y * (THREE.MathUtils.clamp(audioData.getBeatStrength(), 0.2, 1)),
                        p.z * (THREE.MathUtils.clamp(audioData.getBeatStrength(), 0.2, 1))
                    )
                );
        }

        bubbler.children[0].geometry.computeVertexNormals();
        bubbler.children[0].geometry.normalsNeedUpdate = true;
        bubbler.children[0].geometry.verticesNeedUpdate = true;
    }
}

function createBubbler()
{
    const bufferGeometry = new THREE.SphereBufferGeometry(0.05, 32, 32);
    const material = new THREE.MeshLambertMaterial({
        color: 0x0fbcf9
    });
    bubbler = new THREE.InstancedMesh(bufferGeometry, material, 2048);
    bubbler.layers.enable(1);
    let thetaCounter = 2048;

    const dummy = new THREE.Object3D();
    const vector = new THREE.Vector3();
    for (let i = 0; i < 2048; i++) {
        vector.setFromSphericalCoords(
            1.5,
            THREE.MathUtils.degToRad((360 / 2048) * i),
            thetaCounter
        );
        dummy.position.x = vector.x;
        dummy.position.y = vector.y;
        dummy.position.z = vector.z;
        dummy.updateMatrix();
        bubbler.setMatrixAt(i, dummy.matrix);
        thetaCounter--;
    }

    const brainMaterial = new THREE.MeshLambertMaterial({
        color: 0x485460,
    });
    const brainGeometry = new THREE.SphereGeometry(0.15, 32, 32);
    const brain = new THREE.Mesh(brainGeometry, brainMaterial);
    brain.layers.enable(1);
    bubbler.add(brain);

    bubbler.instanceMatrix.needsUpdate = true;
    bubbler.position.z = -0.5;
}

function createWaves()
{
    const material = new THREE.LineBasicMaterial({
        color : 0x13143d,
        linewidth: 3
    });

    for (let i = 0; i < 25; i++) {
        const curve = new THREE.CatmullRomCurve3([
            new THREE.Vector3(-15, THREE.MathUtils.randInt(-6, 6), THREE.MathUtils.randFloat(-2, -1)),
            new THREE.Vector3(-10, THREE.MathUtils.randInt(-6, 6), THREE.MathUtils.randFloat(-2, -1)),
            new THREE.Vector3(-5, THREE.MathUtils.randInt(-6, 6), THREE.MathUtils.randFloat(-2, -1)),
            new THREE.Vector3(15, THREE.MathUtils.randInt(-6, 6), THREE.MathUtils.randFloat(-2, -1)),
        ]);

        const points = curve.getPoints(128);
        originalPoints.push(points);
        const geometry = new THREE.BufferGeometry().setFromPoints(points);


        const wave = new THREE.Line(geometry, material.clone());
        wave.position.z = -2;
        wave.layers.enable(1);
        waves.add(wave);

        material.color.offsetHSL(0.005, 0, 0);
    }
}
